import { theme } from "@dls/web";
import styled from "styled-components";

export const SpaceBetween = styled.section`
  display: flex;
  justify-content: space-between;
`;

export const SmallView = styled.div`
  @media (min-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`;

export const FullImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const Error = styled.div`
  color: #bc1430;
  font-size: 16px;
  flex: 0 0 85%;
  font-family: ${theme.main.fontFamily.secondaryThin};
  margin: ${({ margin = [0, 0, 0, 0] }) =>
    `${theme.space[margin[0]]}px ${theme.space[margin[1]]}px ${
      theme.space[margin[2]]
    }px ${theme.space[margin[3]]}px`};
`;
