import ReCAPTCHA from "react-google-recaptcha";
import React from "react";

const Captcha = React.forwardRef((props, ref) => {
  return (
    <ReCAPTCHA {...props} sitekey={process.env.GATSBY_CAPTCHA_KEY} ref={ref} />
  );
});

export default Captcha;
