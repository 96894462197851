export const paymentDataSelector = state => {
  const premium = state.premium
  const plan = premium.plans[premium.selectedPlan];
  let amount = 0;
  if (plan && plan.totalPremiumAfterGST) {
    amount = plan.totalPremiumAfterGST;
  }
  const { driverEmail: email } = state.driverDetails;
  const { transactionId, proposalNumber } = state.createProposal.proposal;
  return { email, amount, transactionId, proposalNumber };
};

export const getQuoteSelector = state => state.getQuote;

export const driverDetailsSelector = state => state.driverDetails;

export const vehicleDetailsSelector = state => state.vehicleDetails;

export const premiumSelector = state => state.premium;

export const createProposalSelector = state => state.createProposal;

export const extraCoverageSelector = state => state.extraCoverage;

export const trackingIdSelector = state => state.trackingId;

export const tokenSelector = state => state.token;

export const isMstaSelector = state => state.isMsta;

export const myInfoSelector = state => state.myInfo;
