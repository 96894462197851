import { BulletList, Button, Modal, Text, theme } from "@dls/web";

import React from "react";
import { Section } from "..";
import { navigate } from "gatsby";
import styled from "styled-components";

const goToOccupationFail = () => {
  navigate("/occupation-fail");
};

export const ButtonContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${theme.space[10]}px;
`;

const DeclarationModal = ({ visible, onClose, json }) => {

  return (
    <Modal
      visible={visible}
      onClose={() => onClose(false)}
      data-testid="modal-id"
    >
      <Modal.Content>
        <Section margin={[0, 10, 6, 0]}>
          <Text type="sectionTitle">{json.header_text}</Text>
        </Section>
        {json.points.map((item, index) => (
          <Section margin={[2, 0, 0, 0]} key={item}>
            <BulletList>{item}</BulletList>
          </Section>
        ))}
        <ButtonContainer>
          <Button
            data-testid="yes"
            text={"Yes"}
            onClick={() => {
              goToOccupationFail();
              onClose(false);
            }}
            secondary
          />
          <Section margin={[0, 2, 0, 0]} />
          <Button
            data-testid="no"
            text={"No"}
            onClick={() => {
              onClose(false);
            }}
            secondary
          />
        </ButtonContainer>
      </Modal.Content>
    </Modal>
  );
};
export default DeclarationModal;
