import React from "react";
import { Text, TextLink } from "@dls/web";
import { priceFormat } from "../../utils/ui-logic";
import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { FlexBox, Title, Column, ChangeButton } from "./styles";

const PlanSummary = ({
  planName,
  total,
  changeButtonTestId,
  onClickChange
}) => {
  return (
    <FlexBox>
      <Title type="boldBody">
        {PAYMENT_SUMMARY.singtelCarProtect}
        <br />
        {planName}
      </Title>
      <Column>
        <Text type="body">{priceFormat(total)}</Text>
        <ChangeButton type="boldBody">
          <TextLink
            data-testid={changeButtonTestId}
            inline
            onClick={onClickChange}
          >
            Change
          </TextLink>
        </ChangeButton>
      </Column>
    </FlexBox>
  );
};

export default PlanSummary;
