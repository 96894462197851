import {
  SET_POLICY_STATUS,
  SET_LOADING
} from "../../actions/policyActions/actions.json";
import initialState from "./initialState";

export default (policyStatus = initialState, action = {}) => {
  if (action.type === SET_POLICY_STATUS) {
    return { ...policyStatus, ...action.policyStatus };
  }
  if (action.type === SET_LOADING) {
    return { ...policyStatus, ...{ isLoading: action.isLoading } };
  }
  return policyStatus;
};
