export const initialState = {
  driverName: "",
  driverNationality: "",
  driverNationalityCode: "",
  driverNric: "",
  driverEmail: "",
  driverPostal: "",
  driverStreet: "",
  driverFloorAndUnit: "",
  countries: [],
  query: "",
  loading: false
};
