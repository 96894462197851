import styled from "styled-components";
import { theme, Text } from "@dls/web";

export const Container = styled.div`
  background-color: ${theme.main.colours.white};
  border-radius: ${theme.borderRadius.sm};
`;

export const HeaderContainer = styled.div`
  padding: ${props => (props.showRadio ? 0 : theme.space[6])}px
    ${theme.space[6]}px;
  display: flex;
  align-items: center;
`;

export const WarningContainer = styled.div`
  padding: ${theme.space[2]}px ${theme.space[6]}px;
  padding-top: 0px;
  display: flex;
  align-items: center;
`;

export const ErrorText = styled(Text)`
  color: ${theme.main.colours.primary.d1};
`;
