import React, { useMemo, useState } from "react";

import { ContactGeButton } from "../../Buttons";
import { Section } from "../..";
import { Container } from "./styles";
import { MY_INFO_PREVIEW } from "../../../constants/strings/staticString.json";
import { myInfoDriverIsEligible } from "../../../utils/ui-logic";
import Collapsible from "../../Collapsible";
import MyInfoDetailHeader from "./MyInfoDetailHeader";
import MyInfoDetailContent from "./MyInfoDetailContent";
import MyInfoDetailIneligible from "./MyInfoDetailIneligible";

import {
  displayGender,
  displayYesOrNo,
  displayDate
} from "../../../utils/utilsMethods";

const MyInfoDetailDriver = ({ children }) => <>{children}</>;

const Details = ({ driverDetails, checked, open, setOpen, onSelect }) => {
  const { driverIneligible, notAvailable } = MY_INFO_PREVIEW.errors;
  const {
    fullName,
    nric,
    nationality,
    gender,
    dateOfBirth,
    drivingComstatus
  } = driverDetails;

  const isEligible = useMemo(() => myInfoDriverIsEligible(dateOfBirth), [
    dateOfBirth
  ]);

  const [minCollapsibleHeight, setMinCollapsibleHeight] = useState(0);

  const details = {
    NRIC: nric || notAvailable,
    Nationality: nationality || notAvailable,
    Gender: displayGender(gender) || notAvailable,
    "Date of birth": displayDate(dateOfBirth) || notAvailable,
    "Traffic Police Certificate Of Merit":
      displayYesOrNo(drivingComstatus) || notAvailable
  };

  return (
    <Section padding={[2, 0, 2, 0]}>
      <Container>
        <MyInfoDetailHeader
          title={fullName}
          showChevron
          checked={checked}
          open={open}
          setOpen={setOpen}
          onSelect={onSelect}
        />
        <Collapsible open={open} minHeight={minCollapsibleHeight}>
          {!isEligible && (
            <MyInfoDetailIneligible
              innerRef={node =>
                node && setMinCollapsibleHeight(node.clientHeight)
              }
            >
              {driverIneligible}
            </MyInfoDetailIneligible>
          )}
          {!isEligible && (
            <Section padding={[0, 6, 6, 6]}>
              <ContactGeButton />
            </Section>
          )}
          <MyInfoDetailContent details={details} />
        </Collapsible>
      </Container>
    </Section>
  );
};

const Other = ({ title, checked, onSelect }) => {
  return (
    <Section padding={[2, 0, 2, 0]}>
      <Container>
        <MyInfoDetailHeader
          title={title}
          checked={checked}
          onSelect={onSelect}
        />
      </Container>
    </Section>
  );
};

MyInfoDetailDriver.Details = Details;
MyInfoDetailDriver.Other = Other;

export default MyInfoDetailDriver;
