import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column, Text, Row, Grid } from "@dls/web";

import {
  Section,
  Page,
  PaddingContainer,
  StepperWrapper
} from "../../components";
import { GET_QUOTE, COMMON } from "../../constants/strings/staticString.json";
import { DriverDetailsForm } from "../../components/Forms/GetQuote";
import {
  setGetQuoteEditMode,
  getInsuranceData,
  setCampaignCode
} from "../../actions/getQuoteActions";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import {
  SingpassInfo
} from "../../components";
import { getCampaignCode, showSingpassRetrieveInfo } from '../../utils/ui-logic';


const DriverDetailsPage = ({ location: { state } }) => {
  const { title } = GET_QUOTE.driverDetails;
  const dispatch = useDispatch();
  const isEdit = state ? state.isEdit : false;

  const premium = useSelector(s => s.premium);
  const myInfo = useSelector((s) => s.myInfo);
  const plan = premium.plans[premium.selectedPlan];
  const {discounts, source} = useSelector(s => s.promotions)

  const { isMobile } = useCheckMobileScreen();

  const dispatchGetInsuranceData = React.useCallback(
    () => dispatch(getInsuranceData()),
    [dispatch]
  );

  const dispatchSetEditMode = React.useCallback(
    value => dispatch(setGetQuoteEditMode(value)),
    [dispatch]
  );

  React.useEffect(
    () => {
      dispatchGetInsuranceData();
      dispatchSetEditMode(isEdit);
    },
    [dispatchGetInsuranceData, dispatchSetEditMode, isEdit]
  );

  React.useEffect(() => {
    //this will prevent users from accidently refreshing / closing tab
    window.onbeforeunload = () => {
     return "";
   };
   }, [])

   React.useEffect(() => {
    dispatch(setCampaignCode(getCampaignCode(source, discounts)))
   }, [discounts, source])

  const formRef = useRef();

  return (
    <>
      <Page>
        <PaddingContainer mobile={{ bottom: 16 }}>
          <Grid>
            <Row>
              <StepperWrapper
                totalSteps={GET_QUOTE.stepperStepsQuote.length}
                activeStep={1}
                titles={GET_QUOTE.stepperStepsQuote}
              />
            </Row>
            <Row>
              <Column noGutter xs={12} sm={7}>
                <Section margin={[0, 0, 4, 2]}>
                  <Text type="pageTitle">{title}</Text>
                </Section>
              </Column>
            </Row>
            {showSingpassRetrieveInfo(myInfo) && <SingpassInfo/>}
            <DriverDetailsForm
              isMobile={isMobile}
              plan={plan}
              innerRef={formRef}
            />
          </Grid>
        </PaddingContainer>
      </Page>
    </>
  );
};

export default DriverDetailsPage;
