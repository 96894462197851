import {
  LOGIN_MY_INFO,
  GET_MY_INFO_DETAILS,
  SET_MY_INFO_DETAILS,
  SET_MY_INFO_DRIVER_DETAILS,
  SET_MY_INFO_VEHICLE_DETAILS,
  CONFIRM_MY_INFO_DETAILS,
  RESET_MY_INFO_DETAILS
} from "./actions.json";

export const loginMyInfo = () => ({ type: LOGIN_MY_INFO });

export const getMyInfoDetails = (code, isMsta) => ({
  type: GET_MY_INFO_DETAILS,
  code,
  isMsta
});

export const resetMyInfoDetails = () => ({ type: RESET_MY_INFO_DETAILS });

export const setMyInfoDetails = details => ({
  type: SET_MY_INFO_DETAILS,
  details
});

export const setMyInfoDriverDetails = details => ({
  type: SET_MY_INFO_DRIVER_DETAILS,
  details
});

export const setMyInfoVehicleDetails = details => ({
  type: SET_MY_INFO_VEHICLE_DETAILS,
  details
});

export const confirmMyInfoDetails = callBackUrl => ({
  type: CONFIRM_MY_INFO_DETAILS,
  callBackUrl
});
