import React from "react";

import { ERROR, CONFIG } from "../../constants/strings/staticString.json";
import { Error } from "../../components";
import { navigate } from "gatsby";

const PaymentFail = () => {
  const { header, title, buttonText } = ERROR.paymentFailedError;

  const backToHomeBtnClicked = () => {
    navigate(CONFIG.redirectURLRedux);
  }

  return (
    <Error
      header={header}
      title={title}
      buttonText={buttonText}
      onClick={backToHomeBtnClicked}
      testid="try_again"
    />
  );
};

export default PaymentFail;
