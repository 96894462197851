import { call, fork, put, select, takeLatest } from "redux-saga/effects";

import { api } from "../apiSaga";
import { navigate } from "gatsby";
import {
  openExternalURL,
  openExternalBrowser
} from "../../utils/navigationUtils";
import { payment } from "../../constants/api.json";
import { isMstaSelector } from "../../selectors";
import { setPaymentDetails } from "../../actions/paymentActions";
import { FETCH_PAYMENT } from "../../actions/paymentActions/action.json";

export function* paymentWatcher() {
  yield takeLatest(FETCH_PAYMENT, fetchPayment);
}

export function* fetchPayment(action) {
  const params = action;
  const response = yield call(api, payment, params);
  if (response.success) {
    yield fork(fetchPaymentSuccess, response.success);
  }
  if (response.error) {
    yield fork(fetchPaymentFail);
  }
}

export function* fetchPaymentSuccess(data) {
  yield put(setPaymentDetails(data));
  const { redirect_url } = data;
  const isMsta = yield select(isMstaSelector);
  if (isMsta) {
    yield call(openExternalBrowser, redirect_url);
    yield call(navigate, "/payment/pending/");
  } else {
    yield call(openExternalURL, redirect_url);
  }
}

export function* fetchPaymentFail() {
  yield call(navigate, "/error/generic-error");
}
