import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Footer as BaseFooter } from '@lux/components';


export const PureFooter = withTheme(props => {
  const { data, baseUrl } = props;

  const { footer } = data;

  return <BaseFooter
    links={footer.links}
    copyrightText={footer.copyrightText}
    hasContent={footer.hasContent}
    isFullWidth={footer.isFullWidth}
    baseUrl={baseUrl}
  />;
});

PureFooter.propTypes = {
  /** The footer datamodel */
  data: PropTypes.object
};

/* istanbul ignore next */
const Footer = props => (
  <StaticQuery
    query={graphql`
      {
        footer {
          links: links {
            text
            link
          }
          copyrightText
          hasContent
          isFullWidth
        }
      }
    `}
    render={data => <PureFooter data={data} {...props} />}
  />
);

export default Footer;
