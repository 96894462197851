import React from "react";
import { DropdownInput } from "@dls/web";

import { GET_QUOTE } from "../../../../constants/strings/staticString.json";

const BodyType = props => {
  const { label } = GET_QUOTE.vehicleDetails.bodyType;
  const {
    name,
    bodyTypeItems,
    bodyType,
    hintMessage,
    error,
    setFieldValue,
    setFieldTouched
  } = props;

  return (
    <DropdownInput
      id={name}
      defaultValue={bodyType}
      label={label}
      items={bodyTypeItems}
      onChange={item => {
        setFieldTouched(name, true);
        setFieldValue(name, item.value);
      }}
      hintMessage={hintMessage}
      error={error}
    />
  );
};

export default BodyType;
