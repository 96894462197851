import { useEffect, useState } from "react";
import { useFormikContext } from "formik";

const FormikOnError = ({ children }) => {
  const formik = useFormikContext();
  const [submitCount, setSubmitCount] = useState(formik.submitCount);

  useEffect(
    () => {
      if (!formik.isValid && formik.submitCount > submitCount) {
        for (const error in formik.errors) {
          if (error === "coverageDate") {
            const elements = global.window.document.querySelectorAll(
              "[data-testid=datepicker-input]"
            );            
            elements[0].click();
            elements[0].focus();
            break;
          } else if (global.window.document.getElementsByName(error).length) {
            global.window.document.getElementsByName(error)[0].focus();
            setSubmitCount(formik.submitCount);
            break;
          } else if (global.window.document.getElementById(error)) {
            global.window.document.getElementById(error).focus();
            setSubmitCount(formik.submitCount);
            break;
          }
        }
      }
    },
    [formik.submitCount, formik.isValid, formik.errors]
  );
  return children;
};

export default FormikOnError;
