import { takeLatest, call, put, select } from "redux-saga/effects";
import { navigate } from "gatsby";

import {
  GET_POLICY,
  GET_POLICY_STATUS
} from "../../actions/policyActions/actions.json";
import {
  setPolicy,
  setPolicyStatus,
  setLoading,
  getPolicyFailed
} from "../../actions/policyActions";
import { policy, policyStatus } from "../../constants/api.json";
import { api } from "../apiSaga";
import { paymentDataSelector } from "../../selectors";

export function* policyWatcher() {
  yield takeLatest(GET_POLICY, fetchPolicy);
  yield takeLatest(GET_POLICY_STATUS, fetchPolicyStatus);
}

export function* fetchPolicy(action) {
  const response = yield call(api, policy, action);
  if (response.success) {
    yield put(setPolicy(response.success));
  } else {
    yield put(getPolicyFailed());
    yield call(navigate, "/order-incomplete");
  }
}

export function* fetchPolicyStatus(action) {
  const { transactionId } = yield select(paymentDataSelector);
  yield put(setLoading(true));
  const response = yield call(
    api,
    { ...policyStatus, ...{ url: policyStatus.url + transactionId } },
    action
  );
  if (response.success && response.success.status) {
    yield put(setPolicyStatus(response.success));
    const { redirect, state } = getNavigationState(response.success);
    yield call(navigate, redirect, { state });
  } else {
    yield call(navigate, "/error/generic-error");
  }
}

export function getNavigationState(success) {
  const { status, policyNumber } = success;
  let redirect;
  let state = {};
  switch (status) {
    case "FAILED":
      // payment failure
      redirect = "/payment/fail";
      break;
    case "COMPLETED":
      // policyNumber ? payment success and policy created : payment success and policy not created
      redirect = policyNumber ? "/payment/success" : "/order-incomplete";
      state = { fetchPolicy: false };
      break;
    default:
      redirect = "/error/generic-error";
  }
  return { redirect, state };
}
