import { SET_PREMIUM, SELECT_PLAN, UPDATE_PLAN, SET_PREMIUM_LOADING } from "./actions.json";

export const setPremium = premium => ({ type: SET_PREMIUM, premium });
export const updatePlan = plan => ({ type: UPDATE_PLAN, plan });
export const selectPlan = selectedPlanIndex => ({
  type: SELECT_PLAN,
  selectedPlan: selectedPlanIndex
});
export const setPremiumLoading = isLoading => ({
  type: SET_PREMIUM_LOADING,
  isLoading
});
