import {
  CREATE_PROPOSAL,
  CREATE_PROPOSAL_FAILURE,
  CREATE_PROPOSAL_SUCCESS,
  SET_PROPOSAL_NUMBER
} from "../../actions/createProposalActions/actions.json";

import initialState from "./initialState";

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_PROPOSAL:
      return {
        ...state,
        isLodding: true
      };
    case CREATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposal: action.payload,
        isLodding: false
      };
    case CREATE_PROPOSAL_FAILURE:
      return {
        ...state,
        isLodding: false,
        error: action.payload
      };
    case SET_PROPOSAL_NUMBER:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          proposalNumber: action.proposalNumber
        }
      };
    default:
      return state;
  }
}
