import {
  ADD_BENEFIT,
  REMOVE_BENEFIT,
  REMOVE_ALL_BENEFIT
} from "../../actions/extraCoverageActions/actions.json";

import initialState from "./initialState";

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_BENEFIT:
      return [...state, action.benefit];
    case REMOVE_BENEFIT:
      return state.filter(
        benefit =>
          benefit.planId !== action.benefit.planId ||
          benefit.benefitId !== action.benefit.benefitId
      );
    case REMOVE_ALL_BENEFIT:
      return [];
    default:
      return state;
  }
}
