import React from "react";
import { Radio, Text } from "@dls/web";
import { ArrowButton } from "../../";
import { HeaderContainer } from "./styles";

const MyInfoDetailHeader = props => {
  const {
    title,
    showChevron,
    showRadio = true,
    checked,
    onSelect,
    open,
    setOpen
  } = props;

  return (
    <HeaderContainer showRadio={showRadio}>
      {showRadio && <Radio.Input checked={checked} onClick={onSelect} />}
      <Text type="boldBody">{title}</Text>
      {showChevron && <ArrowButton onChange={setOpen} isOpened={open} />}
    </HeaderContainer>
  );
};

export default MyInfoDetailHeader;
