import {
  VALIDATION,
  GET_QUOTE,
  DRIVER_DETAILS
} from "../constants/strings/staticString.json";
import { isValidEmail, isValidMobileNumber } from "@lux/helpers";
import { isValid, parse } from "date-fns";
import { isEligibleAge } from "./utilsMethods";

export const getNRICError = (value, nationality) => {
  if (!value || value.length === 0) {
    return DRIVER_DETAILS.nric.requiredMessage;
  }

  const nricRegex = new RegExp("[ST]\\d{7}[A-Z]$");
  var nricError = "";
  if (!value.match(nricRegex)) {
    nricError = VALIDATION.DRIVER_DETAILS.ENTER_A_VALID_NRIC;
  }
  const finRegex = new RegExp("[FGHM]\\d{7}[A-Z]$");
  var finError = "";
  if (!value.match(finRegex)) {
    finError = VALIDATION.DRIVER_DETAILS.ENTER_A_VALID_NRIC_FIN;
  }
  const error =
    !nricError || nationality === "Singapore" ? nricError : finError;
  return error;
};

export const getDobError = dateOfBirth => {
  if (!dateOfBirth || dateOfBirth.length === 0) {
    return GET_QUOTE.driverDetails.dateOfBirth.requiredMessage;
  }
  const validDateOfBirth = dateOfBirth.replace(/ /g, "");
  const date = parse(validDateOfBirth, "dd/MM/yyyy", new Date());
  const isDOBValid =
    validDateOfBirth.split("/").pop().length === 4 && isValid(date);
  if (isDOBValid) {
    const [day, month, year] = validDateOfBirth
      .split("/")
      .map(d => parseInt(d));
    if (day && month && year) {
      const dob = new Date(year, month - 1, day);
      return isEligibleAge(dob, new Date())
        ? null
        : GET_QUOTE.driverDetails.dateOfBirth.outOfAgeDateError;
    }
  }

  return GET_QUOTE.driverDetails.dateOfBirth.invalidDateError;
};

export const removeContactCountryCode = value => {
  return value ? value.replace("+65 ", "").replace(" ", "") : null;
};

export const getContactNoError = value => {
  if (!value || value.length === 0) {
    return GET_QUOTE.driverDetails.contact.requiredMessage;
  }
  value = removeContactCountryCode(value);
  if (value.length !== 8) {
    return VALIDATION.DRIVER_DETAILS.ENTER_A_VALID_8_DIGIT_MOBILE_NUMBER;
  } else if (!isValidMobileNumber(removeContactCountryCode(value))) {
    return VALIDATION.DRIVER_DETAILS.MOBILE_NUMBER_MUST_START_WITH_8_9;
  } else {
    return null;
  }
};
export const getPostalCodeError = value => {
  if (!value || value.length === 0) {
    return DRIVER_DETAILS.postal.requiredMessage;
  }
  if (isNaN(value) || value.length < 6) {
    return VALIDATION.DRIVER_DETAILS.ENTER_A_VALID_6_DIGIT_POSTAL_CODE;
  }
  return null;
};

export const getEmailError = value => {
  if (!value || value.length === 0) {
    return DRIVER_DETAILS.email.requiredMessage;
  }

  if (!isValidEmail(value)) {
    return VALIDATION.DRIVER_DETAILS.ENTER_A_VALID_EMAIL_ADDRESS;
  }
  return null;
};

export const checkValidEnglishChars = (value) => {
  const regexString = `^[~\`!@#$%^&*()_+=[\\]\\{}|;':",.\\/<>?a-zA-Z0-9- ]+$`
  const regex = new RegExp(regexString);
  return regex.test(value)
}
