import React, { useEffect } from "react";
import { DropdownInput } from "@dls/web";

import { VEHICLE_DETAILS } from "../../../../constants/strings/staticString.json";

const PreviousInsurer = ({
  name,
  value,
  insurerArray,
  error,
  hintMessage,
  setFieldTouched,
  setFieldValue
}) => {
  const { label } = VEHICLE_DETAILS.previousInsurer;
  const [filteredItems, setFilteredItems] = React.useState(insurerArray);

  useEffect(
    () => {
      setFilteredItems(insurerArray);
    },
    [insurerArray]
  );

  const defaultInsurer = insurerArray.filter(item => {
    return value ? item.text === value.text : false;
  });

  const onSearch = (event, searchText) => {
    const items = insurerArray.filter(({ type, text }) => {
      return text.toLowerCase().includes(searchText.toLowerCase());
    });
    setFilteredItems(items);
  };

  return (
    <DropdownInput
      id={name}
      label={label}
      defaultValue={defaultInsurer.length > 0 ? defaultInsurer[0].value : ""}
      items={filteredItems}
      onChange={item => {
        setFieldTouched(name, false);
        setFieldValue(name, item);
      }}
      hasSearch
      onSearch={onSearch}
      error={error}
      hintMessage={hintMessage}
    />
  );
};
export default PreviousInsurer;
