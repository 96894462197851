import { combineReducers } from "redux";
import createProposalReducer from "./createProposalReducer";
import driverDetailsReducer from "./driverDetailsReducer";
import extraCoverageReducer from "./extraCoverageReducer";
import getQuoteReducer from "./getQuoteReducer";
import pdpaReducer from "./pdpaReducer";
import premiumReducer from "./premiumReducer";
import trackingIdReducer from "./trackingIdReducer";
import vehicleDetailsReducer from "./vehicleDetailsReducer";
import paymentReducer from "./paymentReducer";
import policyReducer from "./policyReducer";
import tokenReducer from "./tokenReducer";
import isMstaReducer from "./isMstaReducer";
import policyStatusReducer from "./policyStatusReducer";
import myInfoReducer from "./myInfoReducer";
import promotionsReducer from "./promotionsReducer";

const appReducer = combineReducers({
  getQuote: getQuoteReducer,
  driverDetails: driverDetailsReducer,
  vehicleDetails: vehicleDetailsReducer,
  premium: premiumReducer,
  createProposal: createProposalReducer,
  extraCoverage: extraCoverageReducer,
  trackingId: trackingIdReducer,
  pdpa: pdpaReducer,
  payment: paymentReducer,
  policy: policyReducer,
  token: tokenReducer,
  isMsta: isMstaReducer,
  policyStatus: policyStatusReducer,
  myInfo: myInfoReducer,
  promotions: promotionsReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
