import styled from "styled-components";
import { Text, theme } from "@dls/web";

export const DiscountsContainer = styled.div`
  background-color: ${theme.main.colours.secondary.l2};
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const DiscountText = styled(Text)`
  margin-top: 8px;
`;
