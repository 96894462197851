import React from "react";
import { InputText } from "@dls/web";

import { VEHICLE_DETAILS } from "../../../../constants/strings/staticString.json";
import { upperAlphaNumericFormate } from "../../../../utils/utilsMethods";

const VehicleRegistration = ({
  name,
  previousRegistrationName,
  value,
  error,
  hintMessage,
  setFieldTouched,
  setFieldValue
}) => {
  const { label } = VEHICLE_DETAILS.vehicleRegistration;

  return (
    <InputText
      name={name}
      label={label}
      value={value}
      error={error}
      hintMessage={hintMessage}
      onChange={e => {
        setFieldTouched(name, false);
        setFieldValue(
          previousRegistrationName,
          upperAlphaNumericFormate(e.target.value)
        );
        setFieldValue(name, upperAlphaNumericFormate(e.target.value));
      }}
      onBlur={() => setFieldTouched(name, true)}
    />
  );
};

export default VehicleRegistration;
