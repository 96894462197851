import React from "react";
import { navigate } from "gatsby";
import { CONFIG } from "../constants/strings/staticString.json";

const LandingPage = () => {
  React.useEffect(() => {
    navigate(CONFIG.redirectURL);
  }, []);
  return null;
};

export default LandingPage;
