import styled from "styled-components";
import { theme } from "@dls/web";

const Page = styled.div`
  min-height: 100vh;
  background-color: ${(props) =>
    props.bgColor || theme.main.colours.tertiary.l6};
`;

export default Page;
