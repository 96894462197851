import {
  GET_PREVIOUS_INSURER,
  SET_PREVIOUS_INSURER,
  SET_VEHICLE_DETAILS
} from "./actions.json";

export const setVehicleDetails = ({
  firstTimeInsurance,
  vehicleRegistrationNo,
  vehicleChasisNo,
  vehicleEngineNo,
  vehicleBankName,
  vehicleInsurer,
  vehiclePreviousRegNo
}) => {
  return {
    type: SET_VEHICLE_DETAILS,
    payload: {
      firstTimeInsurance,
      vehicleRegistrationNo,
      vehicleChasisNo,
      vehicleEngineNo,
      vehicleBankName,
      vehicleInsurer,
      vehiclePreviousRegNo
    }
  };
};

export const getPreviousInsurer = () => ({ type: GET_PREVIOUS_INSURER });

export const setPreviousInsurer = ({ insurer }) => {
  return {
    type: SET_PREVIOUS_INSURER,
    insurerArray: insurer.map(item => {
      return { text: item.insurerValue, value: item.insurerCode };
    })
  };
};
