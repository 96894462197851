import { Card } from "@dls/web";
import SanitizedHTML from "../../SanitizedHTML";

import React from "react";

const RibbonCard = ({ promotion }) => {
  return (
    <Card Card shadow="sm" radius="sm" padding="sm">
      <Card.Content>
        {promotion && (
          <SanitizedHTML
            html={promotion.promotionText}
            className="ribbon-list-wrapper"
          />
        )}
      </Card.Content>
    </Card>
  );
};

export default RibbonCard;
