import { Button, Text, theme } from "@dls/web";
import { InProgress as InProgressIcon } from "@dls/assets/icons";
import { Layout, Section } from "../../components";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { ORDER_PENDING } from "../../constants/strings/staticString.json";
import styled from "styled-components";
import { fetchPayment } from "../../actions/paymentActions";
import { getPolicyStatus } from "../../actions/policyActions";
import { paymentDataSelector } from "../../selectors";

export const BodyText = styled.div`
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonSection = styled(Section)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const NotPaidButton = styled.div`
  padding: 0 20px 0 0;
`;
export const PageTitle = styled(Text)`
  text-align: center;
`;
const yellow = "#FAB824";

const OrderIncomplete = () => {
  const dispatch = useDispatch();
  const proposal = useSelector(s => s.createProposal.proposal);
  const policyStatus = useSelector(s => s.policyStatus);
  const { email, amount, transactionId, proposalNumber } = useSelector(s =>
    paymentDataSelector(s)
  );

  const dispatchFetchPayment = React.useCallback(
    () => dispatch(fetchPayment(email, amount, transactionId, proposalNumber)),
    [dispatch, email, amount, transactionId, proposalNumber]
  );
  const dispatchGetPolicyStatus = React.useCallback(
    () => dispatch(getPolicyStatus(proposal.transactionId)),
    [dispatch, proposal.transactionId]
  );
  return (
    <Layout md={5} mdOffset={3.5} bgColor={theme.main.colours.white}>
      {policyStatus.isLoading ? (
        <Container css={{ margin: 80 }}>
          <PageTitle type="pageTitle"> {ORDER_PENDING.loadingTitle} </PageTitle>
        </Container>
      ) : (
        <Container>
          <Section margin={[0, 0, 6, 0]}>
            <InProgressIcon size="64" color={yellow} />
          </Section>
          <PageTitle type="pageTitle"> {ORDER_PENDING.header} </PageTitle>
          <Section margin={[10, 0, 2, 0]}>
            <Text type="header"> {ORDER_PENDING.info_title} </Text>
          </Section>
          <BodyText>
            <Text type="body"> {ORDER_PENDING.info_description} </Text>
          </BodyText>
        </Container>
      )}
      <ButtonSection margin={[10, 0, 0, 0]}>
        <NotPaidButton>
          <Button
            data-testid="not-paid"
            secondary={true}
            onClick={() => dispatchFetchPayment()}
            text={ORDER_PENDING.buttonNotPaid}
            disabled={policyStatus.isLoading}
          />
        </NotPaidButton>
        <Button
          data-testid="payment-status"
          primary={true}
          onClick={() => dispatchGetPolicyStatus()}
          text={ORDER_PENDING.buttonStatus}
          loading={policyStatus.isLoading && "inProgress"}
        />
      </ButtonSection>
    </Layout>
  );
};
export default OrderIncomplete;
