import { Row, Text } from "@dls/web";
import React from "react";

import { theme } from "@dls/web";
import styled from "styled-components";

export const Divider = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${theme.main.colours.divider};
  align-self: center;
`;

export const Title = styled(Text)`
  margin: 0px 30px;
`;

const TitleDivider = ({ title }) => {
  return (
    <Row>
      <Divider />
      <Title>{title}</Title>
      <Divider />
    </Row>
  );
};

export default TitleDivider;
