import React, { useState, useMemo, useEffect } from "react";
import { navigate } from "gatsby";
import { Row, Column, Text, Button } from "@dls/web";
import { useSelector, useDispatch } from "react-redux";

import { Layout, Section } from "../../../components";
import {
  setMyInfoVehicleDetails,
  setMyInfoDriverDetails,
  confirmMyInfoDetails,
  resetMyInfoDetails
} from "../../../actions/myInfoActions";

import { MY_INFO_PREVIEW, COMMON } from "../../../constants/strings/staticString.json";
import { trackEvent } from "../../../utils/analyticsUtils";
import {
  MyInfoDetailDriver,
  MyInfoDetailVehicle
} from "../../../components/Cards/MyInfoDetailCard";
import { useWindowSize } from "../../../hooks/useWindowSize";
import {
  myInfoDriverIsEligible,
  vehicleIsEligible
} from "../../../utils/ui-logic";
import { fetchPromotions, setPromoSource } from "../../../actions/promotionsActions";

const MyInfoPreviewPage = ({ location: { state } }) => {
  const { isEdit } = state || false;
  const { selectedVehicleIndex, details } = useSelector(s => s.myInfo);
  const { vehicleInfos, ...driverDetails } = details;

  const { driverName } = useSelector(s => s.driverDetails);
  const { vehicleRegistrationNo } = useSelector(s => s.vehicleDetails);
  const {
    confirmAndProceedAnalytics,
    useLabel,
    reviewLabel,
    driverToBeInsuredLabel,
    vehicleToBeInsuredLabel,
    insureSomeoneLabel,
    errors: { insureOtherVehicle, noVehicleUnderYou, otherVehicle }
  } = MY_INFO_PREVIEW;

  const dispatch = useDispatch();

  const goToGetQuoteDriverDetails = () => {
    trackEvent(confirmAndProceedAnalytics);
    dispatch(setMyInfoDriverDetails(selectedDriver));
    dispatch(setMyInfoVehicleDetails(selectedVehicle));
    dispatch(confirmMyInfoDetails("/get-quote/driver-details"));
  };

  const goToPaymentSummary = () => {
    navigate("/payment-summary");
  };

  const userHasNoVehicle = details.vehicleInfos.length <= 0;
  const [selectedDriver, setSelectedDriver] = useState(driverDetails);
  const [shouldDriverCardOpen, setShouldDriverDetailsOpen] = useState(true);
  const [selectedVehicle, setSelectedVehicle] = useState(
    userHasNoVehicle ? null : 0
  );
  const [openedVehicles, setOpenedVehicles] = useState([0]);
  const windowSize = useWindowSize();

  const toggleOpenVehicle = (index, isOpening) => {
    let newOpenedVehicles = [...openedVehicles].filter(v => v !== index);
    if (isOpening) {
      newOpenedVehicles = [...newOpenedVehicles, index];
    }

    setOpenedVehicles(newOpenedVehicles);
  };

  const openOnlyVehicle = index => {
    setOpenedVehicles([index]);
  };

  const closeAllVehicle = () => {
    setOpenedVehicles([]);
  };

  const disableSubmit = useMemo(
    () =>
      (selectedDriver && !myInfoDriverIsEligible(selectedDriver.dateOfBirth)) ||
      (selectedVehicle &&
        !vehicleIsEligible(
          vehicleInfos[selectedVehicle].engineCapacity,
          vehicleInfos[selectedVehicle].yearOfManufacture
        )),
    [selectedDriver, selectedVehicle]
  );

  const hideBackButton = useMemo(
    () =>
      myInfoDriverIsEligible(driverDetails.dateOfBirth) &&
      vehicleInfos
        .map(info =>
          vehicleIsEligible(info.engineCapacity, info.yearOfManufacture)
        )
        .some(v => v === true),
    [driverDetails, vehicleInfos]
  );

  //To check user change my info details in edit mode
  const isDriverChanged =
    selectedDriver && selectedDriver.fullName && driverName
      ? selectedDriver.fullName.toLowerCase() !== driverName.toLowerCase()
      : true;

  const isVehicleChanged =
    selectedVehicleIndex !== null && vehicleRegistrationNo
      ? details.vehicleInfos[selectedVehicleIndex].vehicleNo.toLowerCase() !==
        vehicleRegistrationNo.toLowerCase()
      : true;

  useEffect(() => {
     //this will prevent users from accidently refreshing / closing tab
     window.onbeforeunload = () => {
      return "";
    };
    dispatch(fetchPromotions());
  }, []);

  useEffect(() => {
    //store coupon value from storage to redux
   const source = sessionStorage.getItem(COMMON.couponSource)
   if(!!source) {
     dispatch(setPromoSource(source));
   }
 }, []);

  return (
    <Layout key={windowSize} md={12}>
      <Row>
        <Column md={7} sm={7} xs={12}>
          <Text type='pageTitle'>
            {hideBackButton ? useLabel : reviewLabel}
          </Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Section padding={[6, 0, 0, 0]}>
            <Text type='header'>{driverToBeInsuredLabel}</Text>
          </Section>
        </Column>
      </Row>
      <Row>
        <Column xs={12} md={7}>
          <MyInfoDetailDriver.Details
            driverDetails={driverDetails}
            checked={!!selectedDriver}
            open={shouldDriverCardOpen}
            setOpen={v => setShouldDriverDetailsOpen(v)}
            onSelect={e => {
              setSelectedDriver(driverDetails);
              setShouldDriverDetailsOpen(true);
            }}
          />
          <MyInfoDetailDriver.Other
            title={insureSomeoneLabel}
            checked={!selectedDriver}
            onSelect={e => {
              setSelectedDriver(null);
              setShouldDriverDetailsOpen(false);
              setSelectedVehicle(null);
              closeAllVehicle();
            }}
          />
        </Column>
      </Row>
      <Row>
        <Column md={7} sm={7} xs={12}>
          <Section padding={[6, 0, 0, 0]}>
            <Text type='header'>{vehicleToBeInsuredLabel}</Text>
          </Section>
        </Column>
      </Row>
      <Row>
        <Column xs={12} md={7}>
          {vehicleInfos.map((info, index) => (
            <MyInfoDetailVehicle.Details
              key={index}
              vehicleInfo={info}
              checked={selectedVehicle === index}
              open={openedVehicles.includes(index)}
              setOpen={v => toggleOpenVehicle(index, v)}
              onSelect={e => {
                openOnlyVehicle(index);
                setSelectedVehicle(index);
              }}
              mustOwnVehicleError={
                selectedDriver === null &&
                (selectedVehicle === index || selectedVehicle === null) &&
                otherVehicle
              }
            />
          ))}
          <MyInfoDetailVehicle.Other
            title={vehicleInfos.length ? insureOtherVehicle : noVehicleUnderYou}
            checked={selectedVehicle === null}
            selectedDriverName={selectedDriver && selectedDriver.fullName}
            showRadio={!userHasNoVehicle}
            onSelect={e => {
              closeAllVehicle();
              setSelectedVehicle(null);
            }}
          />
        </Column>
      </Row>
      <Row>
        <Column noGutter xs={12} md={7}>
          <Row reverse>
            <Column xs={12} sm={6} md={6}>
              {!disableSubmit && (
                <Button
                  fullWidth
                  secondary={userHasNoVehicle}
                  onClick={
                    isEdit && !isVehicleChanged && !isDriverChanged
                      ? goToPaymentSummary
                      : goToGetQuoteDriverDetails
                  }
                >
                  {isEdit && !isVehicleChanged && !isDriverChanged
                    ? MY_INFO_PREVIEW.reviewOrder
                    : MY_INFO_PREVIEW.confirmAndProceedLabel}
                </Button>
              )}
            </Column>
            <Column xs={12} sm={6} md={6}>
              <Button
                fullWidth
                secondary
                onClick={() => {
                  trackEvent(MY_INFO_PREVIEW.cancelAnalytics);
                  dispatch(resetMyInfoDetails());

                  navigate("/get-quote/driver-details");
                }}
              >
                {MY_INFO_PREVIEW.cancelLabel}
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
    </Layout>
  );
};

export default MyInfoPreviewPage;
