import React from "react";
import { InputText, Text } from "@dls/web";
import Section from "../../../Section";

import { DRIVER_DETAILS } from "../../../../constants/strings/staticString.json";
import { emailFormate } from "../../../../utils/utilsMethods";

const Email = ({
  name,
  value,
  error,
  hintMessage,
  setFieldTouched,
  setFieldValue
}) => {
  const { email, contactFooter } = DRIVER_DETAILS;

  return (
    <>
      <InputText
        name={name}
        label={email.label}
        value={value}
        error={error}
        hintMessage={hintMessage}
        onChange={e => {
          setFieldTouched(name, false);
          setFieldValue(name, emailFormate(e.target.value));
        }}
        onBlur={() => setFieldTouched(name, true)}
      />
      <Section margin={[2, 0, 0, 0]} />
      <Text type="smallBody">{contactFooter}</Text>
    </>
  );
};

export default Email;
