import { useSelector } from "react-redux";
import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { paymentSummaryAnalytics } from "../../utils/analyticsUtils";
import {
  displayDate,
  displayContactNo,
  displayOccupation,
  displayGender
} from "../../utils/utilsMethods";

const useManuallyDriverDetails = () => {
  const {
    driverName,
    driverNationality,
    driverNric,
    driverEmail
  } = useSelector(s => s.driverDetails);
  const {
    dateOfBirth,
    occupationType,
    gender,
    contactNumberMobile
  } = useSelector(s => s.getQuote.premiumData);

  const { selectedDriver } = useSelector(s => s.myInfo);

  const basicDetails = {
    shouldHide: !!selectedDriver,
    title: PAYMENT_SUMMARY.basicDetails,
    items: [driverName, driverNationality, driverNric, driverEmail],
    link: "/driver-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titleDriverDetails,
      "Change"
    )
  };

  const personalDetails = {
    shouldHide: !!selectedDriver,
    title: PAYMENT_SUMMARY.personalDetails,
    items: [
      `${PAYMENT_SUMMARY.labelBorn} ${displayDate(dateOfBirth)}`,
      `${displayOccupation(occupationType)}`,
      `${displayGender(gender)}`,
      `${displayContactNo(contactNumberMobile)}`
    ].filter(items => items !== null),
    link: "/get-quote/driver-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titleDriverToBeInsured,
      "Change"
    )
  };

  return [basicDetails, personalDetails];
};

export default useManuallyDriverDetails;
