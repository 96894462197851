import {
  GET_INSURANCE_DATA,
  SET_GET_QUOTE_EDIT_MODE,
  GET_PREMIUM,
  SET_CLAIM_AMOUNT_ITEMS,
  SET_NCD_ITEMS,
  SET_MAKE_MODEL_ITEMS,
  SET_BODY_TYPE_ITEMS,
  SUBMIT_GET_QUOTE_VEHICLE_DETAILS,
  SET_VERIFIED,
  VERIFICATION_SUCCESS,
  SAVE_DRIVER_DETAILS,
  SAVE_VEHICLE_DETAILS,
  REFETCH_PREMIUM,
  SET_CAMPAIGN_CODE
} from "./actions.json";

export const getInsuranceData = () => ({ type: GET_INSURANCE_DATA });

export const setGetQuoteEditMode = isEdit => ({
  type: SET_GET_QUOTE_EDIT_MODE,
  isEdit
});

export const getPremium = () => ({ type: GET_PREMIUM });

export const setClaimAmountItems = items => {
  const claims = items.map(item => ({
    text: item.claimValue,
    value: item.claimCode
  }));

  return {
    type: SET_CLAIM_AMOUNT_ITEMS,
    claims
  };
};

export const setNCDItems = items => {
  const ncd = items.map(item => ({ text: item.ncdValue, value: item.ncdCode }));
  return { type: SET_NCD_ITEMS, ncd };
};

export const setCampaignCode = code => {
  return { type: SET_CAMPAIGN_CODE, code };
}

export const setMakeModelItems = items => {
  const getItems = item => {
    let { makeCode, makeValue, model } = item;
    model = model.map(m => ({
      makeCode,
      makeValue,
      value: m.modelCode,
      text: m.modelValue
    }));
    return model;
  };
  let makeModel = items.map(getItems);
  makeModel = []
    .concat(...makeModel)
    .sort((a, b) => (a.text < b.text ? -1 : 1));
  return { type: SET_MAKE_MODEL_ITEMS, makeModel };
};

export const setBodyTypeItems = items => {
  const bodyType = items.map(item => ({
    text: item.bodyTypeValue,
    value: item.bodyTypeCode
  }));
  return { type: SET_BODY_TYPE_ITEMS, bodyType };
};

export const submitGetQuoteVehicleDetails = () => ({
  type: SUBMIT_GET_QUOTE_VEHICLE_DETAILS
});

export const verificationSuccess = response => ({
  type: VERIFICATION_SUCCESS,
  response
});

export const setVerified = verified => ({ type: SET_VERIFIED, verified });

export const saveDriverDetails = premiumData => {
  return { type: SAVE_DRIVER_DETAILS, premiumData };
};

export const saveVehicleDetails = values => {
  return {
    type: SAVE_VEHICLE_DETAILS,
    premiumData: {
      ...values,
      make: values.makeAndModel && values.makeAndModel.makeCode,
      model: values.makeAndModel && values.makeAndModel.value,
      makeAndModel: undefined
    },
    makeAndModel: values.makeAndModel
  };
};

export const refetchPremium = () => ({
  type: REFETCH_PREMIUM
});
