export const initialState = {
  firstTimeInsurance: false,
  vehicleRegistrationNo: "",
  vehicleChasisNo: "",
  vehicleEngineNo: "",
  vehicleBankName: "",
  vehicleInsurer: "",
  vehiclePreviousRegNo: "",
  insurerArray: []
};
