import React from "react";
import { BinarySelector, Radio } from "@dls/web";
import { Error } from "../../../../commonStyledComponent";
import { GET_QUOTE } from "../../../../../constants/strings/staticString.json";
import useCheckMobileScreen from "../../../../../hooks/useCheckMobileScreen";
import { StyledText } from "./styles";

const WorkConditions = ({
  name,
  value,
  error,
  setFieldTouched,
  setFieldValue
}) => {
  const {
    label,
    firstOption,
    secondOption
  } = GET_QUOTE.driverDetails.workingConditions;
  const { isMobile } = useCheckMobileScreen();

  return (
    <>
      {isMobile ? (
        <>
          <StyledText data-testid="mobile-work-condition" type="smallBody">
            {label}
          </StyledText>
          <Radio.InputGroup
            value={value}
            name={name}
            error={error}
            options={[firstOption, secondOption]}
            onChange={(_, option) => {
              setFieldTouched(name, true);
              setFieldValue(name, option.value);
            }}
          />
        </>
      ) : (
        <>
          <StyledText data-testid="work-condition" type="smallBody">
            {label}
          </StyledText>
          <BinarySelector
            value={value}
            name={name}
            firstOption={firstOption}
            secondOption={secondOption}
            onChange={(_, option) => {
              setFieldTouched(name, true);
              setFieldValue(name, option.value);
            }}
          />
          {error && <Error margin={[0, 0, 5, 0]}>{error}</Error>}
        </>
      )}
    </>
  );
};

export default WorkConditions;
