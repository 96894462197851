import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import {
  ORDER_INCOMPLETE,
  CONFIG,
  PAYMENT_SUCCESS,
  POLICY_NUMBER
} from "../../constants/strings/staticString.json";
import { navigate } from "gatsby";
import styled from "styled-components";
import { OrderConfirmation } from '@common-modules/shopping-cart'

const IncompleteSectionOrderWrapper = styled.div`
  padding: 16px 16px;
`

const OrderIncomplete = () => {
  const [ showStatus, setStatus ] = useState(false);
  const { proposal } = useSelector((s) => s.createProposal);
  const backToHome = () => {
    navigate(CONFIG.redirectURL);
  };

  useEffect(() => {
    setStatus(true)  
  }, [])

  const renderOrderIncomplete = () => (<OrderConfirmation
    data={{
      orderNo:  proposal.proposalNumber,
      isLoading:!proposal.proposalNumber,
      customerEmail:  '',
      customerSupport: {
        email: PAYMENT_SUCCESS.email,
        domesticNumber: PAYMENT_SUCCESS.phoneNumber,
        availableTime: `${PAYMENT_SUCCESS.operatingHours} ${PAYMENT_SUCCESS.workingHours}`,
        isShownInWhatNext: false
      },
      promotions: []
    }}
    configs={{
      orderStatus: 'success',
      displayMapping: [
        { itemType: 'OrderStatus' },
        { itemType: 'Info' },
        { itemType: 'WhatNext' },
        { itemType: 'OrderInfo' },
        { itemType: 'BackHome' },
        { itemType: 'HelpBox' }
      ]
    }}
    localisation={{
      ORDER_STATUS_TITLE: ORDER_INCOMPLETE.header,
      ORDER_STATUS_BOLD_TEXT: ORDER_INCOMPLETE.title,
      ORDER_STATUS_BODY_TEXT: POLICY_NUMBER.duration,
      ORDER_WHAT_NEXT_TITLE: ORDER_INCOMPLETE.nextTitle,
      ORDER_WHAT_NEXT_TEXT:ORDER_INCOMPLETE.nextAdvice,
      ORDER_NEXT_STEP_TEXT: PAYMENT_SUCCESS.preConfirmationEmail,
      ORDER_HELP_TITLE: PAYMENT_SUCCESS.needHelp,
      ORDER_STATUS_INFO:ORDER_INCOMPLETE.emailAdvice,
      
    }}
    callbacks={{
      onClickBackBtn: backToHome
    }}
  />)
  
  return (
    {showStatus} && (<IncompleteSectionOrderWrapper> {renderOrderIncomplete()} </IncompleteSectionOrderWrapper>)
  );
};
export default OrderIncomplete;
