import { BulletList, CirclePattern, TextLink, Text } from "@dls/web";
import React from "react";
import { navigate } from "gatsby";

const Benefit = ({
  image,
  title,
  description = "",
  points = [],
  leftRight,
  linkDisplay,
  benefitIndex,
  link
}) => {
  const getCirclePatternBody = () => {
    if (benefitIndex === 0 || benefitIndex === 1) {
      return (
        <Text inline>
          {description}
            <TextLink inline onClick={() => navigate(link)}>{linkDisplay}</TextLink>
        </Text>
      );
    } else if (benefitIndex === 2) {
      const splitedDescription = description.split("@");
      return (
        <Text>
          {splitedDescription[0]}
            <TextLink inline onClick={() => navigate(link)}>{linkDisplay}</TextLink>
          {splitedDescription[1]}
        </Text>
      );
    } else if (benefitIndex === 3) {
      return <>{description}</>;
    }
  };

  return (
    <CirclePattern
      thumbnail={{ url: image, alt: `${title}` }}
      flush={leftRight === 1 ? "left" : "right"}
      title={title}
      type="variant2"
      body={
        description === ""
          ? points.map(point => <BulletList>{point}</BulletList>)
          : getCirclePatternBody()
      }
    />
  );
};

export default Benefit;
