import { Text, TextLink } from "@dls/web";

import { NameContainer } from "./styles";
import React from "react";
import Section from "../Section";

const Summary = ({ name, nric, nationality, gender, dob, onClickEdit }) => {
  return (
    <Section margin={[0, 0, 6, 0]}>
      <NameContainer>
        <Text type="boldBody">{name}</Text>
        <Text type="boldBody">
          <TextLink inline onClick={onClickEdit}>
            Edit
          </TextLink>
        </Text>
      </NameContainer>
      <div>
        <Section margin={[0, 0, 1, 0]}>
          <Text type="body">{nric}</Text>
        </Section>
        <Section margin={[0, 0, 1, 0]}>
          <Text type="body">{nationality}</Text>
        </Section>
        <Section margin={[0, 0, 1, 0]}>
          <Text type="body">{<Text type="body">{gender}</Text>}</Text>
        </Section>
        <Section margin={[0, 0, 1, 0]}>
          <Text type="body">{dob}</Text>
        </Section>
      </div>
    </Section>
  );
};

export default Summary;
