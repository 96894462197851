import {
  SET_POLICY,
  GET_POLICY,
  GET_POLICY_FAILED
} from "../../actions/policyActions/actions.json";
import initialState from "./initialState";

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_POLICY:
      return { ...state, loading: true };
    case GET_POLICY_FAILED:
      return { ...state, loading: false };
    case SET_POLICY:
      return { ...state, loading: false, policy: action.policy };
    default:
      return state;
  }
};
