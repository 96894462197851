import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  createProposalFailure,
  createProposalSuccess
} from "../../actions/createProposalActions";
import {
  driverDetailsSelector,
  extraCoverageSelector,
  getQuoteSelector,
  premiumSelector,
  trackingIdSelector,
  vehicleDetailsSelector,
  paymentDataSelector
} from "../../selectors";

import { CREATE_PROPOSAL } from "../../actions/createProposalActions/actions.json";
import { api } from "../apiSaga";
import { fetchPayment } from "../paymentSaga";
import { navigate } from "gatsby";
import { proposal } from "../../constants/api.json";

export function* createProposalWatcher() {
  yield takeLatest(CREATE_PROPOSAL, createProposal);
}

export function* createProposal() {
  const { premiumData } = yield select(getQuoteSelector);
  const driverDetails = yield select(driverDetailsSelector);
  const extraCoverage = yield select(extraCoverageSelector);
  const premium = yield select(premiumSelector);
  const plan = premium.plans[premium.selectedPlan];
  const benefit = extraCoverage.filter(
    aBenefit => aBenefit.planId === plan.planId
  )[0];
  const vehicleDetails = yield select(vehicleDetailsSelector);
  const { trackingId } = yield select(trackingIdSelector);

  const addOns = benefit
    ? [
        {
          id: benefit.benefitId,
          code: benefit.benefitCode
        }
      ]
    : [];
  const params = {
    trackingId,
    planId: plan.planId,
    planCode: plan.planCode,
    effectiveDate: premiumData.effectiveDate,
    expiredDate: premiumData.expiredDate,
    gender: premiumData.gender,
    dateOfBirth: premiumData.dateOfBirth,
    contactNumberMobile: premiumData.contactNumberMobile,
    licenseRevoked: premiumData.licenseRevoked,
    occupationType: premiumData.occupationType,
    noOfClaimsInPast3Years: premiumData.noOfClaimsInPast3Years,
    claimAmountInPast3Years: premiumData.claimAmountInPast3Years,
    yearOfManufacture: premiumData.yearOfManufacture,
    make: premiumData.make,
    model: premiumData.model,
    engineCapacity: premiumData.engineCapacity,
    bodyType: premiumData.bodyType,
    vehicleInfos: premiumData.vehicleInfos,
    ncd: premiumData.ncd,
    ncdp: plan.planCode === "SV" ? "NO" : premiumData.ncdp,
    sdd: premiumData.sdd,
    registrationNo: vehicleDetails.vehicleRegistrationNo,
    chasisNumber: vehicleDetails.vehicleChasisNo,
    engineNumber: vehicleDetails.vehicleEngineNo,
    loanDetails: vehicleDetails.vehicleBankName, //bank
    insuranceCompanyName: vehicleDetails.firstTimeInsurance
      ? ""
      : vehicleDetails.vehicleInsurer,
    insurancePolicyNumber: "POL12345", //hard code for now
    vehicleNo: vehicleDetails.firstTimeInsurance
      ? ""
      : vehicleDetails.vehiclePreviousRegNo,
    firstOwner: vehicleDetails.firstTimeInsurance,
    nationality: driverDetails.driverNationalityCode,
    nric: driverDetails.driverNric,
    mailingAddrStreet: driverDetails.driverStreet,
    mailingAddrFloor: driverDetails.driverFloorAndUnit || "#0-00",
    postalCode: driverDetails.driverPostal,
    email: driverDetails.driverEmail,
    fullName: driverDetails.driverName,
    addOns: addOns
  };
  const response = yield call(api, proposal, params);
  if (response.success) {
    yield put(createProposalSuccess(response.success));
    const paymentData = yield select(paymentDataSelector);
    yield call(fetchPayment, paymentData);
  } else {
    yield put(createProposalFailure(response.error));
    yield call(navigate, "/error/generic-error");
  }
}
