import { Button, Column } from "@dls/web";

import React from "react";

const PlanCardAction = ({ testid, buttonLabel, onClick, type, disabled, loading = false }) => {
  return (
    <Column>
      <Button
        data-testid={testid}
        fullWidth
        type={type}
        onClick={onClick}
        disabled={disabled}
        text={buttonLabel}
        loading={loading}
      />
    </Column>
  );
};

export default PlanCardAction;
