import React from "react";
import { Card, Text, theme } from "@dls/web";
import styled from "styled-components";
import SanitizedHTML from "../SanitizedHTML";
import Section from "../Section";

const exclusivePlanSelectionClasses = [
  "plan-code",
  "display-order",
  "tabs-title",
  "tabs-selected",
  "plan-tag",
  "coverage-disclaimer"
];

const TitleText = styled(Text)`
  color: ${theme.main.colours.secondary.d2};
  margin-bottom: 16px;
`;

const CoverageDisclaimerText = styled(Text)`
  color: ${theme.main.colours.errorRed};
  margin-top: 16px;
`;

const KeyBenefits = ({ keyBenefits, ncdp }) => {
  return (
    <Section margin={[!!keyBenefits.title ? 0 : 10, 0, 10, 0]}>
      {!!keyBenefits.title && (
        <TitleText type="boldBody">{keyBenefits.title}</TitleText>
      )}
      <Card shadow="sm" radius="sm" padding="lg">
        <Card.Content>
          <SanitizedHTML
            html={keyBenefits.promotionText}
            options={{
              exclusiveFilter: frame => {
                return exclusivePlanSelectionClasses.includes(
                  frame.attribs.class
                );
              }
            }}
          />
        </Card.Content>
      </Card>
      {ncdp && !!keyBenefits.coverageDisclaimer && (
        <CoverageDisclaimerText type="body">
          {keyBenefits.coverageDisclaimer}
        </CoverageDisclaimerText>
      )}
    </Section>
  );
};

export default KeyBenefits;
