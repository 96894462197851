import { takeLatest, call, put } from "redux-saga/effects";
import { navigate } from "gatsby";

import { api } from "../apiSaga";
import { captcha } from "../../constants/api.json";
import { VERIFICATION_SUCCESS } from "../../actions/getQuoteActions/actions.json";
import { setVerified } from "../../actions/getQuoteActions";

export function* captchaWatcher() {
  yield takeLatest(VERIFICATION_SUCCESS, fetchCaptcha);
}

export function* fetchCaptcha(action) {
  const response = yield call(api, captcha, { response: action.response });
  if (response.success) {
    yield put(setVerified(true));
  }

  if (response.error) {
    yield call(navigate, "/error/generic-error");
  }
}
