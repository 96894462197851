import {
  SET_PREVIOUS_INSURER,
  SET_VEHICLE_DETAILS
} from "../../actions/vehicleDetailsActions/actions.json";

import { initialState } from "./initialState";

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_VEHICLE_DETAILS:
      return {
        ...state,
        ...action.payload
      };
    case SET_PREVIOUS_INSURER:
      return {
        ...state,
        insurerArray: action.insurerArray
      };
    default:
      return state;
  }
}
