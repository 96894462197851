import React from "react";
import { DatePicker } from "@dls/web";

import { GET_QUOTE } from "../../../../constants/strings/staticString.json";
import { dateFromString } from "../../../../utils/utilsMethods";
import { addDays, format, subDays, addYears } from "date-fns";

const CoverageStartDate = props => {
  const { label } = GET_QUOTE.vehicleDetails.coveragePeriod;
  const {
    id,
    coverageDate,
    today,
    maxDate,
    hintMessage,
    error,
    setFieldValue,
    setFieldTouched
  } = props;

  const { effective } = coverageDate;

  const coverageDateFormat = date =>
    `${format(date, "dd MMM yyy")} - ${format(
      subDays(addYears(date, 1), 1),
      "dd MMM yyy"
    )}`;

  const before = today || new Date();
  const after = maxDate || addDays(before, 89);

  const calculateCoveragePeriod = pickedDate => {
    //fall back to just prevent app crash
    if (!pickedDate) {
      pickedDate = addDays(new Date(), 1);
    }

    const formatCoveragePeriod = date => format(date, "dd/MM/yyyy");
    const getExpiredDate = date =>
      formatCoveragePeriod(subDays(addYears(date, 1), 1));
    return {
      effective: formatCoveragePeriod(pickedDate),
      expired: getExpiredDate(pickedDate)
    };
  };

  const onConfirm = date => {
    const {
      effective: effectiveDate,
      expired: expiredDate
    } = calculateCoveragePeriod(date);
    setFieldTouched("coverageDate", true);
    setFieldValue("coverageDate", {
      effective: effectiveDate,
      expired: expiredDate
    });
  };

  return (
    <DatePicker.SingleDatePicker
      id={id}
      defaultValue={dateFromString(effective)}
      value={effective}
      label={label}
      disabledDays={{ before, after }}
      hintMessage={hintMessage}
      error={error}
      onChange={date => onConfirm(date || dateFromString(effective))}
      onConfirm={onConfirm}
      onClear={() => {
        setFieldTouched("coverageDate", true);
        setFieldValue("coverageDate", { effective: null, expired: null });
      }}
      formatter={coverageDateFormat}
    />
  );
};

export default CoverageStartDate;
