import { Button, Modal, Text } from "@dls/web";

import { NO_CLAIM_DISCOUNT } from "../../constants/strings/staticString.json";
import React from "react";

const NoClaimDiscountModalContent = ({ onClick }) => {
  return (
    <Modal.Content>
      <Text>{NO_CLAIM_DISCOUNT.content}</Text>
      <br />
      <Button fullWidth secondary onClick={onClick}>
        Okay, got it!
      </Button>
    </Modal.Content>
  );
};

export default NoClaimDiscountModalContent;
