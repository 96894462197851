import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import { Header as BaseHeader } from "@lux/components";
import { api } from "@detox/actions";
import { setUrlParams } from "@lux/helpers";

const checkLoginApi = api.onlineApi.checkLogin;

export const PureHeader = withTheme(props => {
  const { data, baseUrl, shoppingCartUrl } = props;

  const { header } = data;

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkLogin = async () => {
    try {
      const result = await checkLoginApi();
      setIsLoggedIn(result.isLoggedIn);
    } catch (e) {
      // Failed to check login, default isLoggedInTo false
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(
    () => {
      const callbackUrl = `${baseUrl}${header.loginButtonUrl}`;
      const onePassLogoutUrlWithParams = setUrlParams(
        `${process.env.GATSBY_ONEPASS_URL}/userLogout.action?portalTy=1`,
        {
          success_callback_url: callbackUrl
        }
      );

      const hasLogoutButton = header.avatarMenuItems.find(
        item =>
          item.title.toLowerCase() === header.logoutButtonText.toLowerCase()
      );

      // The header datamodel and component does not include a log out button so we append
      // the logout object and give it functionality
      if (!hasLogoutButton) {
        header.avatarMenuItems.push({
          title: header.logoutButtonText,
          href: "/logout", // This href does nothing but provide a vanity url if user hovers over the anchor tag
          onClick: e => {
            e.preventDefault();
            window.location.href = onePassLogoutUrlWithParams;
          }
        });
      }
    },
    [
      baseUrl,
      header.avatarMenuItems,
      header.loginButtonUrl,
      header.logoutButtonText
    ]
  );

  // Set Products & Services as default selection
  header.menuItems[0].item.active = true;

  return (
    <BaseHeader
      baseUrl={baseUrl}
      avatarMenuItems={header.avatarMenuItems}
      firstLevelLinks={header.firstLevelLinks}
      logo={header.logo}
      shoppingCartUrl={shoppingCartUrl}
      showAvatar={isLoggedIn}
      showHamburger={true}
      showLoginButton={!isLoggedIn}
      showSearch={true}
      showShoppingCart={true}
      menuItems={header.menuItems}
      loginUrl={header.loginButtonUrl}
    />
  );
});

PureHeader.propTypes = {
  /** The header datamodel */
  data: PropTypes.object,
  /** Set the base url */
  baseUrl: PropTypes.string,
  /** Set if logged in */
  login: PropTypes.bool,
  /** Set the shopping cart url */
  shoppingCartUrl: PropTypes.string
};

/* istanbul ignore next */
const Header = props => (
  <StaticQuery
    query={graphql`
      {
        header {
          menuItems: headerList {
            item: linkItem {
              href: src
              title
            }
            subMenu {
              subMenu: menuList {
                item: linkItem {
                  subMenu: menuList {
                    item: linkItem {
                      title
                      href: src
                    }
                  }
                  href: src
                  title
                }
              }
              item: menuSectionTitle {
                title
                href: src
              }
            }
          }
          firstLevelLinks: linkItemList {
            title
            href: src
          }
          avatarMenuItems: links {
            title
            href: src
          }
          logo {
            link
            alt
            href: src
          }
          loginButtonUrl
          logoutButtonText
        }
      }
    `}
    render={data => <PureHeader data={data} {...props} />}
  />
);

export default Header;
