import {
  Text,
  theme
} from "@dls/web";

import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  POLICY_NUMBER,
  CONFIG,
  PAYMENT_SUCCESS
} from "../../constants/strings/staticString.json";
import { getPolicy } from "../../actions/policyActions";
import { setProposalNumber } from "../../actions/createProposalActions";
import { navigate } from "gatsby";
import queryString from "query-string";
import styled from "styled-components";
import { OrderConfirmation } from '@common-modules/shopping-cart'
import { fetchPromotions } from "../../actions/promotionsActions";

export const BodyText = styled(Text)`
  color: ${theme.main.colours.tertiary.l3};
`;

export const CenterText = styled(Text)`
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Anchor = styled.a`
  color: ${theme.main.colours.tertiary.base};
`;

const PaymentSuccess = (props) => {
  const [ showStatus, setStatus ] = useState(false);
  let { search, state } = props.location;
  //set default value
  if (!state || !state.hasOwnProperty("fetchPolicy")) {
    state = {
      ...state,
      fetchPolicy: true
    };
  }
  const {
    paymentToken,
    merchantRefNum
  } = queryString.parse(search);
  
  const {
    policy: { policyNumber },
    loading
  } = useSelector((s) => s.policy);

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setStatus(true) 
    }, 1000); 
  }, [])

  const dispatchGetPolicy = React.useCallback(() => {
    if(merchantRefNum && merchantRefNum.includes("-") && state.fetchPolicy) {
      const refNumbAndProposal = merchantRefNum.split("-")
      const proposalNumber = refNumbAndProposal[2]
      
      dispatch(setProposalNumber(proposalNumber));
      dispatch(getPolicy( paymentToken, merchantRefNum));
    }
    
  }, [dispatch, paymentToken, merchantRefNum, state.fetchPolicy]);

  const backToHome = () => {
    navigate(CONFIG.redirectURL);
  };

  React.useEffect(() => {
    dispatchGetPolicy();
  }, [dispatchGetPolicy]);

  const SuccessOrderWrapper = styled.div`
    padding: 16px 16px;
  `

  const renderSuccessOrder = () => 
      (<OrderConfirmation
        data={{
          orderNo: policyNumber ? policyNumber : ``,
          isLoading:loading,
          customerEmail:  '',
          customerSupport: {
            email: PAYMENT_SUCCESS.email,
            domesticNumber: PAYMENT_SUCCESS.phoneNumber,
            availableTime: `${PAYMENT_SUCCESS.operatingHours} ${PAYMENT_SUCCESS.workingHours}`,
            isShownInWhatNext: false
          },
          promotions: []
        }}
        configs={{
          orderStatus: 'success',
          displayMapping: [
            { itemType: 'OrderStatus' },
            { itemType: 'OrderInfo' },
            ...(policyNumber ? [{ itemType: 'WhatNext' }] : []),
            { itemType: 'BackHome' },
            { itemType: 'HelpBox' }
          ]
        }}
        localisation={{
          ORDER_STATUS_TITLE: PAYMENT_SUCCESS.header,
          ORDER_STATUS_BOLD_TEXT: PAYMENT_SUCCESS.title,
          ORDER_STATUS_BODY_TEXT: '',
          ORDER_STATUS_INFO:POLICY_NUMBER.info_description,
          ORDER_NEXT_STEP_TITLE: PAYMENT_SUCCESS.whatHappensNext,
          ORDER_WHAT_NEXT_TEXT:PAYMENT_SUCCESS.preConfirmationEmail,
          ORDER_HELP_TITLE: PAYMENT_SUCCESS.needHelp,
          ORDER_INFO_SUBTITLE:PAYMENT_SUCCESS.policyNumber
          
        }}
        callbacks={{
          onClickBackBtn: backToHome
        }}
      />)
  

  return (
    {showStatus} && (<SuccessOrderWrapper>{renderSuccessOrder()}</SuccessOrderWrapper>)
  );
};
export default PaymentSuccess;
