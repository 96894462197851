import React from "react";
import { Card, Divider, Text, theme, Row, Column } from "@dls/web";
import styled from "styled-components";

import Section from "../../Section";
import { getBenefitDescription } from "../../../utils/ui-logic";

const CoverageText = styled(Text)`
  color: ${theme.main.colours.secondary.d3};
  text-align: right;
`;

const BasicValueContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BenefitCard = ({ benefits, benefitElaboration }) => {
  const benefitDescs = [...new Set(benefits.map(b => b.benefitDesc))];
  const benefitsElaborationsArray =
    !!benefitElaboration && !!benefitElaboration.promotionText
      ? benefitElaboration.promotionText
      : '{"benefitsElaborations":[]}';

  return (
    <Card shadow='sm' radius='sm' padding='lg'>
      <Card.Content>
        {benefitDescs.map((benefitDesc, benefitDescIndex) => (
          <>
            <Row>
              <Column noGutter xs={8}>
                <Text type='boldBody'>{benefitDesc}</Text>
                <Text type='body'>
                  {getBenefitDescription(
                    benefitDesc,
                    benefitsElaborationsArray
                  )}
                </Text>
              </Column>
              {benefits.map(
                benefit =>
                  benefit.benefitDesc === benefitDesc &&
                  !benefit.subsectionDescription && (
                    <Column noGutter xs={4}>
                      <BasicValueContainer>
                        <CoverageText type='boldBody'>
                          {benefit.basicValue}
                        </CoverageText>
                      </BasicValueContainer>
                    </Column>
                  )
              )}
            </Row>
            {benefits.map(
              (benefit, benefitIndex) =>
                benefit.subsectionDescription &&
                benefit.benefitDesc === benefitDesc && (
                  <>
                    <Section margin={[4, 0, 0, 0]}>
                      <Row>
                        <Column noGutter xs={8}>
                          <Text type='body'>
                            {benefit.subsectionDescription}
                          </Text>
                        </Column>
                        <Column noGutter xs={4}>
                          <BasicValueContainer>
                            <CoverageText type='boldBody'>
                              {benefit.basicValue}
                            </CoverageText>
                          </BasicValueContainer>
                        </Column>
                      </Row>
                    </Section>
                  </>
                )
            )}
            {benefitDescIndex + 1 < benefitDescs.length && (
              <Section margin={[6, 0, 6, 0]}>
                <Divider />
              </Section>
            )}
          </>
        ))}
      </Card.Content>
    </Card>
  );
};

export default BenefitCard;
