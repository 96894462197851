import { ERROR } from '../../../constants/strings/staticString.json';
import { Error } from '../../../components';
import React from 'react';
import { navigate } from 'gatsby';

const tryAgain = () => {
  navigate('/');
};
const NoNetwork = () => {
  const { header, title, buttonText } = ERROR.noNetworkError;
  return (
    <Error
      header={header}
      title={title}
      buttonText={buttonText}
      onClick={tryAgain}
      testid="try_again"
    />
  );
};
export default NoNetwork;
