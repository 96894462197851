import { Button, Modal, Text, TextLink } from "@dls/web";

import React from "react";
import { SAFE_DRIVER_MODAL } from "../../constants/strings/staticString.json";

const SafeDriverDiscountModalContent = ({ onClick, onClickLink }) => {
  return (
    <Modal.Content>
      <Text>{SAFE_DRIVER_MODAL.content}</Text>
      <br />
      <Text>
        {SAFE_DRIVER_MODAL.findOutMore}
        <TextLink onClick={onClickLink}>{SAFE_DRIVER_MODAL.link}</TextLink>
      </Text>
      <br />
      <Button fullWidth secondary onClick={onClick}>
        {SAFE_DRIVER_MODAL.button}
      </Button>
    </Modal.Content>
  );
};

export default SafeDriverDiscountModalContent;
