import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column, Text, Row, Grid } from "@dls/web";
import { navigate } from "gatsby";

import {
  Section,
  Page,
  PaddingContainer,
  StepperWrapper
} from "../../components";
import { GET_QUOTE, CONFIG} from "../../constants/strings/staticString.json";
import { VehicleDetailsForm } from "../../components/Forms/GetQuote";
import {
  getInsuranceData,
  setGetQuoteEditMode
} from "../../actions/getQuoteActions";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import useCheckRedux from "../../hooks/useCheckRedux";

const VehicleDetailsPage = ({ location: { state } }) => {
  const { title } = GET_QUOTE.vehicleDetails;
  const { isEdit } = state || false;
  const testingCaptchaToken = state && state.testingCaptchaToken;

  const premium = useSelector((s) => s.premium);
  const plan = premium.plans[premium.selectedPlan];

  const isPremiumLoading = premium.isLoading ? 'inProgress' : false;

  const { isMobile } = useCheckMobileScreen();

  const dispatch = useDispatch();

  const dispatchGetInsuranceData = React.useCallback(
    () => dispatch(getInsuranceData()),
    [dispatch]
  );

  const dispatchSetGetQuoteEditMode = React.useCallback(
    (isEditValue) => dispatch(setGetQuoteEditMode(isEditValue)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatchGetInsuranceData();
    dispatchSetGetQuoteEditMode(isEdit);
  }, [dispatchGetInsuranceData, dispatchSetGetQuoteEditMode, isEdit]);

  const formRef = useRef();

  const isReduxRefreshed = useCheckRedux();
  useEffect(() => {
    if (isReduxRefreshed) {
      navigate(CONFIG.redirectURLRedux);
    }
  }, []);

  return (
    <>
      <Page>
        <PaddingContainer mobile={{ bottom: 16 }}>
          <Grid>
            <Row>
              <StepperWrapper
                totalSteps={GET_QUOTE.stepperStepsQuote.length}
                activeStep={2}
                titles={GET_QUOTE.stepperStepsQuote}
              />
            </Row>
            <Row>
              <Column noGutter xs={12} sm={7}>
                <Section margin={[0, 2, 4, 2]}>
                  <Text type="pageTitle">{title}</Text>
                </Section>
              </Column>
            </Row>
            <VehicleDetailsForm
              isMobile={isMobile}
              plan={plan}
              innerRef={formRef}
              testingCaptchaToken={testingCaptchaToken}
              submitBtnLoading={isPremiumLoading}
            />
          </Grid>
        </PaddingContainer>
      </Page>
    </>
  );
};

export default VehicleDetailsPage;
