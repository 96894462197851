import styled from "styled-components";
import { theme, Text } from "@dls/web";

export const FormSectionContainer = styled.div`
  padding-top: ${theme.space[2]}px;
  padding-bottom: ${theme.space[2]}px;
`;

export const FormSectionHeader = styled(Text)`
  padding-top: ${theme.space[4]}px;
  padding-bottom: ${theme.space[4]}px;
`;

export const LeftAlign = styled.div`
  text-align: left;
`;
