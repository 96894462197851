import React from "react";
import { navigate } from "gatsby";

import { ERROR } from "../../../constants/strings/staticString.json";
import { Error } from "../../../components";

const goToGetQuoteDriverDetails = () => {
  navigate("/get-quote/driver-details");
};

const MyInfo = () => {
  const { header, title, buttonText } = ERROR.myInfoError;
  return (
    <Error
      header={header}
      title={title}
      buttonText={buttonText}
      onClick={goToGetQuoteDriverDetails}
      secondary={false}
    />
  );
};

export default MyInfo;
