import React, { useEffect } from "react";
import { Text } from "@dls/web";
import { useDispatch } from "react-redux";

import { Layout, Section, StepperWrapper } from "../../components";
import {
  DRIVER_DETAILS,
  VEHICLE_DETAILS,
  CONFIG
} from "../../constants/strings/staticString.json";
import { DriverDetailsForm } from "../../components/Forms/PurchasePlan";
import { getCountries } from "../../actions/driverDetailsActions";
import { navigate } from "gatsby";
import useCheckRedux from "../../hooks/useCheckRedux";

const DriverDetails = ({ location }) => {
  const isEdit = location && location.state && location.state.isEdit;
  const dispatch = useDispatch();

  const dispatchGetCountries = React.useCallback(
    () => dispatch(getCountries()),
    [dispatch]
  );
  useEffect(
    () => {
      dispatchGetCountries();
    },
    [dispatchGetCountries]
  );

  const isReduxRefreshed = useCheckRedux();
  useEffect(() => {
    if (isReduxRefreshed) {
      navigate(CONFIG.redirectURLRedux);
    }
  }, []);

  return (
    <Layout md paddingMobileBottom={16}>
      <StepperWrapper
        totalSteps={VEHICLE_DETAILS.stepperStepsPurchasePlan.length}
        activeStep={2}
        titles={VEHICLE_DETAILS.stepperStepsPurchasePlan}
      />
      <Section margin={[0, 0, 8, 2]}>
        <Text type="pageTitle">{DRIVER_DETAILS.title}</Text>
      </Section>
      <DriverDetailsForm isEdit={isEdit} />
    </Layout>
  );
};

export default DriverDetails;
