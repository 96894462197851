import { useSelector } from "react-redux";
import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { paymentSummaryAnalytics } from "../../utils/analyticsUtils";
import {
  getVehicleBodyTypeSummary,
  getVehicleEngineCapacitySummary,
  getYomSummary,
  getVehicleTypesSummary
} from "../../utils/ui-logic.js";

const useManuallyVehicleDetails = () => {
  const {
    vehicleRegistrationNo,
    vehicleChasisNo,
    vehicleEngineNo,
    vehicleBankName
  } = useSelector(s => s.vehicleDetails);
  const { selectedVehicleIndex } = useSelector(s => s.myInfo);

  const bodyTypeSummary = useSelector(getVehicleBodyTypeSummary);
  const engineCapacitySummary = useSelector(getVehicleEngineCapacitySummary);
  const yomSummary = useSelector(getYomSummary);
  const vehicleTypesSummary = useSelector(getVehicleTypesSummary);

  const basicDetails = {
    shouldHide: selectedVehicleIndex !== null,
    title: PAYMENT_SUMMARY.basicDetails,
    items: [
      vehicleRegistrationNo,
      `${PAYMENT_SUMMARY.labelChassisNo} ${vehicleChasisNo}`,
      `${PAYMENT_SUMMARY.labelEngineNo} ${vehicleEngineNo}`,
      vehicleBankName
        ? `${PAYMENT_SUMMARY.financedBy} ${vehicleBankName}`
        : null
    ],
    link: "/vehicle-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titleVehicleDetails,
      "Change"
    )
  };

  const vehicleModel = {
    shouldHide: selectedVehicleIndex !== null,
    title: PAYMENT_SUMMARY.vehicleModel,
    items: [
      bodyTypeSummary,
      engineCapacitySummary,
      yomSummary,
      ...vehicleTypesSummary
    ],
    link: "/get-quote/vehicle-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titleVehicleToBeInsured,
      "Change"
    )
  };

  return [basicDetails, vehicleModel];
};

export default useManuallyVehicleDetails;
