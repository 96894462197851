import React, { useState, useEffect } from "react";
import { theme, Button, Card, Column, Divider, Row, Text } from "@dls/web";
import { Layout, Section, StepperWrapper } from "../../components";
import { addBenefit, removeBenefit } from "../../actions/extraCoverageActions";
import { useDispatch, useSelector } from "react-redux";

import {
  EXTRA_COVERAGE,
  REVIEW_POLICY,
  CONFIG
} from "../../constants/strings/staticString.json";
import { PlanCard } from "../../components/Cards";
import { navigate } from "gatsby";
import styled from "styled-components";
import StickyHeader from "../../components/StickyHeader";
import StickyPlanCard from "../../components/StickyPlanCard";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

import { getGSTInclusiveAmount, priceFormat } from '../../utils/ui-logic';
import useCheckRedux from "../../hooks/useCheckRedux";

export const SectionSpaceBetween = styled(Section)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CoverageText = styled(Text)`
  color: ${theme.main.colours.secondary.d3};
  text-align: right;
`;

const skipToPurchase = () => {
  navigate("/vehicle-details");
};

const ExtraCoverage = () => {
  const dispatch = useDispatch();
  const premium = useSelector((s) => s.premium);
  const extraCoverage = useSelector((s) => s.extraCoverage);
  const plan = premium.plans[premium.selectedPlan];
  const isPremiumLoading = premium.isLoading ? 'inProgress' : false;
  const cachedBenefit = extraCoverage.filter(
    (aBenefit) =>
      aBenefit.planId === plan.planId && aBenefit.benefitCode === "LOSU"
  )[0];
  const benefitArray = plan
    ? plan.benefitList.filter(({ benefitType, benefitCode }) => {
        return benefitType === "ADDITIONAL" && benefitCode === "LOSU";
      })
    : [];
  const benefit = plan && benefitArray[0] ? benefitArray[0] : {};

  const [add, setAdd] = useState(!cachedBenefit);
  const [success, setSuccess] = useState(false);
  const [remove, setRemove] = useState(cachedBenefit);

  const { isMobile } = useCheckMobileScreen();

  const dispatchAddBenefit = React.useCallback(
    (aBenefit) => dispatch(addBenefit(aBenefit)),
    [dispatch]
  );
  const dispatchRemoveBenefit = React.useCallback(
    (aBenefit) => dispatch(removeBenefit(aBenefit)),
    [dispatch]
  );

  const addOnPriceIncludingGST = getGSTInclusiveAmount(benefit?.additionalBenefitPremium, plan?.gstPercentage)

  const isReduxRefreshed = useCheckRedux();
  useEffect(() => {
    if (isReduxRefreshed) {
      navigate(CONFIG.redirectURLRedux);
    }
  }, []);

  return (
    <>
      {isMobile && (
        <>
          <StickyHeader top={60}>
            <StickyPlanCard
              plan={plan}
              totalLabel={EXTRA_COVERAGE.total}
              loading={isPremiumLoading}
              buttonLabel={
                add ? EXTRA_COVERAGE.skipButton : EXTRA_COVERAGE.puchaseButton
              }
              onClick={() => {
                skipToPurchase();
              }}
            />
          </StickyHeader>
          <StickyPlanCard
            plan={plan}
            totalLabel={EXTRA_COVERAGE.total}
            buttonLabel={
              add ? EXTRA_COVERAGE.skipButton : EXTRA_COVERAGE.puchaseButton
            }
            onClick={() => {
              skipToPurchase();
            }}
            isHidden
          />
        </>
      )}
      <Layout md={12} paddingMobileBottom={16}>
        <StepperWrapper
          totalSteps={REVIEW_POLICY.stepperStepsReviewPolicy.length}
          activeStep={3}
          titles={REVIEW_POLICY.stepperStepsReviewPolicy}
        />
        <Section margin={[0, 2, 5, 2]}>
          <Text type="pageTitle">{EXTRA_COVERAGE.title}</Text>
        </Section>
        <Row noGutter>
          <Column noGutter xs={12} md={7}>
            <Section margin={[5, 0, 0, 0]}>
              <Card shadow="sm" radius="sm" padding="lg">
                <Card.Content>
                  <Row>
                    <Column noGutter xs={8}>
                      <Text type="boldBody">{benefit.benefitDesc}</Text>
                    </Column>

                    <Column noGutter xs={4}>
                      <CoverageText type="boldBody">
                        {benefit.basicValue}
                      </CoverageText>
                    </Column>
                  </Row>
                  <Row>
                    <Column noGutter xs={8}>
                      <Text type="smallBody">{EXTRA_COVERAGE.description}</Text>
                    </Column>
                  </Row>
                  <Section margin={[6, 0, 4, 0]}>
                    <Divider />
                  </Section>
                  <SectionSpaceBetween margin={[6, 0, 0, 0]}>
                    <Text type="boldBody">+{priceFormat(addOnPriceIncludingGST)}</Text>
                    {add && (
                      <Button
                        data-testid="add"
                        text={EXTRA_COVERAGE.add_button_label}
                        secondary
                        onClick={() => {
                          setAdd(false);
                          setSuccess(true);
                          setRemove(false);
                          setTimeout(() => {
                            setAdd(false);
                            setSuccess(false);
                            setRemove(true);
                          }, 3000);
                          dispatchAddBenefit(benefit);
                        }}
                      />
                    )}
                    {success && (
                      <Button
                        data-testid="success"
                        title="Success"
                        text="Success"
                        loading={"success"}
                      />
                    )}

                    {remove && (
                      <Button
                        data-testid="remove"
                        text={EXTRA_COVERAGE.remove_button_label}
                        secondary
                        onClick={() => {
                          setAdd(true);
                          setSuccess(false);
                          setRemove(false);
                          dispatchRemoveBenefit(benefit);
                        }}
                      />
                    )}
                  </SectionSpaceBetween>
                </Card.Content>
              </Card>
            </Section>
          </Column>
          <Column sm={1} md={1} />
          {!isMobile && (
            <Column noGutter xs={12} md={4}>
              <PlanCard padding={20} plan={plan} addOnPrice={addOnPriceIncludingGST}>
                <PlanCard.Action
                  testid="skip-to-purchase"
                  loading={isPremiumLoading}
                  buttonLabel={
                    add
                      ? EXTRA_COVERAGE.skipButton
                      : EXTRA_COVERAGE.puchaseButton
                  }
                  onClick={() => {
                    skipToPurchase();
                  }}
                />
              </PlanCard>
            </Column>
          )}
        </Row>
      </Layout>
    </>
  );
};

export default ExtraCoverage;
