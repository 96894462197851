import React from "react";
import sanitizeHtml from "sanitize-html";

const defaultOptions = {
  allowedTags: ["div", "span", "img", "a"],
  allowedAttributes: {
    a: ["href", "target"],
    img: ["src", "class"],
    div: ["class"]
  },
  allowedIframeHostnames: ["www.singtel.com"]
};

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...options })
});

const SanitizedHTML = ({ html, options = {}, className = "" }) => (
  <div dangerouslySetInnerHTML={sanitize(html, options)} class={className} />
);

export default SanitizedHTML;
