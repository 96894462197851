import React from "react";

import PageWrap from "./src/components/PageWrap";

const wrapPageComponent = ({ element }) => {
  return (
    <PageWrap>{element}</PageWrap>
  );
};

export default wrapPageComponent;
