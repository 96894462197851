import React, { useState } from "react";
import { AnimatedCollapsible } from "./styles";

const Collapsible = ({ children, open, minHeight = 0 }) => {
  const [height, setHeight] = useState(0);
  const [opacity, setOpacity] = useState(0);

  const assignHeight = node => {
    if (node) {
      setHeight(open ? node.clientHeight : minHeight);
      setOpacity(open ? 1 : Math.max(1, minHeight));
    }
  };

  return (
    <AnimatedCollapsible height={height} opacity={opacity}>
      <div ref={assignHeight}>{children}</div>
    </AnimatedCollapsible>
  );
};

export default Collapsible;
