import React from "react";
import { Column, Stepper } from "@dls/web";
import { Section } from "../";

const StepperWrapper = ({ totalSteps, activeStep, titles }) => {
  return (
    <Column noGutter xs={12} sm={7}>
      <Section margin={[0, 2, 8, 2]}>
        <Stepper
          totalSteps={totalSteps}
          activeStep={activeStep}
          titles={titles}
        />
      </Section>
    </Column>
  );
};

export default StepperWrapper;
