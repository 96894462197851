import { useSelector } from "react-redux";
import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { paymentSummaryAnalytics } from "../../utils/analyticsUtils";

const usePreviousInsurer = () => {
  const { vehiclePreviousRegNo, vehicleInsurer } = useSelector(
    s => s.vehicleDetails
  );

  return {
    title: PAYMENT_SUMMARY.titlePreviousInsurer,
    items: [vehicleInsurer, vehiclePreviousRegNo],
    link: "/vehicle-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titlePreviousInsurer,
      "Change"
    )
  };
};

export default usePreviousInsurer;
