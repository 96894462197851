import { call, put, takeLatest } from "redux-saga/effects";

import { FETCH_PROMOTIONS } from "../../actions/promotionsActions/actions.json";
import { promotions } from "../../constants/api.json";
import { setPromotions } from "../../actions/promotionsActions";
import { aemAPI } from "../apiSaga";
import { setPdpa } from "../../actions/pdpaActions";

export function * promotionsWatcher () {
  yield takeLatest(FETCH_PROMOTIONS, fetchPromotions);
}

export function * fetchPromotions () {
  const response = yield call(aemAPI, promotions);
  let pdpaBody = {};
  if (response && !response.error && response.success) {
    yield put(setPromotions(response.success));
    response.success.forEach(value => {
      //check pdpa from promotions
      if (!!value && !!value.promotionText && value.promotionText.includes("header")) {
        pdpaBody = JSON.parse(value.promotionText);
      }
    });

    yield put(setPdpa(pdpaBody));
  }
}
