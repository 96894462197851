import { format, differenceInYears } from "date-fns";

import { CONFIG } from "../constants/strings/staticString.json";

export const upperAlphaNumericFormate = value => {
  return value.replace(/[^a-z0-9]/gi, "").toUpperCase();
};

export const emailFormate = value => {
  return value.replace(/[^a-zA-Z0-9.@_-]/gi, "");
};

export const autoCapitalFirstAlpha = value => {
  return value
    .replace(/[^a-zA-Z\s]/gi, "")
    .replace(
      /(^\w|\s\w)(\S*)/g,
      (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
    );
};

export const autoCapitalFirstAlphaWithNumber = value => {
  return value.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
};

export const numberFormate = value => {
  let number = value.match(/\d+/g);
  return number ? number[0] : "";
};

export const dateFromString = dateString => {
  if (dateString) {
    const [day, month, year] = dateString.split("/").map(d => parseInt(d));
    return new Date(year, month - 1, day);
  }
  return null;
};

export const displayDate = date => {
  return date ? format(dateFromString(date), "dd MMM yyyy") : null;
};

export const displayContactNo = contactNo => {
  return contactNo ? contactNo.substr(0, 4) + " " + contactNo.substr(4) : null;
};

export const displayOccupation = occ => {
  if (occ === "I") {
    return "Works in a fixed location";
  }
  if (occ === "O") {
    return "Moves around for work";
  }
  return "";
};

export const displayGender = gender => {
  if (gender === "M") {
    return "Male";
  }
  if (gender === "F") {
    return "Female";
  }
  return null;
};

export const processMyInfoDOB = dob => {
  if (dob) {
    const [day, month, year] = dob.split("/").map(d => parseInt(d));
    if (year && month && day) {
      return format(new Date(year, month - 1, day), "dd/MM/yyyy");
    }
  }
};

export const displayYesOrNo = answer => {
  if (answer === "Y") {
    return "Yes";
  }
  if (answer === "N") {
    return "No";
  }
  return null;
};

export const copyToClipBoard = (navigator, text) =>
  navigator?.clipboard?.writeText(text);

export const isEligibleAge = (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    return false;
  }

  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(0, 0, 0, 0);

  const age = differenceInYears(toDate, fromDate);
  const fromDay = fromDate.getUTCDate();
  const fromMonth = fromDate.getUTCMonth() + 1;
  const day = toDate.getUTCDate();
  const month = toDate.getUTCMonth() + 1;

  if (age === 69 && fromDay === day && fromMonth === month) {
    return true;
  }

  if (age < 26 || age > 68) {
    return false;
  }
  return true;
};

export const partialStringMatch = (stringArray, partialText) => {
  var matchedItems = stringArray.filter(text => {
    return partialText.toUpperCase().indexOf(text) > -1;
  });

  return matchedItems.length > 0;
};

export const mapMyInfoName = name => {
  if (name) {
    return name
      .split(" ")
      .map(n => {
        return n.charAt(0) + n.toLowerCase().slice(1);
      })
      .join(" ");
  }
  return "";
};

export const mapMyInfoNationality = (nationality, countries) => {
  if (nationality) {
    const countryMapped = countries.find(c => c.countryValue === nationality);
    if (countryMapped) {
      return {
        text: countryMapped.countryValue,
        value: countryMapped.countryCode
      };
    } else {
      return {};
    }
  }
  return {};
};

export const mapMyInfoStreet = (block, street) => {
  if (block && street) {
    return `${block} ${street}`.slice(0, 31);
  }
  return "";
};

export const mapMyInfoUnit = (floor, unit) => {
  if (floor && unit) {
    return `${floor}-${unit}`;
  }
  return "";
};

export const mapMyInfoMakeAndModel = (make, model, codes) => {
  const makeData = codes.find(
    c => c.makeValue.toUpperCase() === make.toUpperCase()
  );
  if (makeData) {
    const modelData = makeData.model.find(
      m =>
        m.modelValue.toUpperCase() ===
        `${make.toUpperCase()} - ${model.toUpperCase()}`
    );
    if (modelData) {
      return {
        makeCode: makeData.makeCode,
        makeValue: makeData.makeValue,
        text: modelData.modelValue,
        value: modelData.modelCode
      };
    }
  }
  return "";
};

export const mapMyInfoBodyType = bodyType => {
  if (bodyType) {
    bodyType = bodyType.toUpperCase();
    const { validMyInfoVehicleBodyTypes: vbt } = CONFIG;

    for (let [bodyTypeCode, bodyTypeValidStrings] of Object.entries(vbt)) {
      if (new RegExp(bodyTypeValidStrings.join("|")).test(bodyType)) {
        return bodyTypeCode;
      }
    }
  }
  return null;
};

export const mapMyInfoVehicleType = (propellant, scheme) => {
  let vehicleType = [];

  // it will be marked as
  const isE = !!propellant && propellant.toLowerCase() === "electric";

  const isWEK =
    !!scheme &&
    (scheme.includes("ROPC") ||
      scheme.includes("OPC") ||
      scheme.includes("WEC") ||
      scheme.includes("Weekend"));

  if (isE) {
    vehicleType.push("E");
  }

  if (isWEK) {
    vehicleType.push("WEK");
  }

  return vehicleType;
};

export function getUniqueVehicleTypes (currentVehicleTypes, myInfo) {
  if (
    myInfo.selectedVehicleIndex === null ||
    myInfo.details.vehicleInfos.length === 0
  ) {
    return currentVehicleTypes;
  }
  const selectedVehicle =
    myInfo.details.vehicleInfos[myInfo.selectedVehicleIndex];
  const myInfoVehicleTypes = mapMyInfoVehicleType(
    selectedVehicle.propellant,
    selectedVehicle.scheme
  );

  return [...new Set(currentVehicleTypes.concat(myInfoVehicleTypes))];
}

export const myInfoEngineCapacity = myInfo => {
  if (
    myInfo.selectedVehicleIndex === null ||
    myInfo.details.vehicleInfos.length === 0
  ) {
    return null;
  }
  return myInfo.details.vehicleInfos[myInfo.selectedVehicleIndex]
    .engineCapacity;
};
