import initialState from "./initialState";
import {
  SET_GET_QUOTE_EDIT_MODE,
  SET_CLAIM_AMOUNT_ITEMS,
  SET_NCD_ITEMS,
  SET_MAKE_MODEL_ITEMS,
  SET_BODY_TYPE_ITEMS,
  SET_VERIFIED,
  SAVE_DRIVER_DETAILS,
  SAVE_VEHICLE_DETAILS,
  SET_CAMPAIGN_CODE
} from "../../actions/getQuoteActions/actions.json";

export default (getQuote = initialState, action = {}) => {
  switch (action.type) {
    case SET_GET_QUOTE_EDIT_MODE:
      return { ...getQuote, isEdit: action.isEdit };
    case SET_CLAIM_AMOUNT_ITEMS:
      return { ...getQuote, claimsItems: action.claims };
    case SET_NCD_ITEMS:
      return { ...getQuote, ncdItems: action.ncd };
    case SET_MAKE_MODEL_ITEMS:
      return { ...getQuote, makeModelItems: action.makeModel };
    case SET_BODY_TYPE_ITEMS:
      return { ...getQuote, bodyTypeItems: action.bodyType };
    case SET_VERIFIED:
      return {
        ...getQuote,
        verified: action.verified
      };
    case SAVE_DRIVER_DETAILS:
      return {
        ...getQuote,
        premiumData: {
          ...getQuote.premiumData,
          ...action.premiumData
        }
      };
    case SAVE_VEHICLE_DETAILS:
      return {
        ...getQuote,
        makeAndModel: action.makeAndModel,
        premiumData: {
          ...getQuote.premiumData,
          ...action.premiumData
        }
      };
    case SET_CAMPAIGN_CODE: 
      return {
        ...getQuote,
        premiumData: {...getQuote.premiumData, campaignCode:action.code}
    }
    default:
      return getQuote;
  }
};
