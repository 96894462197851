import { call, put } from "redux-saga/effects";
import { navigate } from "gatsby";

import { authenticate } from "../../constants/api.json";
import { request } from "../../utils/networkUtils";
import { setToken } from "../../actions/tokenActions";
import errorCodes from "../../constants/errorCodes";

export function* auth() {
  const baseURL = process.env.API_BASE_URL;
  const { url, method, TenantId } = authenticate;

  const response = yield call(request, baseURL, url, method, undefined, {
    "Content-Type": "application/json",
    TenantId
  });

  if (response.success) {
    const { jwtToken } = response.success;
    yield put(setToken(jwtToken));
    return jwtToken;
  }

  if (response.error) {
    if(response.error.response && response.error.response.data && response.error.response.data.result && errorCodes["JWT_EXPIRED"].includes(response.error.response.data.result))
      yield call(navigate, "/error/session-time-out");
    } else {
      yield call(navigate, "/error/generic-error");
    }
}
