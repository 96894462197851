import { Modal, Text, TextLink } from "@dls/web";
import { Completed } from "@dls/assets/icons";
import React from "react";
import {
  SAFE_DRIVER_MODAL,
  GET_QUOTE
} from "../../../../constants/strings/staticString.json";
import { SafeDriverDiscountModalContent } from "../../../../components/Modals";
import { openExternalURLNewTab } from "../../../../utils/navigationUtils";
import { trackEvent } from "../../../../utils/analyticsUtils";

const SDDModal = ({ isMyInfo = false }) => {
  const [visible, setVisible] = React.useState(false);
  const {
    notSure,
    findOutHere,
    labelEligible,
    findOutWhy,
    analytics
  } = GET_QUOTE.driverDetails.tpCertOfMerit;

  const open = () => {
    trackEvent(analytics);
    setVisible(true);
    
  };
  const close = () => setVisible(false);
  const openLink = () => openExternalURLNewTab(SAFE_DRIVER_MODAL.merit_link);

  return isMyInfo ? (
    <>
      <div style={{ display: "inline-block" }}>
        <Text>
          <Completed
            size="32"
            color="rgb(12, 170, 143)"
            style={{ marginBottom: "-8px" }}
          />
          &nbsp; &nbsp; {labelEligible}&nbsp;
          <TextLink inline onClick={open}>
            {findOutWhy}
          </TextLink>
        </Text>
        <br />
      </div>
      <Modal title={SAFE_DRIVER_MODAL.title} visible={visible} onClose={close}>
        <SafeDriverDiscountModalContent
          onClick={close}
          onClickLink={openLink}
        />
      </Modal>
    </>
  ) : (
    <>
      <div style={{ marginLeft: "48px" }}>
        <Text>
          {notSure}&nbsp;
          <TextLink inline onClick={open}>
            {findOutHere}
          </TextLink>
        </Text>
        <br />
      </div>
      <Modal title={SAFE_DRIVER_MODAL.title} visible={visible} onClose={close}>
        <SafeDriverDiscountModalContent
          onClick={close}
          onClickLink={openLink}
        />
      </Modal>
    </>
  );
};

export default SDDModal;
