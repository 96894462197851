import { call, put, takeLatest } from "redux-saga/effects";

import { GET_PDPA } from "../../actions/pdpaActions/actions.json";
import { pdpa } from "../../constants/api.json";
import { setPdpa } from "../../actions/pdpaActions";
import { api } from "../apiSaga";

export function* pdpaWatcher() {
  yield takeLatest(GET_PDPA, fetchPdpa);
}

export function* fetchPdpa() {
  const response = yield call(api, pdpa);
  if (response.success) {
    yield put(setPdpa(response.success));
  }
}
