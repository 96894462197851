import React from "react";
import { theme } from "@dls/web";
import { Attention } from "@dls/assets/icons";

import { WarningContainer } from "./styles";
import Section from "../../Section";

const MyInfoDetailWarning = ({ children }) => {
  return (
    <Section padding={[0, 0, 2, 0]}>
      <WarningContainer>
        <div style={{ alignSelf: "flex-start" }}>
          <Attention size={32} color={theme.main.colours.primary.d1} />
        </div>
        <div style={{ marginLeft: `${theme.space[4]}px` }}>{children}</div>
      </WarningContainer>
    </Section>
  );
};

export default MyInfoDetailWarning;
