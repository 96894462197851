import styled from "styled-components";
import { Text, theme } from "@dls/web";

export const Container = styled.div`
  padding-top: ${theme.space[7]}px;
  padding-right: ${theme.space[6]}px;
  padding-left: ${theme.space[6]}px;
  visibility: ${props => props.isHidden ? "hidden" : "visible"};
  background-color: ${props => props.isHidden
    ? theme.main.colours.tertiary.l6
    : theme.main.colours.white};
`;

export const ColumnContainer = styled(Text)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  height: 40px;
  align-self: center;
`;

export const PriceContainer = styled(Text)`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 24px;
`;

export const TotalText = styled(Text)`
  font-family: AvenirLTStd-Book, Arial, Helvetica, sans-serif;
  font-size: 16px;
`;

export const PriceText = styled(Text)`
  font-family: AvenirLTStd-Heavy, Arial, Helvetica, sans-serif;
  font-size: 20px;
`;
