import {
  SET_PREMIUM,
  SELECT_PLAN,
  UPDATE_PLAN,
  SET_PREMIUM_LOADING
} from "../../actions/premiumActions/actions.json";
import initialState from "./initialState";

export default (premium = initialState, action = {}) => {
  switch (action.type) {
    case SET_PREMIUM:
      return { ...premium, plans: action.premium, isLoading: false };
    case SELECT_PLAN:
      return { ...premium, selectedPlan: action.selectedPlan };
    case UPDATE_PLAN:
      const updatedPlans = premium.plans.map(plan =>
        plan.planCode === action.plan.planCode ? action.plan : plan
      );
      return { ...premium, plans: updatedPlans, isLoading: false };
    case SET_PREMIUM_LOADING:
      return { ...premium, isLoading: action.isLoading }
    default:
      return premium;
  }
};
