import { TextLink, Text } from "@dls/web";
import { Layout, Section } from "../../components";
import { navigate } from "gatsby";

import {
  OCCUPATION_FAIL
} from "../../constants/strings/staticString.json";
import React from "react";

const OccupationFailPage = () => {
  return (
    <Layout md={7}>
      <Section margin={[0, 2, 0, 2]}>
        <Text type="pageTitle">{OCCUPATION_FAIL.header}</Text>
      </Section>
      <Section margin={[6, 2, 8, 2]}>
        <Text type="body">
          {`${OCCUPATION_FAIL.title_start} `}
          <TextLink
            inline
            data-testid="form-link"
            onClick={() => navigate(OCCUPATION_FAIL.great_eastern_form_url)}
          >
            {OCCUPATION_FAIL.form_link}
          </TextLink>
          {` ${OCCUPATION_FAIL.title_end}`}
        </Text>
      </Section>
    </Layout>
  );
};

export default OccupationFailPage;
