import React from "react";
import { InputText, Text } from "@dls/web";

import Section from "../../../Section";
import { upperAlphaNumericFormate } from "../../../../utils/utilsMethods";
import { VEHICLE_DETAILS } from "../../../../constants/strings/staticString.json";

const PreviousRegistration = ({
  name,
  value,
  error,
  hintMessage,
  setFieldTouched,
  setFieldValue
}) => {
  const { label } = VEHICLE_DETAILS.vehicleRegistration;

  return (
    <>
      <InputText
        name={name}
        label={label}
        value={value}
        error={error}
        hintMessage={hintMessage}
        onChange={e => {
          setFieldTouched(name, false);
          setFieldValue(name, upperAlphaNumericFormate(e.target.value));
        }}
        onBlur={() => setFieldTouched(name, true)}
      />
      <Section margin={[2, 0, 2, 0]}>
        <Text type="smallBody">{VEHICLE_DETAILS.footerText}</Text>{" "}
      </Section>
    </>
  );
};

export default PreviousRegistration;
