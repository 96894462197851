import { Card, Text } from "@dls/web";
import { PlanCardContainer, RowContainer } from "./styles";
import PlanCardAction from "./PlanCardAction";
import React from "react";
import Section from "../../Section";
import { useSelector } from "react-redux";
import { REVIEW_POLICY } from "../../../constants/strings/staticString.json";
import styled from "styled-components";
import { premiumTitles, priceFormat } from "../../../utils/ui-logic";

const PlanCard = React.forwardRef(
  (
    {
      plan,
      children,
      padding = 0,
      shouldShowOtherInfo = true,
      useCardLayout = true,
      addOnPrice
    },
    ref
  ) => {
    const { total } = REVIEW_POLICY.planCard;
    const extraCoverage = useSelector(s => s.extraCoverage);
    const { ncd, sdd } = useSelector(s => s.getQuote.premiumData);
    const {
      totalDiscountPercentage,
      totalPremiumAfterGST,
      grossPremiumAmount,
      planId
    } = plan || {};
    const benefit = extraCoverage.filter(
      aBenefit => aBenefit.planId === planId
    )[0];

    const renderTextSection = (title, texts) => {
      const TitleText = styled(Text)`
        margin-top: 0px;
        margin-bottom: 16px;
      `;

      const DiscountText = styled(Text)`
        margin-bottom: 4px;
      `;

      return (
        texts &&
        texts.length > 0 && (
          <>
            {title && <TitleText type="boldBody">{title}</TitleText>}
            {texts.map((text, index) => (
              <DiscountText type="body" key={index}>
                {text}
              </DiscountText>
            ))}
            <Section margin={[8, 0, 8, 0]} />
          </>
        )
      );
    };

    const renderKeyValueSection = (title, mappings) => {
      const TitleText = styled(Text)`
        margin-top: 0px;
        margin-bottom: 16px;
      `;
      return (
        mappings?.length > 0 && (
          <>
            {title && <TitleText type="boldBody">{title}</TitleText>}
            {mappings.map(({key, value}, index) => (
              <RowContainer>
                 <Text type="body" key={index}>
                  {key}
                </Text>
                <Text type="body" key={index}>
                  {value}
                </Text>
              </RowContainer>
            ))}
            <Section margin={[8, 0, 8, 0]} />
          </>
        )
      );
    }

    const validSingtelDiscount =
      totalDiscountPercentage &&
      Math.abs(parseInt(totalDiscountPercentage)) > 0;

    const discountSectionTexts = () => {
      let texts = [];
      const premiumDiscountTitles = premiumTitles(ncd, sdd);
      if (!!grossPremiumAmount && premiumDiscountTitles !== "") {
        texts = [...premiumDiscountTitles];
      }
      if (validSingtelDiscount) {
        texts.push(
          `${Math.abs(parseInt(totalDiscountPercentage))}% Premium Discount`
        );
      }
      return texts;
    };
    return (
      <PlanCardContainer ref={ref} padding={padding}>
        <Card
          padding="lg"
          shadow={useCardLayout && "sm"}
          radius={useCardLayout && "sm"}
        >
          <Card.Content>
            {shouldShowOtherInfo &&
              benefit &&
              renderKeyValueSection("Add-ons", [{ key: benefit.benefitDesc, value: priceFormat(addOnPrice) }])}
            {shouldShowOtherInfo &&
              renderTextSection("Discounts applied", discountSectionTexts())}
            <Section margin={[0, 0, 6, 0]}>
              <RowContainer>
                <Text type="boldBody">{total}</Text>
                <Text type="header">{priceFormat(totalPremiumAfterGST)}</Text>
              </RowContainer>
            </Section>
            {children}
          </Card.Content>
        </Card>
      </PlanCardContainer>
    );
  }
);

PlanCard.Action = PlanCardAction;

export default PlanCard;
