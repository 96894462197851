import {
  GET_POLICY,
  GET_POLICY_FAILED,
  SET_POLICY,
  GET_POLICY_STATUS,
  SET_POLICY_STATUS,
  SET_LOADING
} from "./actions.json";

export const getPolicy = (paymentToken, merchantRefNum) => ({
  type: GET_POLICY,
  paymentToken, merchantRefNum
});

export const getPolicyFailed = () => ({
  type: GET_POLICY_FAILED
});

export const setPolicy = policy => ({
  type: SET_POLICY,
  policy
});

export const getPolicyStatus = transactionId => ({
  type: GET_POLICY_STATUS,
  transactionId
});

export const setPolicyStatus = policyStatus => ({
  type: SET_POLICY_STATUS,
  policyStatus
});

export const setLoading = isLoading => ({
  type: SET_LOADING,
  isLoading
});
