import React from "react";
import { InputText, Text } from "@dls/web";
import Section from "../../../Section";

import { DRIVER_DETAILS } from "../../../../constants/strings/staticString.json";
import { checkValidEnglishChars } from "../../../../utils/validation";

const FloorUnit = ({ name, value, setFieldValue }) => {
  const { floorAndUnit, leaveBlank } = DRIVER_DETAILS;

  return (
    <>
      <InputText
        label={floorAndUnit}
        value={value}
        onChange={e => {
          if (e.target.value === "" || checkValidEnglishChars(e.target.value)) {
            setFieldValue(name, e.target.value);
          }
        }}
      />
      <Section margin={[2, 0, 0, 0]} />
      <Text type="smallBody">{leaveBlank}</Text>
    </>
  );
};

export default FloorUnit;
