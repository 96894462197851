import React from "react";
import { InputText } from "@dls/web";
import { GET_QUOTE } from "../../../../constants/strings/staticString.json";

const Contact = ({
  name,
  error,
  value,
  hintMessage,
  setFieldTouched,
  setFieldValue
}) => {
  const { contact } = GET_QUOTE.driverDetails;

  return (
    <InputText
      name={name}
      displayFormat="mobile-sg"
      label={contact.label}
      error={error}
      hintMessage={hintMessage}
      onBlur={() => setFieldTouched(name, true)}
      value={value}
      onChange={e => {
        setFieldTouched(name, false);
        setFieldValue(name, e.target.value);
      }}
    />
  );
};

export default Contact;
