import styled from "styled-components";
import { Text } from "@dls/web";

export const FlexBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const Column = styled.div`
  flex-direction: column;
`;

export const Title = styled(Text)`
  flex: 1;
  margin-right: 24px;
`;

export const ChangeButton = styled(Text)`
  text-align: right;
`;
