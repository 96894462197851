import React from "react";
import { navigate } from "gatsby";
import {
  Row,
  Column,
  Button,
  DropdownInput,
  Text,
  Layout,
  Checkbox
} from "@dls/web";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import { saveDriverDetails } from "../../../../actions/getQuoteActions";
import {
  showTotalClaimAmountLogic,
  showTPCEligible,
  showNcdProtectorLogic,
  showTPCertLogic,
  showDateOfBirthLogic,
  showGenderLogic,
  showFieldsBelowPastClaims
} from "../../../../utils/ui-logic";

import {
  getDobError,
  getContactNoError,
  removeContactCountryCode
} from "../../../../utils/validation";

import Section from "../../../Section";
import NCDModal from "./NCDModal";
import SDDModal from "./SDDModal";
import DateOfBirth from "./DateOfBirth";
import Contact from "./Contact";
import LicenseRevoked from "./LicenseRevoked";
import WorkConditions from "./WorkConditions";
import Gender from "./Gender";
import FormikOnError from "../../FormikOnError";

import { FormSectionHeader } from "../styles";
import { GET_QUOTE } from "../../../../constants/strings/staticString.json";

export default ({ innerRef }) => {
  const dispatch = useDispatch();

  const {
    claimsItems,
    premiumData: {
      noOfClaimsInPast3Years,
      claimAmountInPast3Years,
      ncd,
      ncdp,
      sdd,
      dateOfBirth,
      contactNumberMobile,
      licenseRevoked,
      occupationType,
      gender
    },
    ncdItems,
    isEdit
  } = useSelector((s) => s.getQuote);

  const myInfo = useSelector((s) => s.myInfo);
  const showTPCEligibleLabel = useSelector(showTPCEligible);
  const showGenderField = useSelector(showGenderLogic);

  const shouldShowContactGEButton = (errors, touched) => {
    return (
      errors.noOfClaimsInPast3Years ||
      errors.claimAmountInPast3Years ||
      isDOBIneligible(errors, touched)
    );
  };

  const isDOBIneligible = (errors, touched) => {
    return (
      touched &&
      touched.dateOfBirth &&
      errors.dateOfBirth &&
      errors.dateOfBirth !== dateOfBirthStr.requiredMessage
    );
  };

  const {
    noOfClaims,
    totalClaimAmount,
    noClaimDiscount,
    ncdProtector,
    safeDriverDiscount,
    tpCertOfMerit,
    claimFormTitle,
    driverToBeInsured,
    bottomButton,
    contactGreatEastern,
    viewPaymentSummary,
    dateOfBirth: dateOfBirthStr,
    licenseRevoked: licenseRevokedStr,
    workingConditions,
    gender: genderStr
  } = GET_QUOTE.driverDetails;

  const contactGE = () => {
    saveDriverDetailsFormInfo(innerRef.current.values);
    navigate("/occupation-fail");
  };

  const saveDriverDetailsFormInfo = (values) => {
    values.contactNumberMobile = removeContactCountryCode(
      values.contactNumberMobile
    );
    values.ncdp = showNcdProtectorLogic(values.ncd) ? values.ncdp : "NO";
    values.sdd = showTPCertLogic(values.ncd, myInfo) ? values.sdd : "NO";
    values.dateOfBirth = values.dateOfBirth.replace(/ /g, "");
    dispatch(saveDriverDetails(values));
  }

  return (
    <>
      <Formik
        innerRef={innerRef}
        enableReinitialize
        initialValues={{
          noOfClaimsInPast3Years,
          claimAmountInPast3Years,
          ncd,
          ncdp,
          sdd,
          dateOfBirth,
          contactNumberMobile,
          licenseRevoked,
          occupationType,
          gender
        }}
        validate={(values) => {
          const errors = {};

          if (values.noOfClaimsInPast3Years > 1) {
            errors.noOfClaimsInPast3Years = noOfClaims.errorMessage;
          }
          if (values.claimAmountInPast3Years > 10000) {
            errors.claimAmountInPast3Years = totalClaimAmount.errorMessage;
          }
          if (!values.ncd) {
            errors.ncd = noClaimDiscount.requiredMessage;
          }
          // validate dob
          if (showDateOfBirthLogic(myInfo) && !values.dateOfBirth) {
            errors.dateOfBirth = dateOfBirthStr.invalidDateError;
          }
          if (getDobError(values.dateOfBirth)) {
            errors.dateOfBirth = getDobError(values.dateOfBirth);
          }
          if (getContactNoError(values.contactNumberMobile)) {
            errors.contactNumberMobile = getContactNoError(
              values.contactNumberMobile
            );
          }
          if (!values.licenseRevoked) {
            errors.licenseRevoked = licenseRevokedStr.requiredMessage;
          }
          if (!values.occupationType) {
            errors.occupationType = workingConditions.requiredMessage;
          }
          if (!values.gender) {
            errors.gender = genderStr.requiredMessage;
          }
          return errors;
        }}
        onSubmit={(values) => {
          saveDriverDetailsFormInfo(values)
          if (isEdit) {
            navigate("/payment-summary");
          } else {
            navigate("/get-quote/vehicle-details");
          }
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          setValues,
          touched
        }) => {
          return (
            <FormikOnError>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Column xs={12} sm={7}>
                    <>
                      <FormSectionHeader type="header">
                        {driverToBeInsured}
                      </FormSectionHeader>
                      <Layout.Stack space={2}>
                        {showDateOfBirthLogic(myInfo) && (
                          <>
                            <DateOfBirth
                              name="dateOfBirth"
                              value={values.dateOfBirth}
                              error={touched.dateOfBirth && errors.dateOfBirth}
                              hintMessage={
                                touched.dateOfBirth ? errors.dateOfBirth : null
                              }
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                            />
                          </>
                        )}
                        {!isDOBIneligible(errors, touched) && (
                          <>
                            <Contact
                              name="contactNumberMobile"
                              error={
                                touched.contactNumberMobile &&
                                errors.contactNumberMobile
                              }
                              hintMessage={
                                touched.contactNumberMobile
                                  ? errors.contactNumberMobile
                                  : null
                              }
                              value={values.contactNumberMobile}
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                            />
                            <Layout.Stack space={2}>
                              <LicenseRevoked
                                name="licenseRevoked"
                                value={values.licenseRevoked}
                                error={
                                  touched.licenseRevoked &&
                                  errors.licenseRevoked
                                }
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                              />
                              {/* <Section margin={[2, 0, 2, 0]}/> */}
                              {showGenderField && (
                                  <Gender
                                    name="gender"
                                    value={values.gender}
                                    error={touched.gender && errors.gender}
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                  />
                              )}

                              <WorkConditions
                                name="occupationType"
                                value={values.occupationType}
                                error={
                                  touched.occupationType &&
                                  errors.occupationType
                                }
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                              />
                            </Layout.Stack>
                          </>
                        )}
                      </Layout.Stack>
                    </>

                    <FormSectionHeader type="header">
                      {claimFormTitle}
                    </FormSectionHeader>
                    <DropdownInput
                      {...noOfClaims}
                      name="noOfClaimsInPast3Years"
                      defaultValue={values.noOfClaimsInPast3Years}
                      onChange={(item) => {
                        if (item.value < 2) {
                          setValues({...values, "noOfClaimsInPast3Years": item.value, "claimAmountInPast3Years": item.value }, true);
                        }else{
                          setFieldValue("noOfClaimsInPast3Years", item.value);
                        }
                      }}
                      hintMessage={errors.noOfClaimsInPast3Years}
                      error={!!errors.noOfClaimsInPast3Years}
                    />
                    {showTotalClaimAmountLogic(
                      values.noOfClaimsInPast3Years
                    ) && (
                      <>
                        <Section margin={[3, 0, 3, 0]} />
                        <DropdownInput
                          name="claimAmountInPast3Years"
                          label={totalClaimAmount.label}
                          items={claimsItems}
                          defaultValue={values.claimAmountInPast3Years}
                          onChange={(item) => {
                            setFieldValue(
                              "claimAmountInPast3Years",
                              item.value
                            );
                          }}
                          hintMessage={errors.claimAmountInPast3Years}
                          error={!!errors.claimAmountInPast3Years}
                        />
                      </>
                    )}
                    {showFieldsBelowPastClaims(
                      values.noOfClaimsInPast3Years,
                      values.claimAmountInPast3Years
                    ) && (
                      <>
                        <Section margin={[3, 0, 3, 0]} />
                        <DropdownInput
                          {...noClaimDiscount}
                          id="ncd"
                          name="ncd"
                          items={ncdItems}
                          defaultValue={values.ncd}
                          onChange={(item) => {
                            setFieldTouched("ncd", true);
                            setFieldValue("ncd", item.value);
                          }}
                          hintMessage={touched.ncd && errors.ncd}
                          error={touched.ncd ? errors.ncd : null}
                        />
                        <NCDModal />
                        {showNcdProtectorLogic(values.ncd) && (
                          <Section margin={[4, 0, 8, 0]}>
                            <Checkbox.Input
                              name="ncdp"
                              label={ncdProtector.label}
                              checked={ncdp === "YES"}
                              onChange={(_, checked) => {
                                setFieldValue("ncdp", checked ? "YES" : "NO");
                              }}
                            />
                            <div style={{ marginLeft: "48px" }}>
                              <Text type="body">
                                {ncdProtector.description}
                              </Text>
                            </div>
                          </Section>
                        )}
                        {showTPCertLogic(values.ncd, myInfo) && (
                          <>
                            <FormSectionHeader type="header">
                              {safeDriverDiscount}
                            </FormSectionHeader>
                            {showTPCEligibleLabel ? (
                              <>
                                <SDDModal isMyInfo={true} />
                              </>
                            ) : (
                              <>
                                <Checkbox.Input
                                  name="sdd"
                                  label={tpCertOfMerit.label}
                                  checked={sdd === "YES"}
                                  onChange={(_, checked) => {
                                    setFieldValue(
                                      "sdd",
                                      checked ? "YES" : "NO"
                                    );
                                  }}
                                />
                                <SDDModal />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Column>
                </Row>
                <Row>
                  <Column noGutter xs={12} md={7}>
                    <Row>
                      {!shouldShowContactGEButton(errors, touched) && (
                        <Column xs={12} sm={6} md={6}>
                          <Button
                            data-testid="enter-vehicle-details"
                            fullWidth
                            type="submit"
                          >
                            {isEdit ? viewPaymentSummary : bottomButton}
                          </Button>
                        </Column>
                      )}

                      {shouldShowContactGEButton(errors, touched) && (
                        <Column xs={12} sm={6} md={6}>
                          <Button fullWidth onClick={contactGE}>
                            {contactGreatEastern}
                          </Button>
                        </Column>
                      )}
                    </Row>
                  </Column>
                </Row>
              </form>
            </FormikOnError>
          );
        }}
      </Formik>
    </>
  );
};
