import { Row, Text, TextLink } from "@dls/web";

import React from "react";
import Section from "../Section";
import { TitleContainer } from "./styles";

const Summary = ({ title, children, onClickEdit, testid }) => {
  return (
    <>
      {title && (
        <TitleContainer>
          <Section margin={[0, 0, 1, 0]}>
            <Row start="xs" middle="xs">
              <Text type="boldBody">{title}</Text>
            </Row>
          </Section>
          {onClickEdit && (
            <Row start="xs" middle="xs">
              <Text type="boldBody">
                <TextLink data-testid={testid} inline onClick={onClickEdit}>
                  Change
                </TextLink>
              </Text>
            </Row>
          )}
        </TitleContainer>
      )}
      {children}
    </>
  );
};

export default Summary;
