import React, { useEffect } from "react";
import { DropdownInput } from "@dls/web";
import { uniqBy } from "lodash";

import { DRIVER_DETAILS } from "../../../../constants/strings/staticString.json";

const Nationality = ({
  name,
  value,
  countries,
  error,
  hintMessage,
  setFieldTouched,
  setFieldValue
}) => {
  const { label } = DRIVER_DETAILS.nationality;
  const [filteredItems, setFilteredItems] = React.useState(countries);

  useEffect(
    () => {
      setFilteredItems(countries);
    },
    [countries]
  );

  const defaultNationality = countries.filter(item => {
    return value ? item.text === value.text : false;
  });

  const onSearch = (event, searchText) => {
    const items = countries.filter(({ text }) => {
      return text.toLowerCase().includes(searchText.toLowerCase());
    });

    setFilteredItems(
      searchText === "" ? items : uniqBy(items, item => item.text)
    );
  };

  return (
    <DropdownInput
      id={name}
      label={label}
      defaultValue={
        defaultNationality.length > 0 ? defaultNationality[0].value : ""
      }
      items={filteredItems}
      onChange={item => {
        setFieldTouched(name, false);
        setFieldValue(name, item);
      }}
      hasSearch
      onSearch={onSearch}
      error={error}
      hintMessage={hintMessage}
    />
  );
};
export default Nationality;
