import axios from "axios";

export const request = async (
  baseURL,
  url,
  method = "get",
  data = {},
  headers = { "Content-Type": "application/json" }
) => {
  try {
    const response = await axios.request({
      baseURL,
      url,
      method,
      data,
      headers
    });
    if (response.status === 200) {
      return { success: response.data };
    } else {
      return { error: response.status };
    }
  } catch (error) {
    return { error };
  }
};
