import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "gatsby";
import {
  vehicleRegistrationSelector,
  vehiclePreviousRegistrationSelector,
  showVehicleRegistrationLogic,
  showVehicleChassisNoLogic,
  vehicleChassisNoSelector,
  vehicleEngineNoSelector,
  showVehicleEngineNoLogic
} from "../../../../utils/ui-logic";
import { Row, Column, Text, Layout, Button } from "@dls/web";

import Section from "../../../Section";
import VehicleRegistration from "./VehicleRegistration";
import ChassisNumber from "./ChassisNumber";
import EngineNumber from "./EngineNumber";
import BankName from "./BankName";
import FirstTime from "./FirstTime";
import PreviousInsurer from "./PreviousInsurer";
import PreviousRegistration from "./PreviousRegistration";
import FormikOnError from "../../FormikOnError";
import { setVehicleDetails } from "../../../../actions/vehicleDetailsActions";

import { VEHICLE_DETAILS } from "../../../../constants/strings/staticString.json";
import { Formik } from "formik";

const VehicleDetailsForm = ({ isEdit }) => {
  const dispatch = useDispatch();
  const vehicleRegistrationNo = useSelector(vehicleRegistrationSelector);
  const vehiclePreviousRegNo = useSelector(vehiclePreviousRegistrationSelector);
  const showVehicleRegistrationField = useSelector(
    showVehicleRegistrationLogic
  );

  const vehicleChasisNo = useSelector(vehicleChassisNoSelector);
  const showVehicleChassisNoField = useSelector(showVehicleChassisNoLogic);

  const vehicleEngineNo = useSelector(vehicleEngineNoSelector);
  const showVehicleEngineNoField = useSelector(showVehicleEngineNoLogic);

  const { vehicleBankName, firstTimeInsurance, vehicleInsurer, insurerArray } =
    useSelector((s) => s.vehicleDetails);

  const { ncd, noOfClaimsInPast3Years } = useSelector(
    (s) => s.getQuote.premiumData
  );

  const showFirstTimeCheckBox = ncd === "0" && noOfClaimsInPast3Years === "0";
  const alreadyClaimedInPast3Years = parseInt(noOfClaimsInPast3Years) > 0;

  const {
    vehicleRegistration,
    chassis,
    engine,
    previousInsurer,
    paymentSummary,
    buttonLabel
  } = VEHICLE_DETAILS;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        vehicleRegistrationNo,
        vehiclePreviousRegNo,
        vehicleChasisNo,
        vehicleEngineNo,
        vehicleBankName,
        firstTimeInsurance,
        vehicleInsurer: { text: vehicleInsurer }
      }}
      validate={(values) => {
        const errors = {};
        if (
          !values.vehicleRegistrationNo ||
          values.vehicleRegistrationNo === ""
        ) {
          errors.vehicleRegistrationNo = vehicleRegistration.requiredMessage;
        }

        if (!values.vehicleChasisNo || values.vehicleChasisNo === "") {
          errors.vehicleChasisNo = chassis.requiredMessage;
        }

        if (!values.vehicleEngineNo || values.vehicleEngineNo === "") {
          errors.vehicleEngineNo = engine.requiredMessage;
        }

        if (!values.firstTimeInsurance || alreadyClaimedInPast3Years) {
          if (
            !values.vehicleInsurer ||
            !values.vehicleInsurer.text ||
            values.vehicleInsurer.text === ""
          ) {
            errors.vehicleInsurer = previousInsurer.requiredMessage;
          }

          if (
            !values.vehiclePreviousRegNo ||
            values.vehiclePreviousRegNo === ""
          ) {
            errors.vehiclePreviousRegNo = vehicleRegistration.requiredMessage;
          }
        }

        return errors;
      }}
      onSubmit={(values) => {
        dispatch(
          setVehicleDetails({
            ...values,
            firstTimeInsurance:
              values.firstTimeInsurance && !alreadyClaimedInPast3Years,
            vehicleInsurer:
              values.firstTimeInsurance && !alreadyClaimedInPast3Years
                ? ""
                : values.vehicleInsurer.text
          })
        );
        if (isEdit) {
          window.history.back();
        } else {
          navigate("/driver-details");
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        touched
      }) => {
        return (
          <FormikOnError>
            <form onSubmit={handleSubmit}>
              <Row>
                <Column xs={12} sm={7}>
                  <Layout.Stack space={3}>
                    <Text type="header">{VEHICLE_DETAILS.vehicleDetails}</Text>
                    <>
                      {showVehicleRegistrationField && (
                        <>
                          <VehicleRegistration
                            name="vehicleRegistrationNo"
                            previousRegistrationName="vehiclePreviousRegNo"
                            value={values.vehicleRegistrationNo}
                            error={
                              touched.vehicleRegistrationNo
                                ? errors.vehicleRegistrationNo
                                : null
                            }
                            hintMessage={
                              touched.vehicleRegistrationNo &&
                              errors.vehicleRegistrationNo
                            }
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                          />
                          <Section margin={[4, 0, 0, 0]} />
                        </>
                      )}
                      {showVehicleChassisNoField && (
                        <>
                          <ChassisNumber
                            name="vehicleChasisNo"
                            value={values.vehicleChasisNo}
                            error={
                              touched.vehicleChasisNo
                                ? errors.vehicleChasisNo
                                : null
                            }
                            hintMessage={
                              touched.vehicleChasisNo && errors.vehicleChasisNo
                            }
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                          />
                          <Section margin={[4, 0, 0, 0]} />
                        </>
                      )}
                      {showVehicleEngineNoField && (
                        <>
                          <EngineNumber
                            name="vehicleEngineNo"
                            value={values.vehicleEngineNo}
                            error={
                              touched.vehicleEngineNo
                                ? errors.vehicleEngineNo
                                : null
                            }
                            hintMessage={
                              touched.vehicleEngineNo && errors.vehicleEngineNo
                            }
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                          />
                          <Section margin={[4, 0, 0, 0]} />
                        </>
                      )}
                      <BankName
                        name="vehicleBankName"
                        value={values.vehicleBankName}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                    </>
                    <Text type="header">{VEHICLE_DETAILS.policyDetail}</Text>
                    <>
                      {showFirstTimeCheckBox && (
                        <>
                          <FirstTime
                            name="firstTimeInsurance"
                            value={values.firstTimeInsurance}
                            setFieldValue={setFieldValue}
                          />
                          <Section margin={[10, 0, 0, 0]} />
                        </>
                      )}
                      {(!values.firstTimeInsurance ||
                        alreadyClaimedInPast3Years) && (
                        <Layout.Stack space={2}>
                          <PreviousInsurer
                            name="vehicleInsurer"
                            value={values.vehicleInsurer}
                            insurerArray={insurerArray}
                            error={
                              touched.vehicleInsurer
                                ? errors.vehicleInsurer
                                : null
                            }
                            hintMessage={
                              touched.vehicleInsurer && errors.vehicleInsurer
                            }
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                          />
                          <PreviousRegistration
                            name="vehiclePreviousRegNo"
                            value={values.vehiclePreviousRegNo}
                            error={
                              touched.vehiclePreviousRegNo
                                ? errors.vehiclePreviousRegNo
                                : null
                            }
                            hintMessage={
                              touched.vehiclePreviousRegNo &&
                              errors.vehiclePreviousRegNo
                            }
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                          />
                        </Layout.Stack>
                      )}
                    </>
                  </Layout.Stack>
                </Column>
              </Row>
              <Row>
                <Column noGutter xs={12} md={7}>
                  <Row>
                    <Column xs={12} sm={6} md={6}>
                      <Button
                        data-testid="enter-vehicle-details"
                        fullWidth
                        type="submit"
                      >
                        {isEdit ? paymentSummary : buttonLabel}
                      </Button>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </form>
          </FormikOnError>
        );
      }}
    </Formik>
  );
};

export default VehicleDetailsForm;
