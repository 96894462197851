import styled from "styled-components";
import { theme } from "@dls/web";

export const PlanCardContainer = styled.div`
  @media (min-width: ${theme.breakpoints.sm}) {
    position: sticky;
    top: 0;
    padding: ${props => props.padding}px 0;
  }
`;

export const RowContainer = styled.section`
  display: flex;
  justify-content: space-between;
`;
