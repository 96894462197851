import { SET_PDPA } from "../../actions/pdpaActions/actions.json";

import initialState from "./initialState";

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PDPA:
      return {
        ...state,
        pdpa: action.pdpa,
        loading: false
      };
    default:
      return state;
  }
}
