import {
  ADD_BENEFIT,
  REMOVE_BENEFIT,
  REMOVE_ALL_BENEFIT
} from "./actions.json";

export const addBenefit = benefit => ({
  type: ADD_BENEFIT,
  benefit
});
export const removeBenefit = benefit => ({
  type: REMOVE_BENEFIT,
  benefit
});
export const removeAllBenefit = () => ({
  type: REMOVE_ALL_BENEFIT
});
