import { useSelector } from "react-redux";
import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { paymentSummaryAnalytics } from "../../utils/analyticsUtils";
import {
  displayDate,
  displayContactNo,
  displayOccupation,
  displayGender
} from "../../utils/utilsMethods";

const useMyInforiverDetails = () => {
  const {
    driverName,
    driverNationality,
    driverNric,
    driverEmail
  } = useSelector(s => s.driverDetails);
  const {
    dateOfBirth,
    occupationType,
    gender,
    contactNumberMobile
  } = useSelector(s => s.getQuote.premiumData);

  const { selectedDriver } = useSelector(s => s.myInfo);

  const myInfoDetails = {
    shouldHide: !selectedDriver,
    title: PAYMENT_SUMMARY.myInfoDetails,
    items: [
      driverName,
      driverNationality,
      driverNric,
      `${PAYMENT_SUMMARY.labelBorn} ${displayDate(dateOfBirth)}`,
      `${displayGender(gender)}`
    ].filter(items => items !== null),
    link: "/myinfo/preview",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titleDriverDetails,
      "Change"
    )
  };

  const otherDetails = {
    shouldHide: !selectedDriver,
    title: PAYMENT_SUMMARY.otherDetails,
    items: [
      `${displayOccupation(occupationType)}`,
      `${displayContactNo(contactNumberMobile)}`
    ].filter(items => items !== null),
    link: "/get-quote/driver-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titleDriverToBeInsured,
      "Change"
    )
  };

  const emailAddress = {
    shouldHide: !selectedDriver,
    title: PAYMENT_SUMMARY.emailAddress,
    items: [driverEmail].filter(items => items !== null),
    link: "/driver-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titleDriverToBeInsured,
      "Change"
    )
  };

  return [myInfoDetails, otherDetails, emailAddress];
};

export default useMyInforiverDetails;
