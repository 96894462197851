import { takeLatest, call, put, select, fork } from "redux-saga/effects";

import { api } from "../apiSaga";
import {
  GET_MY_INFO_DETAILS,
  LOGIN_MY_INFO,
  CONFIRM_MY_INFO_DETAILS
} from "../../actions/myInfoActions/actions.json";
import {
  myInfoLogin,
  myInfoDetails,
  country,
  insuranceData
} from "../../constants/api.json";
import {
  setMyInfoDetails,
  setMyInfoDriverDetails,
  setMyInfoVehicleDetails
} from "../../actions/myInfoActions";
import { openExternalURL } from "../../utils/navigationUtils";
import {
  processMyInfoDOB,
  mapMyInfoName,
  mapMyInfoNationality,
  mapMyInfoStreet,
  mapMyInfoUnit,
  mapMyInfoMakeAndModel,
  mapMyInfoBodyType,
  mapMyInfoVehicleType
} from "../../utils/utilsMethods";
import { navigate } from "gatsby";
import { setDriverDetails } from "../../actions/driverDetailsActions";
import {
  saveDriverDetails,
  saveVehicleDetails
} from "../../actions/getQuoteActions";
import { setVehicleDetails } from "../../actions/vehicleDetailsActions";
import { myInfoSelector, isMstaSelector } from "../../selectors";

export function* myInfoWatcher() {
  yield takeLatest(LOGIN_MY_INFO, fetchMyInfoLogin);
  yield takeLatest(GET_MY_INFO_DETAILS, fetchMyInfoDetails);
  yield takeLatest(CONFIRM_MY_INFO_DETAILS, mapMyInfoDetails);
}

export function* fetchMyInfoLogin() {
  const apiDetails = yield call(setOpenedIn, myInfoLogin);
  const response = yield call(api, apiDetails);
  if (response.success) {
    yield call(openExternalURL, response.success.url);
  }
}

export function* setOpenedIn(apiDetails) {
  // pass in wheather we are in msta or browser
  // this will help later to open payment page in external browser if inside msta
  const isMsta = yield select(isMstaSelector);
  apiDetails.url += isMsta ? "webview" : "browser";
  return apiDetails;
}

export function* fetchMyInfoDetails(action) {
  const { code, isMsta } = action;
  myInfoDetails.url += isMsta ? "webview" : "browser";
  const response = yield call(api, myInfoDetails, {}, { "myInfo-jwt": code });
  if (response.success) {
    const {
      fullName,
      nric,
      nationality,
      gender,
      dateOfBirth,
      contactNumberMobile,
      email,
      postalCode,
      mailingAddrBlock,
      mailingAddrBuilding,
      mailingAddrStreet,
      mailingAddrFloor,
      mailingAddrUnit,
      drivingComstatus,
      vehicleInfos
    } = response.success;
    yield put(
      setMyInfoDetails({
        ...response.success,
        dateOfBirth: processMyInfoDOB(dateOfBirth),
        vehicleInfos: vehicleInfos || []
      })
    );
    yield put(
      setMyInfoDriverDetails({
        fullName,
        nric,
        nationality,
        gender,
        dateOfBirth: processMyInfoDOB(dateOfBirth),
        contactNumberMobile,
        email,
        postalCode,
        mailingAddrBlock,
        mailingAddrBuilding,
        mailingAddrStreet,
        mailingAddrFloor,
        mailingAddrUnit,
        drivingComstatus
      })
    );
    if (!vehicleInfos) {
      yield put(setMyInfoVehicleDetails(null));
    } else if (vehicleInfos.length > 0) {
      yield put(setMyInfoVehicleDetails(0));
    }
    yield call(navigate, "/myinfo/preview");
  }
}

export function* mapMyInfoDetails(action) {
  const {
    selectedDriver,
    selectedVehicleIndex,
    details: { vehicleInfos }
  } = yield select(myInfoSelector);

  if (selectedDriver) {
    yield fork(mapMyInfoDriver, selectedDriver);
  }

  if (selectedVehicleIndex !== null) {
    yield fork(mapMyInfoVehicle, vehicleInfos, selectedVehicleIndex);
  }
  yield call(navigate, action.callBackUrl);
}

export function* mapMyInfoDriver(selectedDriver) {
  const countryResponse = yield call(api, country);
  if (countryResponse.success) {
    let {
      fullName: name,
      nationality,
      nric,
      gender,
      dateOfBirth,
      contactNumberMobile,
      email,
      postalCode: postal,
      mailingAddrBlock,
      mailingAddrStreet,
      mailingAddrFloor,
      mailingAddrUnit,
      drivingComstatus
    } = selectedDriver;

    const { country: countryReceived } = countryResponse.success;
    nationality = { ...mapMyInfoNationality(nationality, countryReceived) };
    const sdd =
      drivingComstatus === "Y" || drivingComstatus === "YES" ? "YES" : "NO";

    yield put(
      setDriverDetails({
        driverName: mapMyInfoName(name),
        driverNationality: nationality.text,
        driverNationalityCode: nationality.value,
        driverNric: nric,
        driverEmail: email,
        driverPostal: postal,
        driverStreet: mapMyInfoStreet(mailingAddrBlock, mailingAddrStreet),
        driverFloorAndUnit: mapMyInfoUnit(mailingAddrFloor, mailingAddrUnit)
      })
    );

    yield put(
      saveDriverDetails({ gender, dateOfBirth, contactNumberMobile, sdd })
    );
  }
}

export function* mapMyInfoVehicle(vehicleInfos, selectedVehicleIndex) {
  const insuranceDataReponse = yield call(api, insuranceData);
  let {
    make,
    model,
    bodyType,
    vehicleNo,
    engineCapacity,
    engineNumber,
    chasisNumber,
    yearOfManufacture,
    propellant,
    scheme
  } = vehicleInfos[selectedVehicleIndex];

  if (insuranceDataReponse.success) {
    const { makeModel: makeModelItems, bodyType: bodyTypeItems } =
      insuranceDataReponse.success;

    yield put(
      setVehicleDetails({
        firstTimeInsurance: false,
        vehicleRegistrationNo: vehicleNo,
        vehicleEngineNo: engineNumber,
        vehicleChasisNo: chasisNumber,
        vehicleBankName: "",
        vehicleInsurer: "",
        vehiclePreviousRegNo: ""
      })
    );
    const makeAndModel = mapMyInfoMakeAndModel(make, model, makeModelItems);
    const vehicleType = mapMyInfoVehicleType(propellant, scheme);
    bodyType = mapMyInfoBodyType(bodyType, bodyTypeItems);
    engineCapacity = engineCapacity || null;
    yearOfManufacture = yearOfManufacture || null;

    yield put(
      saveVehicleDetails({
        makeAndModel,
        bodyType,
        engineCapacity,
        yearOfManufacture,
        vehicleInfos: vehicleType.length > 0 ? vehicleType : ["NA"]
      })
    );
  }
}
