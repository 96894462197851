import {
  ADD_BENEFIT,
  REMOVE_BENEFIT
} from "../../actions/extraCoverageActions/actions.json";

import { fetchPremium } from "../premiumSaga";
import { takeLatest } from "redux-saga/effects";

export function* getExtraCoverage() {
  yield takeLatest(ADD_BENEFIT, fetchPremium);
  yield takeLatest(REMOVE_BENEFIT, fetchPremium);
}
