import { theme } from "@dls/web";
import styled from "styled-components";
const Section = styled.div`
  margin: ${({ margin = [0, 0, 0, 0] }) =>
    `${theme.space[margin[0]]}px ${theme.space[margin[1]]}px ${
      theme.space[margin[2]]
    }px ${theme.space[margin[3]]}px`};
  padding: ${({ padding = [0, 0, 0, 0] }) =>
    `${theme.space[padding[0]]}px ${theme.space[padding[1]]}px ${
      theme.space[padding[2]]
    }px ${theme.space[padding[3]]}px`};
  background-color: ${({ bgColor }) => bgColor};
`;

export default Section;
