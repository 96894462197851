import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { navigate } from "gatsby";
import {
  Grid,
  Row,
  Column,
  Text,
  TextLink,
  Button,
  theme,
  Divider
} from "@dls/web";
import { Edit } from "@dls/assets/icons";
import { PERMISSION_REQUIRED } from "../../../constants/strings/staticString.json";
import { Section } from "../../../components";
import { loginMyInfo } from "../../../actions/myInfoActions";

const PageText = styled(Text)`
  @media (max-width: ${theme.breakpoints.xs}) {
    text-align: center;
  }
`;

const PageTitle = styled(PageText)`
  margin-top: 64px;
`;

const PermissionRequired = () => {
  const {
    title,
    description,
    buttonLabel,
    linkText,
    youCouldAlso
  } = PERMISSION_REQUIRED;

  const dispatch = useDispatch();

  const dispatchLoginMyInfo = React.useCallback(() => dispatch(loginMyInfo()), [
    dispatch
  ]);

  const goToGetQuoteDriverDetails = () => {
    navigate("/get-quote/driver-details");
  };

  const retryMyInfo = () => {
    dispatchLoginMyInfo();
  };

  return (
    <Grid>
      <Row>
        <Column md={5} sm={5} smOffset={1}>
          <PageTitle type="pageTitle">{title}</PageTitle>
        </Column>
        <Column xs={false} sm={5} />
      </Row>
      <Row>
        <Column md={5} sm={5} smOffset={1}>
          <Section margin={[2, 0, 4, 0]}>
            <PageText type="body">{description}</PageText>
          </Section>
        </Column>
        <Column xs={false} md={4} sm={4} smOffset={1}>
          <Section margin={[2, 0, 6, 0]}>
            <Text type="body">{youCouldAlso}</Text>
          </Section>
          <TextLink onClick={goToGetQuoteDriverDetails}>
            <TextLink.Icon icon={Edit} />
            <TextLink.Text>{linkText}</TextLink.Text>
          </TextLink>
        </Column>
      </Row>
      <Row>
        <Column xs={12} sm={3} md={3} smOffset={1}>
          <Button fullWidth secondary onClick={retryMyInfo}>
            {buttonLabel}
          </Button>
        </Column>
        <Column xs={false} sm={8} />
        <Column xs={12} md={false} sm={false}>
          <Divider />
          <Section margin={[6, 0, 6, 0]}>
            <Text type="body">{youCouldAlso}</Text>
          </Section>
          <TextLink onClick={goToGetQuoteDriverDetails}>
            <TextLink.Icon icon={Edit} />
            <TextLink.Text>{linkText}</TextLink.Text>
          </TextLink>
        </Column>
      </Row>
    </Grid>
  );
};

export default PermissionRequired;
