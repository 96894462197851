import {
  GET_COUNTRIES,
  SET_COUNTRIES,
  SET_DRIVER_DETAILS
} from "./actions.json";

export const setDriverDetails = ({
  driverName,
  driverNationality,
  driverNationalityCode,
  driverNric,
  driverEmail,
  driverPostal,
  driverStreet,
  driverFloorAndUnit
}) => {
  return {
    type: SET_DRIVER_DETAILS,
    payload: {
      driverName,
      driverNationality,
      driverNationalityCode,
      driverNric,
      driverEmail,
      driverPostal,
      driverStreet,
      driverFloorAndUnit
    }
  };
};
export const getCountries = () => ({ type: GET_COUNTRIES });

export const setCountries = ({ country }) => {
  const sortedCountries = country.sort((country1, country2) =>
    country1.countryValue.localeCompare(country2.countryValue)
  );
  const singapore = country.find(c => c.countryCode === "SGP");
  if (singapore) {
    sortedCountries.unshift(singapore);
  }

  return {
    type: SET_COUNTRIES,
    countries: sortedCountries.map(item => ({
      text: item.countryValue,
      value: item.countryCode
    }))
  };
};
