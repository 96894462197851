export default {
  claimsItems: [],
  ncdItems: [],
  makeModelItems: [],
  bodyTypeItems: [],
  makeAndModel: "",
  isEdit: false,
  verified: false,
  premiumData: {
    noOfClaimsInPast3Years: "0",
    claimAmountInPast3Years: "0",
    ncd: null,
    ncdp: "NO",
    sdd: "NO",
    dateOfBirth: "",
    contactNumberMobile: "",
    licenseRevoked: null,
    occupationType: null,
    gender: null,
    effectiveDate: null,
    expiredDate: null,
    make: null,
    model: null,
    bodyType: null,
    engineCapacity: "",
    yearOfManufacture: "",
    vehicleInfos: ["NA"],
    campaignCode:"",
  }
};
