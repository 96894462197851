import { useSelector } from "react-redux";

import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { paymentSummaryAnalytics } from "../../utils/analyticsUtils";

const useClaimsInPast3Years = () => {
  const {
    titleClaimsinPast3Years,
    labelNcdPercentage,
    labelNCDProtector,
    labelNCDProtectorForValuePlan,
    labelSafeDriverDiscount
  } = PAYMENT_SUMMARY;

  const {
    noOfClaimsInPast3Years,
    claimAmountInPast3Years,
    ncd,
    ncdp,
    sdd
  } = useSelector(s => s.getQuote.premiumData);

  const claimsItems = useSelector(s => s.getQuote.claimsItems);

  const claimAmountItem = claimsItems.find(
    item => item.value === claimAmountInPast3Years
  );
  const { plans, selectedPlan } = useSelector(s => s.premium);
  const plan = plans[selectedPlan];

  return {
    title: titleClaimsinPast3Years,
    items: [
      `${parseInt(noOfClaimsInPast3Years)} claim`,
      `${claimAmountItem ? claimAmountItem.text : ""}`,
      `${ncd !== null ? `${ncd}${labelNcdPercentage}` : ""}`,
      `${
        ncdp === "YES"
          ? `${
              plan.planCode !== "SV"
                ? labelNCDProtector
                : labelNCDProtectorForValuePlan
            }`
          : ""
      }`,
      `${sdd === "YES" ? `${labelSafeDriverDiscount}` : ""}`
    ],
    shouldShowDivider: true,
    link: "/get-quote/driver-details",
    analytics: paymentSummaryAnalytics(titleClaimsinPast3Years, "Change")
  };
};

export default useClaimsInPast3Years;
