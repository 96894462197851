import { SET_PAYMENT_DETAILS, FETCH_PAYMENT } from "./action.json";

export const setPaymentDetails = payment => ({
  type: SET_PAYMENT_DETAILS,
  payment
});

export const fetchPayment = (email, amount, transactionId, proposalNumber) => ({
  type: FETCH_PAYMENT,
  email, 
  amount, 
  transactionId, 
  proposalNumber
});
