import React from "react";
import { Button } from "@dls/web";
import { priceFormat } from "../../utils/ui-logic";

import {
  Container,
  ColumnContainer,
  ButtonWrapper,
  PriceContainer,
  TotalText,
  PriceText
} from "./styles";

const StickyPlanCard = ({
  plan = {},
  totalLabel,
  buttonLabel,
  onClick,
  loading = false,
  isHidden
}) => {
  const { totalPremiumAfterGST } = plan;
  return (
    <Container isHidden={isHidden}>
      <PriceContainer>
        <ColumnContainer>
          <TotalText>{totalLabel}</TotalText>
          <PriceText>{priceFormat(totalPremiumAfterGST)}</PriceText>
        </ColumnContainer>
        <ButtonWrapper>
          <Button
            text={buttonLabel}
            onClick={onClick}
            loading={loading}
            data-testid="sticky-header-button"
          />
        </ButtonWrapper>
      </PriceContainer>
    </Container>
  );
};

export default StickyPlanCard;
