import React from "react";
import { useDispatch } from "react-redux";
import { Column, Text, Row, Grid, Card, Button, theme } from "@dls/web";
import styled from "styled-components";
import Logo from "../../assets/images/singpass-logo.png";
import { Image } from "../Images";
import { Section } from "../";
import { trackEvent } from "../../utils/analyticsUtils";
import { loginMyInfo } from "../../actions/myInfoActions";
import { SINGPASS } from "../../constants/strings/staticString.json";

export const TitleText = styled(Text)`
  margin-bottom: 16px;
`;

export const DescriptionText = styled(Text)`
  margin-bottom: 24px;
`;

export const ButtonContainer = styled.div`
  flex: 1;
  margin: 8px auto 0px auto;
  display: flex;
  justify-content: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    flex: 0.5;
  }
`;

export const SingPassLogo = styled(Image)`
  background-size: contain;
  background-image: url(${Logo});
  height: 20px;
  width: 120px;
  margin-bottom: 16px;
`;

const SingpassInfo = () => {
  const { title, description, button, useMyInfoAnalytics } = SINGPASS;
  const dispatch = useDispatch();
  const dispatchLoginMyInfo = React.useCallback(
    () => dispatch(loginMyInfo()),
    [dispatch]
  );

  const goToMyInfoPreview = () => {
    trackEvent(useMyInfoAnalytics);
    //this is to prevent the warning prompt when leaving the site
    window.onbeforeunload = null;
    dispatchLoginMyInfo();
  };

  return (
    <Grid>
      <Row>
        <Column xs={12} sm={7}>
          <Section margin={[0, 0, 3, 0]}>
            <Card shadow="sm" radius="md" padding="lg">
              <Card.Content>
                <SingPassLogo />
                <TitleText type="header">{title}</TitleText>
                <DescriptionText type="body">{description}</DescriptionText>
                <Row>
                  <ButtonContainer>
                    <Button
                      fullWidth
                      data-testid="use-my-info"
                      onClick={goToMyInfoPreview}
                    >
                      {button}
                    </Button>
                  </ButtonContainer>
                </Row>
              </Card.Content>
            </Card>
          </Section>
        </Column>
      </Row>
    </Grid>
  );
};

export default SingpassInfo;
