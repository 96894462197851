import React, { useCallback, useRef, useState, useEffect } from "react";

const useRefCallback = () => {
  const [elementOffsetTop, setElementOffsetTop] = useState(0);

  const ref = useRef({ current: null });

  useEffect(
    () => {
      setElementOffsetTop((ref.current && ref.current.offsetTop) || 0);
    },
    [ref.current.offsetTop]
  );

  const setRef = useCallback(node => {
    ref.current = node;
  }, []);

  return { setRef, elementOffsetTop };
};

export default useRefCallback;
