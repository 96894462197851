import { CONFIG } from "../constants/strings/staticString.json";
import {
  isEligibleAge,
  partialStringMatch,
  mapMyInfoBodyType,
  mapMyInfoVehicleType
} from "./utilsMethods";
import {
  REQUIREMENTS,
  PAYMENT_SUMMARY
} from "../constants/strings/staticString.json";
import { isWithinInterval, parse } from "date-fns";

const {
  showTotalClaim,
  ncd: { sddDiscount, ncdpDiscount },
  driver: { lowerAgeLimit, upperAgeLimit },
  vehicle: {
    lowerEngineCapacityLimit,
    upperEngineCapacityLimit,
    yearOfManufactureLimit
  }
} = REQUIREMENTS;

export const showTotalClaimAmountLogic = noOfClaimsInPast3Years => {
  return noOfClaimsInPast3Years === showTotalClaim;
};
export const showFieldsBelowPastClaims = (
  noOfClaimsInPast3Years,
  claimAmountInPast3Years
) => noOfClaimsInPast3Years < "2" && claimAmountInPast3Years < 10001;

export const showNcdProtectorLogic = ncd => {
  return ncd >= ncdpDiscount;
};

export const showTPCEligible = state => {
  const {
    selectedDriver,
    details: { drivingComstatus = null }
  } = state.myInfo;

  if (!selectedDriver) {
    return false;
  }

  return drivingComstatus === "Y" || drivingComstatus === "YES";
};

export const showTPCertLogic = (ncd, myInfo) => {
  const { details, selectedDriver } = myInfo;

  if (ncd < sddDiscount) {
    //hide the field
    return false;
  } else if (
    ncd >= sddDiscount &&
    (!details || !details.drivingComstatus || !selectedDriver)
  ) {
    //show the field nothing to prefill
    return true;
  } else if (
    ncd >= sddDiscount &&
    (details.drivingComstatus === "Y" || details.drivingComstatus === "YES")
  ) {
    //prefill and show the field
    return true;
  } else if (
    ncd >= sddDiscount &&
    (details.drivingComstatus === "N" || details.drivingComstatus === "NO")
  ) {
    //prefill and hide the field
    return false;
  }
};

export const premiumTitles = (ncd, sdd) => {
  let titles = [];
  const hasNCD = ncd && parseInt(ncd) > 0;
  if (hasNCD) {
    titles.push(`${ncd}% NCD`);
  }
  if (sdd && sdd === "YES") {
    titles.push("Safe Driver Discount");
  }
  return titles;
};

export const driverIsEligible = age =>
  age >= lowerAgeLimit && age <= upperAgeLimit;

export const vehicleIsEligible = (engineCapacity, yearOfManufacture) => {
  //if enginecapacity / yom is not returned by service still shoud be considered eligible without running validations
  const today = new Date();
  const thisYear = today.getFullYear();
  return (
    (!engineCapacity ||
      (engineCapacity >= lowerEngineCapacityLimit &&
        engineCapacity <= upperEngineCapacityLimit)) &&
    (!yearOfManufacture ||
      (yearOfManufacture <= thisYear &&
        yearOfManufacture >= thisYear - yearOfManufactureLimit))
  );
};

export const myInfoDriverIsEligible = dateOfBirth => {
  if (
    !dateOfBirth ||
    !(typeof dateOfBirth === "string" || dateOfBirth instanceof String)
  ) {
    //when dob is not returned by myInfo but still should considered eligible
    return true;
  }
  if (dateOfBirth) {
    const [day, month, year] = dateOfBirth.split("/").map(d => parseInt(d));
    return isEligibleAge(new Date(year, month - 1, day), new Date());
  }
};

export const myInfoVehiclesAreEligible = state => {
  const {
    details: { vehicleInfos = [] }
  } = state.myInfo;
  if (vehicleInfos) {
    return vehicleInfos.map(v => {
      return vehicleIsEligible(v.engineCapacity, v.yearOfManufacture);
    });
  }
};

export const myInfoVehicleAvailabilityCheck = state => {
  const { details } = state.myInfo;
  if (!details || !details.vehicleInfos) {
    return false;
  }

  return details.vehicleInfos.length > 0;
};

export const disableSubmitMyInfoPreview = state => {
  const { selectedDriver, selectedVehicleIndex } = state.myInfo;
  const driverIsEligibleMyInfo = false;
  const vehiclesAreEligible = myInfoVehiclesAreEligible(state);
  return (
    (!driverIsEligibleMyInfo && selectedDriver !== null) ||
    (!vehiclesAreEligible[selectedVehicleIndex] &&
      selectedVehicleIndex !== null)
  );
};

export const hideBackButtonInMyInfo = state => {
  const driverIsEligibleMyInfo = false;
  const vehiclesAreEligible = myInfoVehiclesAreEligible(state);
  return driverIsEligibleMyInfo && vehiclesAreEligible.some(v => v === true);
};

export const showPreviousInsurerLogic = state => {
  const {
    getQuote: {
      premiumData: { noOfClaimsInPast3Years }
    },
    vehicleDetails: { firstTimeInsurance, vehicleInsurer }
  } = state;

  return (
    firstTimeInsurance &&
    !(parseInt(noOfClaimsInPast3Years) > 0 && vehicleInsurer !== "")
  );
};

export const showYearOfManufactureLogic = state => {
  const { selectedVehicleIndex, details } = state.myInfo;
  if (
    selectedVehicleIndex !== null &&
    !!details &&
    !!details.vehicleInfos &&
    details.vehicleInfos.length > selectedVehicleIndex
  ) {
    return !details.vehicleInfos[selectedVehicleIndex].yearOfManufacture;
  } else {
    return true;
  }
};

export const showEngineCapacityLogic = state => {
  const { selectedVehicleIndex, details } = state.myInfo;
  if (
    selectedVehicleIndex !== null &&
    !!details &&
    !!details.vehicleInfos &&
    details.vehicleInfos.length > selectedVehicleIndex
  ) {
    return !details.vehicleInfos[selectedVehicleIndex].engineCapacity;
  } else {
    return true;
  }
};

export const showBodyTypeLogic = state => {
  const { selectedVehicleIndex, details } = state.myInfo;

  if (selectedVehicleIndex !== null) {
    const bodyType = details.vehicleInfos[selectedVehicleIndex].bodyType;
    return !mapMyInfoBodyType(bodyType);
  }

  return true;
};

export const showVehicleTypesInput = state => {
  const { selectedVehicleIndex, details } = state.myInfo;

  if (selectedVehicleIndex !== null) {
    const selectedVehicle = details.vehicleInfos[selectedVehicleIndex];
    return (
      JSON.stringify(
        mapMyInfoVehicleType(selectedVehicle.propellant, selectedVehicle.scheme)
      ) !== JSON.stringify(["E", "WEK"])
    );
  }

  return true;
};

export const showVehicleTobeInsuredTitleLogic = state =>
  showMakeAndModelLogic(state) ||
  showBodyTypeLogic(state) ||
  showEngineCapacityLogic(state) ||
  showYearOfManufactureLogic(state);

const findMakeAndModel = (make, model, makeModelItems) => {
  if (!make || !model) {
    return true;
  }
  let makeUpper = make.toUpperCase();
  let makeModelCombined = `${make.toUpperCase()} - ${model.toUpperCase()}`;

  const foundMakeModel = makeModelItems.find(
    value =>
      value.makeCode.toUpperCase() === makeUpper &&
      value.text.toUpperCase() === makeModelCombined
  );
  return !foundMakeModel;
};

export const showMakeAndModelLogic = state => {
  const { selectedVehicleIndex, details } = state.myInfo;
  const { premiumData, makeModelItems } = state.getQuote;

  if (!premiumData || !premiumData.make || !premiumData.model) {
    return true;
  }

  if (
    selectedVehicleIndex !== null &&
    !!details &&
    !!details.vehicleInfos &&
    details.vehicleInfos.length > selectedVehicleIndex
  ) {
    return findMakeAndModel(
      details.vehicleInfos[selectedVehicleIndex].make,
      details.vehicleInfos[selectedVehicleIndex].model,
      makeModelItems
    );
  } else {
    return true;
  }
};

export const showVehicleRegistrationLogic = state => {
  const {
    selectedVehicleIndex,
    details: { vehicleInfos = [] }
  } = state.myInfo;
  const vehicle =
    selectedVehicleIndex < vehicleInfos.length &&
    vehicleInfos[selectedVehicleIndex];
  return !(vehicle && vehicle.vehicleNo);
};

export const vehiclePreviousRegistrationSelector = state => {
  const { vehiclePreviousRegNo } = state.vehicleDetails;
  const {
    selectedVehicleIndex,
    details: { vehicleInfos = [] }
  } = state.myInfo;
  const vehicle =
    selectedVehicleIndex < vehicleInfos.length &&
    vehicleInfos[selectedVehicleIndex];
  const regNo = (vehicle && vehicle.vehicleNo) || vehiclePreviousRegNo;
  return regNo && regNo.toUpperCase();
};

export const vehicleRegistrationSelector = state => {
  const { vehicleRegistrationNo } = state.vehicleDetails;
  const {
    selectedVehicleIndex,
    details: { vehicleInfos = [] }
  } = state.myInfo;
  const vehicle =
    selectedVehicleIndex < vehicleInfos.length &&
    vehicleInfos[selectedVehicleIndex];
  return (vehicle && vehicle.vehicleNo) || vehicleRegistrationNo;
};

export const showVehicleChassisNoLogic = state => {
  const {
    selectedVehicleIndex,
    details: { vehicleInfos = [] }
  } = state.myInfo;
  const vehicle =
    selectedVehicleIndex < vehicleInfos.length &&
    vehicleInfos[selectedVehicleIndex];
  return !(vehicle && vehicle.chasisNumber);
};

export const vehicleChassisNoSelector = state => {
  const { vehicleChasisNo } = state.vehicleDetails;
  const {
    selectedVehicleIndex,
    details: { vehicleInfos = [] }
  } = state.myInfo;
  const vehicle =
    selectedVehicleIndex < vehicleInfos.length &&
    vehicleInfos[selectedVehicleIndex];
  return (vehicle && vehicle.chasisNumber) || vehicleChasisNo;
};

export const showVehicleEngineNoLogic = state => {
  const {
    selectedVehicleIndex,
    details: { vehicleInfos = [] }
  } = state.myInfo;
  const vehicle =
    selectedVehicleIndex < vehicleInfos.length &&
    vehicleInfos[selectedVehicleIndex];
  return !(vehicle && vehicle.engineNumber);
};

export const vehicleEngineNoSelector = state => {
  const { vehicleEngineNo } = state.vehicleDetails;
  const {
    selectedVehicleIndex,
    details: { vehicleInfos = [] }
  } = state.myInfo;
  const vehicle =
    selectedVehicleIndex < vehicleInfos.length &&
    vehicleInfos[selectedVehicleIndex];
  return (vehicle && vehicle.engineNumber) || vehicleEngineNo;
};

export const showDateOfBirthLogic = myInfo => {
  const { selectedDriver } = myInfo;

  return !selectedDriver || !selectedDriver.dateOfBirth;
};

export const showGenderLogic = state => {
  const { selectedDriver } = state.myInfo;
  if (!selectedDriver || !selectedDriver.gender) {
    //check for null
    return true;
  }

  return !(
    selectedDriver.gender.toUpperCase() === "M" ||
    selectedDriver.gender.toUpperCase() === "F"
  );
};

export const driverNameSelector = state => {
  const { driverName } = state.driverDetails;
  const { selectedDriver } = state.myInfo;

  return (selectedDriver && selectedDriver.fullName) || driverName;
};

export const showDriverNameLogic = state => {
  const { selectedDriver } = state.myInfo;

  return !(selectedDriver && selectedDriver.fullName);
};

export const driverNationalitySelector = state => {
  const { driverNationality } = state.driverDetails;
  const { selectedDriver } = state.myInfo;

  return (selectedDriver && selectedDriver.nationality) || driverNationality;
};

export const showDriverNationalityLogic = state => {
  const { selectedDriver } = state.myInfo;

  return !(selectedDriver && selectedDriver.nationality);
};

export const driverNricSelector = state => {
  const { driverNric } = state.driverDetails;
  const { selectedDriver } = state.myInfo;

  return (selectedDriver && selectedDriver.nric) || driverNric;
};

export const showDriverNricLogic = state => {
  const { selectedDriver } = state.myInfo;

  return !(selectedDriver && selectedDriver.nric);
};

export const driverEmailSelector = state => {
  const { driverEmail } = state.driverDetails;
  const { selectedDriver } = state.myInfo;

  return (selectedDriver && selectedDriver.email) || driverEmail;
};

export const driverPostalCodeSelector = state => {
  const { driverPostal } = state.driverDetails;
  const { selectedDriver } = state.myInfo;

  return (selectedDriver && selectedDriver.postalCode) || driverPostal;
};

export const driverStreetSelector = state => {
  const { driverStreet } = state.driverDetails;
  const { selectedDriver } = state.myInfo;

  let street = driverStreet;

  if (selectedDriver) {
    if (selectedDriver.mailingAddrBlock) {
      street = `Blk ${
        selectedDriver.mailingAddrBlock
      } ${selectedDriver.mailingAddrStreet || ""}`;
    } else if (selectedDriver.mailingAddrStreet) {
      street = selectedDriver.mailingAddrStreet;
    }
  }

  return (street || "").substring(0, Math.min(30, street.length));
};

export const driverFloorAndUnitSelector = state => {
  const { driverFloorAndUnit } = state.driverDetails;
  const { selectedDriver } = state.myInfo;

  return (
    (selectedDriver &&
      `${selectedDriver.mailingAddrFloor}-${selectedDriver.mailingAddrUnit}`) ||
    driverFloorAndUnit
  );
};

export const showEVehicleType = state => {
  const { selectedVehicleIndex, details } = state.myInfo;
  const { validETypes } = CONFIG;
  if (
    selectedVehicleIndex !== null &&
    !!details &&
    !!details.vehicleInfos &&
    details.vehicleInfos.length > selectedVehicleIndex &&
    !!details.vehicleInfos[selectedVehicleIndex].propellant
  ) {
    return validETypes.includes(
      details.vehicleInfos[selectedVehicleIndex].propellant.toUpperCase()
    );
  } else {
    return false;
  }
};

export const showWEKVehicleType = state => {
  const { selectedVehicleIndex, details } = state.myInfo;
  const { validWeekEndCarTypes } = CONFIG;
  if (
    selectedVehicleIndex !== null &&
    !!details &&
    !!details.vehicleInfos &&
    details.vehicleInfos.length > selectedVehicleIndex &&
    !!details.vehicleInfos[selectedVehicleIndex].scheme
  ) {
    return partialStringMatch(
      validWeekEndCarTypes,
      details.vehicleInfos[selectedVehicleIndex].scheme.toUpperCase()
    );
  } else {
    return false;
  }
};

export const filterMakeAndModelItems = (makeModelItems, searchText) => {
  return makeModelItems.filter(item => {
    searchText = searchText.toLowerCase().replace(/\s|-/gi, "");
    return item.text
      .toLowerCase()
      .replace(/\s|-/gi, "")
      .includes(searchText);
  });
};

export const shortPlanName = planName => {
  if (!planName) return null;
  switch (planName.toLowerCase()) {
    case "authorised workshop":
      return "Value";
    case "comprehensive authorised workshop":
      return "Essential";
    case "comprehensive any workshop":
      return "Premium";
    default:
      return null;
  }
};

export const fullPlanName = planName => {
  if (!planName) return null;
  const shortName = shortPlanName(planName);
  return (shortName ? `${shortName} - ` : "") + planName;
};

export const priceFormat = price => {
  if (!price || isNaN(price)) return null;
  var formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2
  });

  return `$${formatter.format(price)}`;
};

export const getVehicleBodyTypeSummary = state => {
  const showMakeModel = showMakeAndModelLogic(state);
  const showBodyType = showBodyTypeLogic(state);
  const { selectedVehicleIndex } = state.myInfo;
  const { text = "" } = state.getQuote.makeAndModel;
  if ((selectedVehicleIndex === null || showMakeModel) && !showBodyType) {
    return `${text.replace("-", "")}`;
  } else if ((selectedVehicleIndex === null || showMakeModel) && showBodyType) {
    return `${text.replace("-", "")} (${state.getQuote.premiumData.bodyType})`;
  }
};

export const getVehicleEngineCapacitySummary = state => {
  const showEngineCapacity = showEngineCapacityLogic(state);
  const { engineCapacity } = state.getQuote.premiumData;
  if (state.myInfo.selectedVehicleIndex === null || showEngineCapacity) {
    return !!engineCapacity && `${engineCapacity} ${PAYMENT_SUMMARY.labelCC}`;
  }
};

export const getYomSummary = state => {
  const showYOM = showYearOfManufactureLogic(state);
  if (state.myInfo.selectedVehicleIndex === null || showYOM) {
    return `${PAYMENT_SUMMARY.labelManufactured} ${state.getQuote.premiumData.yearOfManufacture}`;
  }
};

export const getVehicleTypesSummary = state => {
  const showWek = !showWEKVehicleType(state);
  let vehicleTypesLabels = [];
  state.getQuote.premiumData.vehicleInfos.forEach(v => {
    switch (v) {
      case "E": {
        vehicleTypesLabels.push(PAYMENT_SUMMARY.labelElectricCar);
        break;
      }
      case "WEK": {
        if (showWek) {
          vehicleTypesLabels.push(PAYMENT_SUMMARY.labelWeekendCar);
        }
        break;
      }
    }
  });

  return [...new Set(vehicleTypesLabels)];
};

export const showSingpassRetrieveInfo = ({ selectedDriver }) => {
  return !selectedDriver;
};

export const getGSTInclusiveAmount = (amount, percentage) => {
  const _amount = Number(amount);
  const _percentage = Number(percentage);
  return _amount + (_amount / 100) * _percentage;
};

export const getBenefitDescription = (
  benefitKey,
  benefitsElaborationsArray
) => {
  if (!benefitKey || !benefitsElaborationsArray) {
    return "";
  }
  const BenefitsArrayFromAEM = JSON.parse(benefitsElaborationsArray)
    .benefitsElaborations;
  const trimmedBenefitKey = benefitKey
    .replace(/\s/g, "")
    .trim()
    .toLowerCase();

  return !!benefitKey &&
    Object.keys(BenefitsArrayFromAEM).indexOf(trimmedBenefitKey) !== -1
    ? BenefitsArrayFromAEM[trimmedBenefitKey]
    : "";
};

export const getCampaignCodeForSource = (source="", promotions=[]) => {
  for (let index = 0; index < promotions.length; index++) {
    if (promotions[index].source.toUpperCase() === source.toUpperCase()) {
      return promotions[index]
    }
  }
  return null
}

export const getCampaignCode = (source, promotions) => {
  const campaign = getCampaignCodeForSource(source, promotions)
  const today = new Date()
  //check within same date
  return !!campaign && !!campaign.campaignCode && isWithinInterval(today, {
    start: parse(campaign.startDate, "dd-MM-yyyy", new Date()),
    end: parse(campaign.endDate, "dd-MM-yyyy", new Date())
  }) ? campaign.campaignCode : ""
}
