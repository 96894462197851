import { isWithinInterval } from "date-fns";

export const filterPromotionsByClass = (promotions, className) => {
  if (!promotions || !className) return null;
  return promotions.filter(
    (promotion) =>
      promotion.promotionText && promotion.promotionText.includes(className)
  );
};

export const parsedFreebie = (promotion) => {
  if (!promotion) return null;
  if (!promotion.promotionText) return promotion;
  const xmlDoc = new DOMParser().parseFromString(
    promotion.promotionText,
    "text/html"
  );
  const freebiePromoTextEl =
    xmlDoc.getElementsByClassName("freebie-promo-text")[0];
  return {
    promoText: freebiePromoTextEl && freebiePromoTextEl.innerHTML,
    ...promotion
  };
};

export const parsedRibbon = (promotion) => {
  if (!promotion.promotionText) return promotion;
  const xmlDoc = new DOMParser().parseFromString(
    promotion.promotionText,
    "text/html"
  );
  const ribbonPromoTextEl = xmlDoc.getElementsByClassName("ribbon-children")[0];
  return {
    promoText: ribbonPromoTextEl && ribbonPromoTextEl.innerHTML,
    ...promotion
  };
};

export const parsedPlanSelectionWrapper = (promotion) => {
  if (!promotion.promotionText) return promotion;
  const xmlDoc = new DOMParser().parseFromString(
    promotion.promotionText,
    "text/html"
  );
  const tabTitleEl = xmlDoc.getElementsByClassName("tabs-title")[0];
  const planCodeEl = xmlDoc.getElementsByClassName("plan-code")[0];
  const displayOrderEl = xmlDoc.getElementsByClassName("display-order")[0];
  const selectedEl = xmlDoc.getElementsByClassName("tabs-selected")[0];
  const titleEl = xmlDoc.getElementsByClassName("plan-tag")[0];
  const coverageDisclaimerEl = xmlDoc.getElementsByClassName(
    "coverage-disclaimer"
  )[0];

  return {
    tabTitle: tabTitleEl && tabTitleEl.innerHTML,
    planCode: planCodeEl && planCodeEl.innerHTML,
    displayOrder: displayOrderEl && displayOrderEl.innerHTML - 1,
    selected: selectedEl && selectedEl.innerHTML === "true",
    title: titleEl && titleEl.innerHTML,
    coverageDisclaimer: coverageDisclaimerEl && coverageDisclaimerEl.innerHTML,
    ...promotion
  };
};

export const handlePromotionsResponse = (promotions) => {
  const today = new Date();

  const filteredPromotions = promotions.filter((promotion) => {
    return isWithinInterval(today, {
      start: new Date(promotion.startDate),
      end: new Date(promotion.endDate)
    });
  });

  const  [campiagn]  = filterPromotionsByClass(filteredPromotions, "campaignCode");
  return {
    promotions: filteredPromotions,
    freebies: filterPromotionsByClass(
      filteredPromotions,
      "freebie-wrapper"
    ).map((promotion) => parsedFreebie(promotion)),
    ribbons: filterPromotionsByClass(filteredPromotions, "ribbon-wrapper").map(
      (promotion) => parsedRibbon(promotion)
    ),
    keyBenefits: filterPromotionsByClass(
      promotions,
      "plan-selection-tab-wrapper"
    ).map((promotion) => parsedPlanSelectionWrapper(promotion)),
    discounts:!!campiagn ? JSON.parse(campiagn.promotionText) : []
  };
};
