import { Button, Column, Text, theme } from "@dls/web";
import { Layout, Section } from "../";

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Error = ({
  header,
  title,
  buttonText,
  onClick,
  testid,
  secondary = true
}) => {
  return (
    <Layout mdOffset={3} bgColor={theme.main.colours.white}>
      <Container>
        <Section margin={[10, 0, 6, 0]}>
          <Text type="pageTitle">{header}</Text>
        </Section>
        <Section margin={[0, 0, 8, 0]}>
          <Text type="body">{title}</Text>
        </Section>
      </Container>
      <Column noGutter xs={12} md={8} mdOffset={2}>
        <Button
          secondary={secondary}
          fullWidth
          text={buttonText}
          onClick={onClick}
          data-testid={testid}
        />
      </Column>
    </Layout>
  );
};

export default Error;
