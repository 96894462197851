import React from "react";
import { Button } from "@dls/web";
import { navigate } from "gatsby";

import { GET_QUOTE } from "../../constants/strings/staticString.json";

const ContactGeButton = props => {
  const {
    driverDetails: { contactGreatEastern }
  } = GET_QUOTE;
  return (
    <Button {...props} onClick={() => navigate("/occupation-fail")}>
      {contactGreatEastern}
    </Button>
  );
};

export default ContactGeButton;
