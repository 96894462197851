import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "gatsby";
import {
  Row,
  Column,
  Button,
  Layout,
  Text,
  TextLink,
  BulletList
} from "@dls/web";
import { Formik } from "formik";
import { DeclarationModals } from "../../../../components";
import { trackEvent } from "../../../../utils/analyticsUtils";
import Captcha from "../../../Captcha";

import {
  setVerified,
  verificationSuccess,
  submitGetQuoteVehicleDetails,
  saveVehicleDetails
} from "../../../../actions/getQuoteActions";

import {
  CONFIG,
  GET_QUOTE,
  OCCUPATION_CHECK,
  MODIFICATIONS_MODAL,
  PROFESSIONAL_MODAL
} from "../../../../constants/strings/staticString.json";
import MakeAndModel from "./MakeAndModel";
import BodyType from "./BodyType";
import EngineCapacity from "./EngineCapacity";
import YearOfManufacture from "./YearOfManufacture";
import CoverageStartDate from "./CoverageStartDate";
import VehicleTypeInput from "./VehicleTypeInput";
import Section from "../../../Section";
import FormikOnError from "../../FormikOnError";

import {
  showVehicleTobeInsuredTitleLogic,
  showMakeAndModelLogic,
  showBodyTypeLogic,
  showEngineCapacityLogic,
  showYearOfManufactureLogic,
  showEVehicleType,
  showWEKVehicleType
} from "../../../../utils/ui-logic";

import {
  getUniqueVehicleTypes,
  myInfoEngineCapacity,
  dateFromString,
  isEligibleAge
} from "../../../../utils/utilsMethods";

const VehicleDetailsForm = ({ innerRef, submitBtnLoading = false }) => {
  const {
    vehicleToBeInsured,
    checkBoxesTitle,
    contactGreatEastern,
    bottomButton,
    viewPaymentSummary,
    coveragePeriod: coveragePeriodStr,
    makeAndModel: makeAndModelStr,
    makeAndModelHint,
    bodyType: bodyTypeStr,
    engineCapacity: engineCapacityStr,
    yearOfManufacture: yearOfManufactureStr,
    vehicleInfos: vehicleInfosStr
  } = GET_QUOTE.vehicleDetails;

  const {
    premiumData: {
      effectiveDate,
      expiredDate,
      bodyType,
      engineCapacity,
      dateOfBirth,
      yearOfManufacture,
      vehicleInfos: vehicleInfosState = []
    },
    makeModelItems,
    makeAndModel,
    bodyTypeItems,
    isEdit,
    verified
  } = useSelector(s => s.getQuote);

  const coverageDate = {
    effective: effectiveDate,
    expired: expiredDate
  };

  const showVehicleTobeInsuredTitle = useSelector(
    showVehicleTobeInsuredTitleLogic
  );
  const showMakeAndModel = useSelector(showMakeAndModelLogic);
  const showBodyType = useSelector(showBodyTypeLogic);
  const showEngineCapacity = useSelector(showEngineCapacityLogic);
  const showYearOfManufacture = useSelector(showYearOfManufactureLogic);

  const checkEMyInfo = useSelector(showEVehicleType);
  const checkWEKMyInfo = useSelector(showWEKVehicleType);
  const myInfo = useSelector(s => s.myInfo);
  const recaptchaRef = useRef();

  const dispatch = useDispatch();

  const [professionVisible, setProfessionVisible] = useState(false);
  const [modificationVisible, setModificationVisible] = useState(false);
  const professionsAnalytics = OCCUPATION_CHECK.professionsAnalytics;
  const modificationsAnalytics = OCCUPATION_CHECK.modificationsAnalytics;

  const dispatchSubmitGetQuoteVehicleDetails = React.useCallback(
    () => dispatch(submitGetQuoteVehicleDetails()),
    [dispatch]
  );

  const dispatchVerificationSuccess = React.useCallback(
    value => dispatch(verificationSuccess(value)),
    [dispatch]
  );

  const dispatchVerificationExpired = React.useCallback(
    () => dispatch(setVerified(false)),
    [dispatch]
  );

  const onMakeAndModelNoResultClick = () => {
    saveVehicleDetailsFormInfo(innerRef.current.values);
    navigate("/occupation-fail");
  };

  const vehicleInfos =
    JSON.stringify(getUniqueVehicleTypes(vehicleInfosState, myInfo)) ===
      JSON.stringify(["P"])
      ? [...vehicleInfosState, "NA"].filter(v => v != "P")
      : vehicleInfosState;

  const isCoverageDateInvalid = (errors, touched) => {
    return (
      touched &&
      touched.coverageDate &&
      errors.coverageDate &&
      errors.coverageDate !== coveragePeriodStr.requiredMessage
    );
  };

  const isEngineCapacityInvalid = (errors, touched) => {
    return (
      touched &&
      touched.engineCapacity &&
      errors.engineCapacity &&
      errors.engineCapacity !== engineCapacityStr.requiredMessage
    );
  };

  const isYearOfManufactureInvalid = (errors, touched) => {
    return (
      touched &&
      touched.yearOfManufacture &&
      errors.yearOfManufacture &&
      errors.yearOfManufacture !== yearOfManufactureStr.requiredMessage
    );
  };

  useEffect(() => {
    dispatchVerificationExpired();
    //reset existing over insurance state to avoid showing popup when we return after editing user address
    invokeCaptcha();
  }, []);

  const shouldShowContactGEButton = (errors, touched) => {
    return (
      isCoverageDateInvalid(errors, touched) ||
      isEngineCapacityInvalid(errors, touched) ||
      isYearOfManufactureInvalid(errors, touched)
    );
  };

  const isElectricCar = values => {
    return values.vehicleInfos.includes("E");
  };

  const contactGE = () => {
    navigate("/occupation-fail");
  };

  const invokeCaptcha = async () => {
    // to avoid crashing the app incase recaptcha is not intialised yet call from useEffect
    const token = await recaptchaRef.current.executeAsync();
    dispatchVerificationSuccess(token);
  };

  const onProfessionsLinkClick = () => {
    trackEvent(professionsAnalytics);
    setProfessionVisible(true);
  };

  const onModificationsLinkClick = () => {
    trackEvent(modificationsAnalytics);
    setModificationVisible(true);
  };

  const onProfessionModalClose = state => {
    saveVehicleDetailsFormInfo(innerRef.current.values);
    setProfessionVisible(state);
  };

  const onModificationModalClose = state => {
    saveVehicleDetailsFormInfo(innerRef.current.values);
    setModificationVisible(state);
  };

  const onGetHelpLinkClick = () => {
    saveVehicleDetailsFormInfo(innerRef.current.values);
    navigate("/occupation-fail");
  };

  const saveVehicleDetailsFormInfo = values => {
    const { coverageDate: coverage, ...rest } = values;
    const modifiedValues = { ...rest };
    modifiedValues.engineCapacity = isElectricCar(values)
      ? null
      : myInfoEngineCapacity(myInfo) || values.engineCapacity;
    modifiedValues.effectiveDate = coverage.effective;
    modifiedValues.expiredDate = coverage.expired;
    dispatch(saveVehicleDetails(modifiedValues));
  };

  const onFormikSubmit = async values => {
    if (!verified) {
      invokeCaptcha();
      return;
    }
    saveVehicleDetailsFormInfo(values);
    if (isEdit) {
      navigate("/payment-summary");
    } else {
      dispatchSubmitGetQuoteVehicleDetails();
    }
  };

  return (
    <>
      <Formik
        innerRef={innerRef}
        enableReinitialize
        initialValues={{
          coverageDate,
          makeAndModel,
          bodyType,
          engineCapacity,
          yearOfManufacture,
          vehicleInfos
        }}
        validate={values => {
          const errors = {};

          if (!values.coverageDate.effective) {
            errors.coverageDate = coveragePeriodStr.requiredMessage;
          } else if (
            !isEligibleAge(
              dateFromString(dateOfBirth),
              dateFromString(values.coverageDate.effective)
            )
          ) {
            errors.coverageDate = coveragePeriodStr.ineligibleMessage;
          }

          if (!values.makeAndModel) {
            errors.makeAndModel = makeAndModelStr.requiredMessage;
          } else if (
            !makeModelItems.some(item => item.text === values.makeAndModel.text)
          ) {
            errors.makeAndModel = makeAndModelStr.invalidMessage;
          }

          if (!values.bodyType) {
            errors.bodyType = bodyTypeStr.requiredMessage;
          }

          if (!isElectricCar(values) && !myInfoEngineCapacity(myInfo)) {
            if (!values.engineCapacity) {
              errors.engineCapacity = engineCapacityStr.requiredMessage;
            } else if (values.engineCapacity < 600) {
              errors.engineCapacity = engineCapacityStr.minError;
            } else if (values.engineCapacity > 3000) {
              errors.engineCapacity = engineCapacityStr.maxError;
            }
          }

          if (!values.yearOfManufacture) {
            errors.yearOfManufacture = yearOfManufactureStr.requiredMessage;
          } else {
            const now = new Date();
            const currentYear = now.getFullYear();
            const minYear = currentYear - CONFIG.numberOfYearsFromYOM;

            if (values.yearOfManufacture < 1000) {
              errors.yearOfManufacture = yearOfManufactureStr.yomInvalid;
            } else if (values.yearOfManufacture < minYear) {
              errors.yearOfManufacture = yearOfManufactureStr.yomError;
            } else if (values.yearOfManufacture > currentYear) {
              errors.yearOfManufacture = yearOfManufactureStr.yomFutureError;
            }
          }

          if (getUniqueVehicleTypes(values.vehicleInfos, myInfo).length === 0) {
            errors.vehicleInfos = vehicleInfosStr.requiredMessage;
          }

          return errors;
        }}
        onSubmit={onFormikSubmit}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          touched
        }) => {
          return (
            <FormikOnError>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Column xs={12} sm={7}>
                    {!isCoverageDateInvalid(errors, touched) && (
                      <>
                        {showVehicleTobeInsuredTitle && (
                          <>
                            <Text type='header'>{vehicleToBeInsured}</Text>
                            <Section margin={[4, 0, 0, 0]} />
                          </>
                        )}
                        {showMakeAndModel && (
                          <>
                            <MakeAndModel
                              name='makeAndModel'
                              onNoResultLinkClick={
                                onMakeAndModelNoResultClick
                              }
                              makeAndModel={values.makeAndModel}
                              makeModelItems={makeModelItems}
                              error={
                                touched.makeAndModel
                                  ? errors.makeAndModel
                                  : null
                              }
                              hintMessage={
                                touched.makeAndModel && errors.makeAndModel
                              }
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                            />
                            <Section margin={[1, 0, 5, 0]}>
                              <TextLink
                                data-testid='makeAndModelHintLink'
                                onClick={onMakeAndModelNoResultClick}
                              >
                                {makeAndModelHint}
                              </TextLink>
                            </Section>
                          </>
                        )}
                        {showBodyType && (
                          <>
                            <BodyType
                              name='bodyType'
                              bodyTypeItems={bodyTypeItems}
                              bodyType={values.bodyType}
                              error={
                                touched.bodyType ? errors.bodyType : null
                              }
                              hintMessage={
                                touched.bodyType && errors.bodyType
                              }
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                            />
                            <Section margin={[4, 0, 0, 0]} />
                          </>
                        )}

                        <Section margin={[4, 0, 4, 0]}>
                          <Layout.Stack space={2}>
                            <Text type='header'>{checkBoxesTitle}</Text>
                            <VehicleTypeInput
                              name='vehicleInfos'
                              vehicleInfos={values.vehicleInfos}
                              checkEMyInfo={checkEMyInfo}
                              checkWEKMyInfo={checkWEKMyInfo}
                              error={
                                touched.vehicleInfos
                                  ? errors.vehicleInfos
                                  : ""
                              }
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                            />
                          </Layout.Stack>
                        </Section>

                        {showEngineCapacity && !isElectricCar(values) && (
                          <>
                            <EngineCapacity
                              name='engineCapacity'
                              value={values.engineCapacity}
                              error={
                                touched.engineCapacity &&
                                errors.engineCapacity
                              }
                              hintMessage={
                                touched.engineCapacity
                                  ? errors.engineCapacity
                                  : null
                              }
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                            />
                            <Section margin={[4, 0, 0, 0]} />
                          </>
                        )}
                        {showYearOfManufacture &&
                          !isEngineCapacityInvalid(errors, touched) && (
                            <>
                              <YearOfManufacture
                                name='yearOfManufacture'
                                value={values.yearOfManufacture}
                                error={
                                  touched.yearOfManufacture &&
                                  errors.yearOfManufacture
                                }
                                hintMessage={
                                  touched.yearOfManufacture
                                    ? errors.yearOfManufacture
                                    : null
                                }
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                              />
                              <Section margin={[8, 0, 0, 0]} />
                            </>
                          )}
                      </>)
                    }
                    <Section margin={[4, 0, 8, 0]}>
                      <Layout.Stack space={2}>
                        <Text type='header'>
                          {GET_QUOTE.vehicleDetails.coveragePeriod.label}
                        </Text>
                        <CoverageStartDate
                          id='coverageDate'
                          coverageDate={values.coverageDate}
                          error={
                            touched.coverageDate
                              ? errors.coverageDate
                              : null
                          }
                          hintMessage={
                            touched.coverageDate && errors.coverageDate
                          }
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </Layout.Stack>
                    </Section>
                    {!isCoverageDateInvalid(errors, touched) && (<>
                      <Section margin={[0, 2, 0, 0]}>
                        <Text type='header'>{OCCUPATION_CHECK.title}</Text>
                      </Section>

                      <Section margin={[5, 2, 5, 2]}>
                        <BulletList>
                          {OCCUPATION_CHECK.vehicleOwnerNameTitle}
                        </BulletList>
                        <BulletList>
                          {OCCUPATION_CHECK.experienceTitle}
                        </BulletList>
                        <BulletList>
                          {OCCUPATION_CHECK.aviationIndustryTitle}
                          <TextLink
                            data-testid='profession-link'
                            onClick={onProfessionsLinkClick}
                          >
                            &nbsp;{OCCUPATION_CHECK.highRiskProfessionTitle}
                            &nbsp;
                          </TextLink>
                        </BulletList>
                        <BulletList>
                          {OCCUPATION_CHECK.modificationTitle}
                          <TextLink
                            data-testid='modification-link'
                            onClick={onModificationsLinkClick}
                          >
                            &nbsp;{OCCUPATION_CHECK.modificationLinkTitle}
                            &nbsp;
                          </TextLink>
                          {OCCUPATION_CHECK.modificationTextTitle}
                        </BulletList>
                      </Section>

                      <Section margin={[0, 2, 5, 2]}>
                        <Text>
                          {OCCUPATION_CHECK.doNotMeetRequirements}&nbsp;
                          <TextLink
                            data-testid='get-help-link'
                            onClick={onGetHelpLinkClick}
                          >
                            {OCCUPATION_CHECK.getHelp}
                          </TextLink>
                        </Text>
                      </Section>

                      <DeclarationModals
                        visible={professionVisible}
                        onClose={onProfessionModalClose}
                        json={PROFESSIONAL_MODAL}
                      />
                      <DeclarationModals
                        visible={modificationVisible}
                        onClose={onModificationModalClose}
                        json={MODIFICATIONS_MODAL}
                      />

                      <Captcha
                        ref={recaptchaRef}
                        onExpired={dispatchVerificationExpired}
                        size='invisible'
                      />
                    </>
                    )}
                  </Column>
                </Row>
                <Row>
                  <Column noGutter xs={12} md={7}>
                    <Row>
                      {!shouldShowContactGEButton(errors, touched) && (
                        <Column xs={12} sm={4} md={6}>
                          <Button
                            data-testid='enter-vehicle-details'
                            fullWidth
                            loading={submitBtnLoading}
                            type='submit'
                          >
                            {isEdit ? viewPaymentSummary : bottomButton}
                          </Button>
                        </Column>
                      )}

                      {shouldShowContactGEButton(errors, touched) && (
                        <Column xs={12} sm={4} md={6}>
                          <Button
                            data-testid='contact-ge'
                            fullWidth
                            onClick={contactGE}
                          >
                            {contactGreatEastern}
                          </Button>
                        </Column>
                      )}
                    </Row>
                  </Column>
                </Row>
              </form>
            </FormikOnError>
          );
        }}
      </Formik>
    </>
  );
};

export default VehicleDetailsForm;
