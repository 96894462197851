import React from "react";
import { BinarySelector } from "@dls/web";
import { Error } from "../../../commonStyledComponent";
import { GET_QUOTE } from "../../../../constants/strings/staticString.json";

const Gender = ({ name, value, error, setFieldTouched, setFieldValue }) => {
  const { label, firstOption, secondOption } = GET_QUOTE.driverDetails.gender;

  return (
    <>
      <BinarySelector
        data-testid="gender-binary"
        name={name}
        value={value}
        label={label}
        firstOption={firstOption}
        secondOption={secondOption}
        onChange={(_, option) => {
          setFieldTouched(name, true);
          setFieldValue(name, option.value);
        }}
      />
      {error && <Error>{error}</Error>}
    </>
  );
};

export default Gender;
  