import { useSelector } from "react-redux";
import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { paymentSummaryAnalytics } from "../../utils/analyticsUtils";
import {
  getVehicleBodyTypeSummary,
  getVehicleEngineCapacitySummary,
  getYomSummary,
  getVehicleTypesSummary
} from "../../utils/ui-logic.js";

const useMyInfoVehicleDetails = () => {
  const {
    vehicleRegistrationNo,
    vehicleChasisNo,
    vehicleEngineNo,
    vehicleBankName
  } = useSelector(s => s.vehicleDetails);
  const { selectedVehicleIndex } = useSelector(s => s.myInfo);

  const bodyTypeSummary = useSelector(getVehicleBodyTypeSummary);
  const engineCapacitySummary = useSelector(getVehicleEngineCapacitySummary);
  const yomSummary = useSelector(getYomSummary);
  const vehicleTypesSummary = useSelector(getVehicleTypesSummary);

  return {
    shouldHide: selectedVehicleIndex === null,
    title: PAYMENT_SUMMARY.myInfoDetails,
    items: [
      vehicleRegistrationNo,
      `${PAYMENT_SUMMARY.labelChassisNo} ${vehicleChasisNo}`,
      `${PAYMENT_SUMMARY.labelEngineNo} ${vehicleEngineNo}`,
      vehicleBankName
        ? `${PAYMENT_SUMMARY.financedBy} ${vehicleBankName}`
        : null,
      bodyTypeSummary,
      engineCapacitySummary,
      yomSummary,
      ...vehicleTypesSummary
    ].filter(item => !!item),
    link: "/get-quote/vehicle-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titleVehicleDetails,
      "Change"
    )
  };
};

export default useMyInfoVehicleDetails;
