import React from "react";
import { InputText } from "@dls/web";

import { GET_QUOTE } from "../../../../constants/strings/staticString.json";
import { numberFormate } from "../../../../utils/utilsMethods";

const EngineCapacity = props => {
  const { label } = GET_QUOTE.vehicleDetails.engineCapacity;
  const {
    name,
    value,
    error,
    hintMessage,
    setFieldTouched,
    setFieldValue
  } = props;

  return (
    <InputText
      name={name}
      value={value}
      label={label}
      type="tel"
      maxLength="4"
      error={error}
      hintMessage={hintMessage}
      onChange={e => {
        setFieldTouched(name, false);
        setFieldValue(name, numberFormate(e.target.value));
      }}
      onBlur={() => setFieldTouched(name, true)}
    />
  );
};

export default EngineCapacity;
