import React from "react";
import { InputText } from "@dls/web";

import { DRIVER_DETAILS } from "../../../../constants/strings/staticString.json";
import { autoCapitalFirstAlpha } from "../../../../utils/utilsMethods";

const Name = ({
  name,
  value,
  error,
  hintMessage,
  setFieldTouched,
  setFieldValue
}) => {
  const { label } = DRIVER_DETAILS.name;

  const trimSpaceOnlyString = string => {
    return string === " " ? "" : string;
  };

  return (
    <InputText
      name={name}
      label={label}
      value={value}
      error={error}
      hintMessage={hintMessage}
      maxLength={25}
      onChange={e => {
        setFieldTouched(name, false);
        setFieldValue(
          name,
          trimSpaceOnlyString(autoCapitalFirstAlpha(e.target.value))
        );
      }}
      onBlur={() => setFieldTouched(name, true)}
    />
  );
};

export default Name;
