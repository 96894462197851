import React from "react";
import { useSelector } from "react-redux";

// this hook will help to see whether data refreshed and lost in redux store
const useCheckRedux = () => {
  const { premiumData } = useSelector(s => s.getQuote);
  return !!premiumData.contactNumberMobile ? false  : true
};

export default useCheckRedux;
