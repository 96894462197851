import React from "react";
import { Checkbox } from "@dls/web";
import { GET_QUOTE } from "../../../../constants/strings/staticString.json";

const VehicleTypeInput = props => {
  const { vehicleTypes } = GET_QUOTE.vehicleDetails.vehicleInfos;
  const {
    name,
    vehicleInfos,
    checkEMyInfo,
    checkWEKMyInfo,
    error,
    setFieldTouched,
    setFieldValue
  } = props;

  const handleSelectedVehicleInfos = (checked, value) => {
    if (value === "NA" && !checkEMyInfo && !checkWEKMyInfo) {
      //NA selected when no data availed from myInfo, add P by default
      return checked ? [value] : [];
    } else if (value === "NA" && (checkEMyInfo || checkWEKMyInfo)) {
      //N/A selected when data fetched from myInfo
      let updatedVehicleInfos = vehicleInfos.filter(v => v != "NA");
      // remove E, add P if no data from myInfo
      if (!checkEMyInfo) {
        updatedVehicleInfos = [...updatedVehicleInfos.filter(v => v != "E")];
      }
      //remove WEK if no data from myInfo
      if (!checkWEKMyInfo) {
        updatedVehicleInfos = updatedVehicleInfos.filter(v => v != "WEK");
      }
      return checked ? [...updatedVehicleInfos, "NA"] : updatedVehicleInfos;
    } else {
      //removing NA before inserting other values to array
      let filteredValues = vehicleInfos.filter(v => v != "NA");
      if (checked) {
        return [...filteredValues, value];
      } else {
        filteredValues = filteredValues.filter(v => v != value);
        return filteredValues;
      }
    }
  };

  return (
    <Checkbox.InputGroup error={error} key={vehicleInfos.toString()}>
      {vehicleTypes.map(({ label, value }) => (
        //we'll always show vehicle types regardless of data received from myinfo or not
        <p
          style={{
            display: "block"
          }}
          data-testid={`checkbox-${value}`}
        >
          <Checkbox.Input
            key={label}
            label={label}
            checked={vehicleInfos.includes(value)}
            onChange={(e, checked) => {
              const handledValue = handleSelectedVehicleInfos(checked, value);
              setFieldTouched(name, true);
              setFieldValue(name, handledValue);
            }}
          />
        </p>
      ))}
    </Checkbox.InputGroup>
  );
};

export default VehicleTypeInput;
