import { useSelector } from "react-redux";
import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { paymentSummaryAnalytics } from "../../utils/analyticsUtils";

const usePreviousBank = () => {
  const { vehicleBankName } = useSelector(
    s => s.vehicleDetails
  );

  return {
    shouldHide: !vehicleBankName,
    title: PAYMENT_SUMMARY.titlePreviousBank,
    items: [vehicleBankName],
    link: "/vehicle-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.vehicleBankName,
      "Change"
    )
  };
};

export default usePreviousBank;
