import { useSelector } from "react-redux";

import useCoveragePeriod from "./useCoveragePeriod";
import useMyInfoDriverDetails from "./useMyInfoDriverDetails";
import useMauallyDriverDetails from "./useManuallyDriverDetails";
import useClaimsInPast3Years from "./useClaimsInPast3Years";
import usePreviousBank from "./usePreviousBank";
import usePreviousInsurer from "./usePreviousInsurer";
import useBillingAddress from "./useBillingAddress";
import useMyInfoVehicleDetails from "./useMyInfoVehicleDetails";
import useManuallyVehicleDetails from "./useManuallyVehicleDetails";
import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { showPreviousInsurerLogic } from "../../utils/ui-logic";

const useSummary = () => {
  const coveragePeriod = useCoveragePeriod();
  const myInfoDriverDetails = useMyInfoDriverDetails();
  const mauallyDriverDetails = useMauallyDriverDetails();
  const claimsInPast3Years = useClaimsInPast3Years();
  const myInfoVehicleDetails = useMyInfoVehicleDetails();
  const manuallyVehicleDetails = useManuallyVehicleDetails();
  const previousBank = usePreviousBank();
  const previousInsurer = usePreviousInsurer();
  const paymentMethod = {
    items: [PAYMENT_SUMMARY.labelCreditDebitCard]
  };
  const billingAddress = useBillingAddress();
  const { freebies } = useSelector(s => s.promotions);

  const showPreviousInsurer = useSelector(showPreviousInsurerLogic);

  const sectionTitleItem = title => ({ isSection: true, title });
  const promotionsItem = {
    isPromotions: true,
    promotions: freebies
  };

  const items = [
    coveragePeriod,
    promotionsItem,
    sectionTitleItem("Driver to be insured"),
    ...myInfoDriverDetails,
    ...mauallyDriverDetails,
    claimsInPast3Years,
    sectionTitleItem("Vehicle to be insured"),
    myInfoVehicleDetails,
    ...manuallyVehicleDetails,
    previousBank,
    previousInsurer,
    sectionTitleItem("Payment type"),
    paymentMethod,
    billingAddress
  ];

  showPreviousInsurer && items.splice(8, 1);

  return items.filter(item => item !== null);
};

export default useSummary;
