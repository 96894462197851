import React, { useMemo, useState } from "react";

import { ContactGeButton } from "../../Buttons";
import { Section } from "../..";
import { Container, ErrorText } from "./styles";
import { MY_INFO_PREVIEW } from "../../../constants/strings/staticString.json";
import { vehicleIsEligible } from "../../../utils/ui-logic";
import Collapsible from "../../Collapsible";
import MyInfoDetailHeader from "./MyInfoDetailHeader";
import MyInfoDetailContent from "./MyInfoDetailContent";
import MyInfoDetailIneligible from "./MyInfoDetailIneligible";
import MyInfoDetailWarning from "./MyInfoDetailWarning";

const MyInfoDetailVehicle = ({ children }) => <>{children}</>;

const Details = ({
  vehicleInfo,
  checked,
  open,
  setOpen,
  onSelect,
  mustOwnVehicleError
}) => {
  const { vehicleIneligible, notAvailable } = MY_INFO_PREVIEW.errors;

  const {
    vehicleNo,
    make,
    model,
    bodyType,
    engineNumber,
    chasisNumber,
    engineCapacity,
    yearOfManufacture,
    propellant,
    scheme
  } = vehicleInfo;

  const isEligible = useMemo(
    () => vehicleIsEligible(engineCapacity, yearOfManufacture),
    [engineCapacity, yearOfManufacture]
  );

  const [minCollapsibleHeight, setMinCollapsibleHeight] = useState(0);

  const details = {
    "Body type": bodyType || notAvailable,
    "Engine number": engineNumber || notAvailable,
    "Chassis number": chasisNumber || notAvailable,
    "Engine capacity": !!engineCapacity ? `${engineCapacity} CC` : notAvailable,
    "Year of Manufacture": yearOfManufacture || notAvailable,
    "Vehicle propellant": propellant || notAvailable,
    "Vehicle scheme": scheme || notAvailable
  };

  return (
    <Section padding={[2, 0, 2, 0]}>
      <Container>
        <MyInfoDetailHeader
          title={`${vehicleNo}, ${make} ${model}`}
          showChevron
          checked={checked}
          open={open}
          setOpen={setOpen}
          onSelect={onSelect}
        />
        <Collapsible open={open} minHeight={minCollapsibleHeight}>
          <div ref={node => node && setMinCollapsibleHeight(node.clientHeight)}>
            {!isEligible && (
              <MyInfoDetailIneligible>
                {vehicleIneligible}
              </MyInfoDetailIneligible>
            )}
            {mustOwnVehicleError && (
              <MyInfoDetailWarning>
                <ErrorText type="body">{mustOwnVehicleError}</ErrorText>
              </MyInfoDetailWarning>
            )}
          </div>

          {!isEligible && (
            <Section padding={[0, 6, 6, 6]}>
              <ContactGeButton />
            </Section>
          )}
          <MyInfoDetailContent details={details} />
        </Collapsible>
      </Container>
    </Section>
  );
};

const Other = ({ title, checked, onSelect, selectedDriverName, showRadio }) => {
  return (
    <Section padding={[2, 0, 4, 0]}>
      <Container>
        <MyInfoDetailHeader
          title={title}
          checked={checked}
          onSelect={onSelect}
          showRadio={showRadio}
        />
        <Collapsible open={checked}>
          {selectedDriverName && (
            <MyInfoDetailWarning>
              <ErrorText type="body">
                The insured vehicle must be owned by the insured driver,&nbsp;
                <span style={{ fontWeight: "bold" }}>{selectedDriverName}</span>
                . You will need to provide the vehicle details later.
              </ErrorText>
            </MyInfoDetailWarning>
          )}
        </Collapsible>
      </Container>
    </Section>
  );
};

MyInfoDetailVehicle.Details = Details;
MyInfoDetailVehicle.Other = Other;

export default MyInfoDetailVehicle;
