import React, { useEffect } from "react";
import { navigate } from "gatsby";
import {  useDispatch } from "react-redux";
import { fetchPromotions, setPromoSource } from "../../actions/promotionsActions";
import queryString from "query-string";
import { COMMON } from "../../constants/strings/staticString.json";

const OccupationCheck = (props) => {
  let { search } = props.location;
  const {
    source
  } = queryString.parse(search);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchPromotions());
    dispatch(setPromoSource(source));
    sessionStorage.setItem(COMMON.couponSource, source);
    navigate("/get-quote/driver-details");
  }, []);

  return <div />;
};
export default OccupationCheck;
