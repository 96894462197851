import { Text, TextLink } from "@dls/web";
import { Section, StrikeText, Summary } from "../index";
import { addBenefit, removeBenefit } from "../../actions/extraCoverageActions";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { SpaceBetween } from "../commonStyledComponent";
import {
  trackEvent,
  paymentSummaryAnalytics
} from "../../utils/analyticsUtils";
import { getGSTInclusiveAmount, priceFormat } from '../../utils/ui-logic';

export default function CoverageQuotation({ coverage_quotation }) {
  const dispatch = useDispatch();

  const extraCoverage = useSelector(s => s.extraCoverage);
  const premium = useSelector(s => s.premium);
  const plan = premium.plans[premium.selectedPlan] || { benefitList: [] };
  const benefit = extraCoverage.filter(
    aBenefit =>
      aBenefit.planId === plan.planId && aBenefit.benefitCode === "LOSU"
  )[0];
  const [benefitItem = {}] = plan.benefitList.filter(
    ({ benefitType, benefitCode }) =>
      benefitType === "ADDITIONAL" && benefitCode === "LOSU"
  );

  const dispatchAddBenefit = React.useCallback(
    aBenefit => {
      trackEvent(paymentSummaryAnalytics(aBenefit.benefitDesc, "Add"));
      dispatch(addBenefit(aBenefit));
    },
    [dispatch]
  );

  const dispatchRemoveBenefit = React.useCallback(
    aBenefit => {
      trackEvent(paymentSummaryAnalytics(aBenefit.benefitDesc, "Remove"));
      dispatch(removeBenefit(aBenefit));
    },
    [dispatch]
  );

  const addOnPriceIncludingGST = getGSTInclusiveAmount(benefit?.additionalBenefitPremium, plan?.gstPercentage)

  return (
    <div>
      <Summary title={coverage_quotation.title}>
        <Section margin={[0, 0, 6, 0]}>
          <SpaceBetween>
            {!benefit ? (
              <StrikeText
                type="body"
                style={{ textDecorationLine: "line-through" }}
              >
                {benefitItem.benefitDesc}
              </StrikeText>
            ) : (
              <Text type="body">{benefitItem.benefitDesc}</Text>
            )}

            <Text
              data-testid="add-remove-coverage"
              type="boldBody"
              onClick={() => {
                benefit
                  ? dispatchRemoveBenefit(benefitItem)
                  : dispatchAddBenefit(benefitItem);
              }}
            >
              {benefit && <Text>{priceFormat(addOnPriceIncludingGST)}</Text> }
              <TextLink inline>{!benefit ? "Add" : "Remove"}</TextLink>
            </Text>
          </SpaceBetween>
        </Section>
      </Summary>
    </div>
  );
}
