import { BenefitCard, PlanCard, RibbonCard } from "../../components/Cards";
import { Grid, Column, Row, Text, TextLink, Tabs } from "@dls/web";
import {
  Layout,
  Section,
  StepperWrapper,
  TitleDivider,
  KeyBenefits
} from "../../components";

import styled from "styled-components";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { navigate } from "gatsby";
import { priceFormat } from "../../utils/ui-logic";
import { openExternalURLNewTab } from "../../utils/navigationUtils";
import { useSelector, useDispatch } from "react-redux";
import { REVIEW_POLICY, CONFIG, COMMON } from "../../constants/strings/staticString.json";
import { trackEvent } from "../../utils/analyticsUtils";
import { fetchPromotions } from "../../actions/promotionsActions";
import StickyHeader from "../../components/StickyHeader";
import StickyPlanCard from "../../components/StickyPlanCard";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { selectPlan } from "../../actions/premiumActions";
import useCheckRedux from "../../hooks/useCheckRedux";

const ReviewPolicyPage = ({ location: { state } }) => {
  const { isEdit } = state || false;

  const dispatch = useDispatch();
  const {
    title,
    fullBenefits,
    needMoreInfo,
    downloadOurFull,
    downloadPolicyLink,
    policyUrl,
    purchasePlan,
    updatePlan,
    stepperStepsReviewPolicy,
    downloadPolicyAnalytics,
    planCard
  } = REVIEW_POLICY;
  const { ncdp } = useSelector(s => s.getQuote.premiumData);
  const { ribbons, keyBenefits, promotions } = useSelector(s => s.promotions);
  const premium = useSelector(s => s.premium);
  const tabbarEl = useRef({});
  const [tabbarElBottom, setTabbarElBottom] = useState(500);
  const { isMobile } = useCheckMobileScreen();

  const sortedPlans = useMemo(
    () =>
      keyBenefits
        .sort(
          (firstItem, secondItem) =>
            firstItem.displayOrder - secondItem.displayOrder
        )
        .map(item => {
          const filteredPlan = premium.plans.filter(
            planItem => planItem.planCode === item.planCode
          )[0];
          return !!filteredPlan
            ? {
                ...filteredPlan,
                tabTitle: item.tabTitle,
                selected: item.selected,
                keyBenefits: item
              }
            : null;
        })
        .filter(item => !!item),
    [keyBenefits]
  );

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(
    sortedPlans.findIndex(item => item.selected)
  );

  const plan = sortedPlans[selectedPlanIndex] || { benefitList: [] };
  const isReduxRefreshed = useCheckRedux();

  const defaultSelectedTabIndex = selectedPlan => {
    const premiumSelectedPlan = premium.plans[selectedPlan];
    return sortedPlans.findIndex(
      item => item.planCode === premiumSelectedPlan.planCode
    );
  };

  useEffect(() => {
    const extraPadding = isMobile ? 115 : 0;
    setTabbarElBottom(
      ((tabbarEl.current && tabbarEl.current.offsetTop) || 0) - extraPadding
    );
  }, [tabbarEl.current.offsetTop]);

  useEffect(() => {
    dispatch(fetchPromotions());

    if (isReduxRefreshed) {
      navigate(CONFIG.redirectURLRedux);
    }

    sessionStorage.removeItem(COMMON.couponSource);
  }, []);

  useEffect(() => {
    if (premium.selectedPlan >= 0) {
      setSelectedPlanIndex(defaultSelectedTabIndex(premium.selectedPlan));
    } else {
      if (sortedPlans.length > 0) {
        setSelectedPlanIndex(
          Math.max(
            sortedPlans.findIndex(item => item.selected),
            0
          )
        );
      }
    }
  }, [keyBenefits]);

  useEffect(() => {
    const sortedSelectedPlan = sortedPlans[selectedPlanIndex];
    if (!sortedSelectedPlan) {
      return;
    }
    dispatch(
      selectPlan(
        premium.plans.findIndex(
          item => item.planCode === sortedSelectedPlan.planCode
        )
      )
    );
  }, [selectedPlanIndex, sortedPlans]);

  const benefits = plan.benefitList.filter(
    b =>
      b.basicValue !== " " &&
      b.benefitType !== "ADDITIONAL" &&
      b.benefitCode !== "LOSU"
  );

  // Excess applicable moved to first index as per the ticket [MOT-234]
  const excessIndex = benefits.findIndex(
    benefit => benefit.benefitType === "EXCESS"
  );
  benefits.push(...benefits.splice(0, excessIndex));

  const benefitGroups = [...new Set(benefits.map(b => b.benefitGroupName))];

  const goToNextPage = () => {
    if (plan.planCode === "SV") {
      navigate("/vehicle-details");
    } else {
      navigate("/extra-coverage");
    }
  };

  const goToPaymentSummary = () => {
    navigate("/payment-summary");
  };

  const submitButtonLabel = isEdit ? updatePlan : purchasePlan;
  const submitButtonOnClick = isEdit ? goToPaymentSummary : goToNextPage;

  const NoPaddingGrid = styled(Grid)`
    padding-top: 0px;
    padding-bottom: 0px;
  `;

  return (
    <>
      {isMobile && (
        <>
          <StickyHeader top={60} bottom={2000}>
            <StickyPlanCard
              plan={plan}
              totalLabel={planCard.total}
              buttonLabel={submitButtonLabel}
              onClick={submitButtonOnClick}
            />
          </StickyHeader>
          <StickyPlanCard
            plan={plan}
            totalLabel={planCard.total}
            buttonLabel={submitButtonLabel}
            onClick={submitButtonOnClick}
            isHidden
          />
        </>
      )}
      <StickyHeader
        resetPositionTopIfExceed={isMobile ? 2000 : 0}
        positionTop={isMobile ? 115 : 0}
        showingIfExceed={tabbarElBottom}
      >
        <NoPaddingGrid>
          <Column noGutter xs={12} md={7}>
            <Tabs
              tabs={sortedPlans.map(e => ({
                title: e.tabTitle,
                subTitle: priceFormat(e.totalPremiumAfterGST)
              }))}
              selected={selectedPlanIndex}
              onTabItemClick={(_, index) => {
                setSelectedPlanIndex(index);
              }}
            />
          </Column>
        </NoPaddingGrid>
      </StickyHeader>
      <Layout md paddingMobileBottom={16}>
        <Column noGutter>
          <StepperWrapper
            totalSteps={stepperStepsReviewPolicy.length}
            activeStep={3}
            titles={stepperStepsReviewPolicy}
          />
          <Section margin={[0, 2, 0, 2]}>
            <Text type='pageTitle'>{title}</Text>
          </Section>
          <Row>
            <Column noGutter xs={12} md={7}>
              {ribbons.length > 0 && (
                <Section margin={[10, 2, 0, 2]}>
                  <RibbonCard promotion={ribbons[0]} />
                </Section>
              )}
              <Section margin={[10, 0, 6, 0]}>
                <div ref={tabbarEl}>
                  <Tabs
                    tabs={sortedPlans.map(e => ({
                      title: e.tabTitle,
                      subTitle: priceFormat(e.totalPremiumAfterGST)
                    }))}
                    selected={selectedPlanIndex}
                    onTabItemClick={(_, index) => {
                      setSelectedPlanIndex(index);
                    }}
                  />
                </div>
              </Section>
              {plan.keyBenefits && (
                <>
                  <KeyBenefits
                    keyBenefits={plan.keyBenefits}
                    ncdp={ncdp === "YES"}
                  />
                  {benefitGroups.length > 0 && (
                    <TitleDivider title={fullBenefits} />
                  )}
                </>
              )}
              {benefitGroups.map(benefitGroup => (
                <Row>
                  <React.Fragment key={benefitGroup}>
                    <Section margin={[10, 2, 4, 0]}>
                      <Text type='sectionTitle'>{benefitGroup}</Text>
                    </Section>
                    <BenefitCard
                      benefits={benefits.filter(
                        b => b.benefitGroupName === benefitGroup
                      )}
                      benefitElaboration={promotions.find(p =>
                        p.promotionText.includes("benefitsElaborations")
                      )}
                    />
                  </React.Fragment>
                </Row>
              ))}
              <Section margin={[4, 0, 6, 0]}>
                <Text>
                  {needMoreInfo}
                  {isMobile && <br />}
                  {downloadOurFull}
                  <TextLink
                    data-testid='policy-link'
                    inline
                    onClick={() => {
                      trackEvent(downloadPolicyAnalytics);
                      openExternalURLNewTab(policyUrl);
                    }}
                  >
                    {downloadPolicyLink}
                  </TextLink>
                </Text>
              </Section>
            </Column>
            <Column sm={1} md={1} />
            <Column noGutter xs={12} sm={12} md={4}>
              {!isMobile && (
                <PlanCard plan={plan} padding={100}>
                  <PlanCard.Action
                    testid='select-plan-button'
                    buttonLabel={submitButtonLabel}
                    onClick={submitButtonOnClick}
                  />
                </PlanCard>
              )}
            </Column>
          </Row>
        </Column>
      </Layout>
      {isMobile && (
        <PlanCard plan={plan} padding={100} useCardLayout={false}>
          <PlanCard.Action
            testid='select-plan-button'
            buttonLabel={submitButtonLabel}
            onClick={submitButtonOnClick}
          />
        </PlanCard>
      )}
    </>
  );
};

export default ReviewPolicyPage;
