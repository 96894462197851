import initialState from "./initialState";
import {
  SET_MY_INFO_DETAILS,
  SET_MY_INFO_DRIVER_DETAILS,
  SET_MY_INFO_VEHICLE_DETAILS,
  RESET_MY_INFO_DETAILS
} from "../../actions/myInfoActions/actions.json";

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_MY_INFO_DETAILS:
      return { ...state, details: action.details };
    case RESET_MY_INFO_DETAILS:
      return initialState;
    case SET_MY_INFO_DRIVER_DETAILS:
      return { ...state, selectedDriver: action.details };
    case SET_MY_INFO_VEHICLE_DETAILS:
      return { ...state, selectedVehicleIndex: action.details };
    default:
      return state;
  }
};
