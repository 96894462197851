import React from "react";
import { Header, Footer } from "../index";

const PageWrap = ({ children }) => {
  const baseUrl = process.env.GATSBY_SINGTEL_BASE_URL;
  const shoppingCartUrl = process.env.GATSBY_SINGTEL_SHOPPING_CART_URL;
  return (
    <>
      <Header baseUrl={baseUrl} shoppingCartUrl={shoppingCartUrl} />
      {children}
      <Footer baseUrl={baseUrl} />
    </>
  );
};
export default PageWrap;
