import React from "react";
import { DropdownInput } from "@dls/web";

import { GET_QUOTE } from "../../../../constants/strings/staticString.json";
import { filterMakeAndModelItems } from "../../../../utils/ui-logic";

const MakeAndModel = props => {
  const {
    name,
    makeAndModel,
    makeModelItems,
    onNoResultLinkClick,
    setFieldValue,
    setFieldTouched,
    hintMessage,
    error
  } = props;
  const {
    makeAndModelNoResult,
    makeAndModelNoResultLink
  } = GET_QUOTE.vehicleDetails;
  const { label } = GET_QUOTE.vehicleDetails.makeAndModel;

  const [filteredItems, setFilteredItems] = React.useState(makeModelItems);

  const filterItems = searchText => {
    const filteredResult = filterMakeAndModelItems(makeModelItems, searchText);
    if (filteredResult.length > 0) {
      setFilteredItems(filteredResult);
    } else {
      setFilteredItems([
        <DropdownInput.ActionItem>
          {`${makeAndModelNoResult} '${searchText}'`}
          <DropdownInput.ActionLink
            data-testid="noResultActionLink"
            onClick={onNoResultLinkClick}
          >
            {`${makeAndModelNoResultLink} '${searchText}'`}
          </DropdownInput.ActionLink>
        </DropdownInput.ActionItem>,
        ...makeModelItems
      ]);
    }
  };

  return (
    <DropdownInput
      id={name}
      defaultValue={makeAndModel.value}
      label={label}
      items={filteredItems.length ? filteredItems : makeModelItems}
      onChange={item => {
        setFieldTouched(name, false);
        setFieldValue(name, item);
        filterItems("");
      }}
      hasSearch
      onSearch={(event, searchText) => {
        setFieldValue(name, { text: searchText });
        filterItems(searchText);
      }}
      hintMessage={hintMessage}
      error={error}
    />
  );
};

export default MakeAndModel;
