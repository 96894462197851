import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "gatsby";
import { Row, Column, Text, Layout, Button } from "@dls/web";

import Name from "./Name";
import Nationality from "./Nationality";
import Nric from "./Nric";
import Email from "./Email";
import PostalCode from "./PostalCode";
import Street from "./Street";
import FloorUnit from "./FloorUnit";

import Section from "../../../Section";
import {
  driverNameSelector,
  showDriverNameLogic,
  driverNationalitySelector,
  showDriverNationalityLogic,
  driverNricSelector,
  showDriverNricLogic,
  driverEmailSelector,
  driverPostalCodeSelector,
  driverStreetSelector,
  driverFloorAndUnitSelector
} from "../../../../utils/ui-logic";
import { DRIVER_DETAILS } from "../../../../constants/strings/staticString.json";
import { setDriverDetails } from "../../../../actions/driverDetailsActions";
import { Formik } from "formik";
import {
  getNRICError,
  getPostalCodeError,
  getEmailError
} from "../../../../utils/validation";
import FormikOnError from "../../FormikOnError";

const DriverDetailsForm = ({ isEdit }) => {
  const {
    driverTitle,
    paymentSummary,
    billingTitle,
    name,
    nationality,
    street,
    updateButtonLabel
  } = DRIVER_DETAILS;
  const dispatch = useDispatch();
  const driverName = useSelector(driverNameSelector);
  const showDriverNameField = useSelector(showDriverNameLogic);
  const driverNationality = useSelector(driverNationalitySelector);
  const driverNationalityCode = useSelector(
    s => s.driverDetails.driverNationalityCode
  );
  const { countries } = useSelector(s => s.driverDetails);
  const showDriverNationalityField = useSelector(showDriverNationalityLogic);
  const driverNric = useSelector(driverNricSelector);
  const showDriverNricField = useSelector(showDriverNricLogic);
  const driverEmail = useSelector(driverEmailSelector);
  const driverPostal = useSelector(driverPostalCodeSelector);
  const driverStreet = useSelector(driverStreetSelector);
  const driverFloorAndUnit = useSelector(driverFloorAndUnitSelector);

  const showDriverDetailsSection =
    showDriverNameField || showDriverNationalityField || showDriverNricField;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        driverName,
        driverNationality: {
          text: driverNationality,
          value: driverNationalityCode
        },
        driverNric,
        driverEmail,
        driverPostal,
        driverStreet,
        driverFloorAndUnit
      }}
      validate={values => {
        const errors = {};
        if (!values.driverName || values.driverName === "") {
          errors.driverName = name.requiredMessage;
        }
        if (
          !values.driverNationality ||
          !values.driverNationality.text ||
          values.driverNationality.text === ""
        ) {
          errors.driverNationality = nationality.requiredMessage;
        }
        if (getNRICError(values.driverNric, values.driverNationality.text)) {
          errors.driverNric = getNRICError(
            values.driverNric,
            values.driverNationality.text
          );
        }
        if (getEmailError(values.driverEmail)) {
          errors.driverEmail = getEmailError(values.driverEmail);
        }
        if (getPostalCodeError(values.driverPostal)) {
          errors.driverPostal = getPostalCodeError(values.driverPostal);
        }
        if (!values.driverStreet || values.driverStreet === "") {
          errors.driverStreet = street.requiredMessage;
        }
        return errors;
      }}
      onSubmit={values => {
        dispatch(
          setDriverDetails({
            ...values,
            driverName: values.driverName.trim(),
            driverNationalityCode: values.driverNationality.value,
            driverNationality: values.driverNationality.text
          })
        );
        if (isEdit) {
          window.history.back();
        } else {
          navigate("/payment-summary");
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        touched
      }) => {
        return (
          <FormikOnError>
            <form onSubmit={handleSubmit}>
              <Row>
                <Column xs={12} sm={7}>
                  <Layout.Stack space={3}>
                    <Text type="header">{driverTitle}</Text>
                    <>
                      {showDriverDetailsSection && (
                        <>
                          <Name
                            name="driverName"
                            value={values.driverName}
                            error={
                              touched.driverName ? errors.driverName : null
                            }
                            hintMessage={
                              touched.driverName && errors.driverName
                            }
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                          />
                          <Section margin={[4, 0, 0, 0]} />
                          <Nationality
                            name="driverNationality"
                            value={values.driverNationality}
                            countries={countries}
                            error={
                              touched.driverNationality
                                ? errors.driverNationality
                                : null
                            }
                            hintMessage={
                              touched.driverNationality &&
                              errors.driverNationality
                            }
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                          />
                          <Section margin={[4, 0, 0, 0]} />
                          <Nric
                            name="driverNric"
                            value={values.driverNric}
                            error={
                              touched.driverNric ? errors.driverNric : null
                            }
                            hintMessage={
                              touched.driverNric && errors.driverNric
                            }
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                          />
                          <Section margin={[4, 0, 0, 0]} />
                        </>
                      )}
                      <Email
                        name="driverEmail"
                        value={values.driverEmail}
                        error={touched.driverEmail ? errors.driverEmail : null}
                        hintMessage={touched.driverEmail && errors.driverEmail}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                    </>
                    <Text type="header">{billingTitle}</Text>
                    <Layout.Stack space={2}>
                      <PostalCode
                        name="driverPostal"
                        value={values.driverPostal}
                        error={
                          touched.driverPostal ? errors.driverPostal : null
                        }
                        hintMessage={
                          touched.driverPostal && errors.driverPostal
                        }
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                      <Street
                        name="driverStreet"
                        value={values.driverStreet}
                        error={
                          touched.driverStreet ? errors.driverStreet : null
                        }
                        hintMessage={
                          touched.driverStreet && errors.driverStreet
                        }
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                      <FloorUnit
                        name="driverFloorAndUnit"
                        value={values.driverFloorAndUnit}
                        setFieldValue={setFieldValue}
                      />
                    </Layout.Stack>
                  </Layout.Stack>
                </Column>
              </Row>
              <Row>
                <Column noGutter xs={12} md={7}>
                  <Row>
                    <Column xs={12} sm={6} md={6}>
                      <Section margin={[4, 0, 0, 0]} />
                      <Button
                        data-testid="payment-summary"
                        fullWidth
                        type="submit"
                      >
                        {isEdit ? updateButtonLabel : paymentSummary}
                      </Button>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </form>
          </FormikOnError>
        );
      }}
    </Formik>
  );
};

export default DriverDetailsForm;
