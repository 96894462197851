import { call, fork, put, select } from "redux-saga/effects";
import {
  extraCoverageSelector,
  getQuoteSelector,
  premiumSelector,
  myInfoSelector
} from "../../selectors";

import { SUBMIT_GET_QUOTE_VEHICLE_DETAILS } from "../../actions/getQuoteActions/actions.json";
import {
  ADD_BENEFIT,
  REMOVE_BENEFIT
} from "../../actions/extraCoverageActions/actions.json";
import { api } from "../apiSaga";
import { navigate } from "gatsby";
import { premium } from "../../constants/api.json";
import { addBenefit, removeAllBenefit } from "../../actions/extraCoverageActions";
import { setPremium, updatePlan, setPremiumLoading } from "../../actions/premiumActions";
import { setTrackingId } from "../../actions/trackingIdActions";

export function* fetchPremium(action) {
  yield put(setPremiumLoading(true));
  const { selectedDriver, selectedVehicleIndex } = yield select(myInfoSelector);
  const { premiumData } = yield select(getQuoteSelector);
  const premiumResponse = yield select(premiumSelector);
  const extraCoverage = yield select(extraCoverageSelector);
  const didUseMyInfo = !(!selectedDriver || selectedVehicleIndex === null);

  let vehicleInfos = premiumData.vehicleInfos.filter(v => v != "P");
  vehicleInfos = !vehicleInfos.includes("E")
    ? [...vehicleInfos, "P"]
    : vehicleInfos;

  premiumData.vehicleInfos = vehicleInfos;

  if (action.type === ADD_BENEFIT || action.type === REMOVE_BENEFIT) {
    const selectedPlan = premiumResponse.plans[premiumResponse.selectedPlan];
    premiumData.planCode = selectedPlan.planCode;
    premiumData.planId = selectedPlan.planId;
    let addOns = [];
    if (extraCoverage && extraCoverage.length) {
      addOns = extraCoverage
        .filter(benefit => benefit.planId === selectedPlan.planId)
        .map(benefit => ({ id: benefit.benefitId, code: benefit.benefitCode }));
    }
    premiumData.addOns = addOns;
  } else {
    premiumData.planCode = undefined;
    premiumData.planId = undefined;
    premiumData.addOns = [];
  }
  //check placed to remove N/A as it shold not be sent to service
  premiumData.vehicleInfos = premiumData.vehicleInfos.filter(vi => vi != "NA");

  const response = yield call(api, premium, {
    ...premiumData,
    usedMyInfo: didUseMyInfo
  });
  if (response.success) {
    yield fork(fetchPremiumSuccess, response.success, action.type);
  }
  if (response.error) {
    yield fork(fetchPremiumFailure, response.error);
  }
}

export function* refetchPremium() {
  const premiumResponse = yield select(premiumSelector);
  if (premiumResponse.selectedPlan !== -1) {
    yield call(fetchPremium, addBenefit())
  }
}

export function* fetchPremiumSuccess(data, type) {
  const { result, trackingId } = data;
  yield put(setTrackingId(trackingId));
  if (type === ADD_BENEFIT || type === REMOVE_BENEFIT) {
    yield put(updatePlan(result[0]));
  } else {
    yield put(setPremium(result));
    if (type === SUBMIT_GET_QUOTE_VEHICLE_DETAILS) {
      yield put(removeAllBenefit());
      yield call(navigate, "/get-quote/review-policy");
    }
  }
}

export function* fetchPremiumFailure(error) {
  yield put(setPremiumLoading(false));
  yield call(navigate, "/error/generic-error");
}
