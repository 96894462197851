import React, { useEffect, useState } from "react";
import { differenceInMinutes, differenceInSeconds } from "date-fns";
import { Toast } from "@dls/web";

const ExpiryToast = ({ promotions, onExpired }) => {
  const [expiredPromotion, setExpiredPromotion] = useState({
    showingToast: false
  });

  useEffect(
    () => {
      const allPromotions = [...promotions.ribbons, ...promotions.freebies];
      if (
        allPromotions.some(promotion => {
          const endDate = new Date(promotion.endDate);
          return differenceInMinutes(endDate, new Date()) < 60;
        })
      ) {
        var timer = setInterval(() => {
          const expiredPromotions = allPromotions.filter(promotion => {
            const endDate = new Date(promotion.endDate);
            return differenceInSeconds(endDate, new Date()) <= 0;
          });
          if (expiredPromotions.length > 0) {
            clearInterval(timer);
            onExpired && onExpired();
            setExpiredPromotion({
              ...expiredPromotions[0],
              showingToast: true
            });
          }
        }, 1000);
        return () => {
          clearInterval(timer);
        };
      }
    },
    [promotions]
  );

  return (
    <Toast
      visible={expiredPromotion.showingToast}
      text={
        expiredPromotion &&
        `Sorry. The ${expiredPromotion.promoText} has expired.`
      }
      autoDismiss={false}
      action={{
        text: "Dismiss",
        onPress: () =>
          setExpiredPromotion(promotion => ({
            ...promotion,
            showingToast: false
          }))
      }}
    />
  );
};

export default ExpiryToast;
