import { Button, Modal, Text, TextLink } from "@dls/web";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FullImage } from "../../components/commonStyledComponent";
import { PDPA } from "../../constants/strings/staticString.json";
import PDPA_IMAGE from "./../../assets/images/PDPA_dataSharing.png";
import { Section } from "../";
import { createProposal } from "../../actions/createProposalActions";
import { openExternalURLNewTab } from "../../utils/navigationUtils";
import styled from "styled-components";

const PdpaComponent = ({ visible, onClose, test }) => {
  const { pdpa } = useSelector(s => s.pdpa);
  const [progress, setProgress] = useState("");

  const dispatch = useDispatch();

  const dispatchCreateProposal = React.useCallback(
    () => dispatch(createProposal()),
    [dispatch]
  );

  const TextStyled = styled(Text)`
    a {
      display: inline;
    }
  `;

  return (
    <Modal
      visible={visible}
      title={PDPA.header_text}
      onClose={() => onClose(false)}
      data-testid='modal-id'
    >
      <Modal.Content>
        <Section margin={[8, 0, 8, 0]}>
          <FullImage src={PDPA_IMAGE} alt='Logo' />
        </Section>
        {pdpa ? (
          <>
            <Section margin={[6, 0, 6, 0]}>
              <Text type='body'>
                {pdpa.footer.text1}&nbsp;
                <TextLink
                  inline
                  onClick={() => openExternalURLNewTab(pdpa.footer.link.link)}
                >
                  {pdpa.footer.link.text}
                </TextLink>
                {pdpa.footer.text2}
              </Text>
            </Section>
            <Section margin={[6, 0, 6, 0]}>
              <Text type='body'>{pdpa.header.text}</Text>
            </Section>
            {pdpa.links.map((item, index) => (
              <Section margin={[4, 0, 0, 0]} key={index}>
                <TextStyled type='body'>
                  {"\u2022"}&nbsp;&nbsp;
                  <TextLink
                    data-testid={`link-${index}`}
                    onClick={() => openExternalURLNewTab(item.link)}
                  >
                    <TextLink.Text>{item.text}</TextLink.Text>
                  </TextLink>
                </TextStyled>
              </Section>
            ))}
          </>
        ) : null}

        <Section margin={[8, 0, 10, 0]}>
          <Button
            data-testid='proceed'
            fullWidth
            text={PDPA.button_label}
            loading={progress}
            onClick={() => {
              //this is to prevent the warning prompt when leaving the site to one pay
              window.onbeforeunload = null;
              //set progress loader on CTA
              setProgress("inProgress");
              dispatchCreateProposal();
            }}
          />
        </Section>
      </Modal.Content>
    </Modal>
  );
};
export default PdpaComponent;
