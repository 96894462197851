import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Text } from "@dls/web";

import { Layout, Section, StepperWrapper } from "../../components";
import { VEHICLE_DETAILS, CONFIG } from "../../constants/strings/staticString.json";
import { VehicleDetailsForm } from "../../components/Forms/PurchasePlan";
import { getPreviousInsurer } from "../../actions/vehicleDetailsActions";
import { navigate } from "gatsby";
import useCheckRedux from "../../hooks/useCheckRedux";

const VehicleDetails = ({ location }) => {
  const isEdit = location && location.state && location.state.isEdit;
  const dispatch = useDispatch();

  const dispatchGetPreviousInsurer = React.useCallback(
    () => dispatch(getPreviousInsurer()),
    [dispatch]
  );
  useEffect(
    () => {
      dispatchGetPreviousInsurer();
    },
    [dispatchGetPreviousInsurer]
  );

  const isReduxRefreshed = useCheckRedux();
  useEffect(() => {
    if (isReduxRefreshed) {
      navigate(CONFIG.redirectURLRedux);
    }
  }, []);

  return (
    <Layout md paddingMobileBottom={16}>
      <StepperWrapper
        totalSteps={VEHICLE_DETAILS.stepperStepsPurchasePlan.length}
        activeStep={1}
        titles={VEHICLE_DETAILS.stepperStepsPurchasePlan}
      />
      <Section margin={[0, 2, 8, 2]}>
        <Text type="pageTitle">{VEHICLE_DETAILS.title}</Text>
      </Section>
      <VehicleDetailsForm isEdit={isEdit} />
    </Layout>
  );
};

export default VehicleDetails;
