import { Button, Card, Column, Text, TextLink, theme } from "@dls/web";
import {
  Completed as CompletedIcon,
  Mobilephone,
  Mail
} from "@dls/assets/icons";
import { Layout, Section } from "../../components";
import React, { useState } from "react";

import { POLICY_NUMBER } from "../../constants/strings/staticString.json";
import { navigate } from "gatsby";
import styled from "styled-components";

export const BodyText = styled(Text)`
  color: ${theme.main.colours.tertiary.l3};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PolicyNumber = () => {
  const [copyText, setCopyText] = useState("Copy");
  const backToHome = () => {
    navigate("/order-incomplete");
  };
  return (
    <Layout mdOffset={3}>
      <Container>
        <Section margin={[10, 0, 7, 0]}>
          <CompletedIcon
            size="64"
            color={theme.main.colours.successGreen}
          />
        </Section>
        <Text type="header"> {POLICY_NUMBER.header} </Text>
        <Text type="pageTitle"> {POLICY_NUMBER.title} </Text>

        <Section margin={[0, 0, 8, 0]}>
          <Text type="body">{POLICY_NUMBER.duration}</Text>
        </Section>
        <Card shadow="sm" radius="sm" padding="sm">
          <Card.Content>
            <CardSection>
              <Column noGutter>
                <Text type="boldBody">{POLICY_NUMBER.number}</Text>
                <BodyText>{POLICY_NUMBER.policy_number}</BodyText>
              </Column>
              <TextLink
                data-testid="text-link-copy"
                onClick={() => setCopyText("Copied")}
              >
                <TextLink.Text data-testid="text-copy">
                  {copyText}
                </TextLink.Text>
              </TextLink>
            </CardSection>
          </Card.Content>
        </Card>
      </Container>

      <Section margin={[10, 0, 2, 0]}>
        <Text type="header"> {POLICY_NUMBER.info_title_1} </Text>
      </Section>
      <Text type="body">{POLICY_NUMBER.info_description_1}</Text>

      <Section margin={[6, 0, 2, 0]}>
        <Text type="header"> {POLICY_NUMBER.info_title_2} </Text>
      </Section>
      <Text type="body"> {POLICY_NUMBER.info_description_2}</Text>

      <Section margin={[4, 0, 4, 0]}>
        <a href="tel:+65 6248 2888">
          <TextLink>
            <TextLink.Icon icon={Mobilephone} />
            <TextLink.Text>+65 6248 2888</TextLink.Text>
          </TextLink>
        </a>
      </Section>

      <a href="mailto:wecare-sg@greateastern.com">
        <TextLink>
          <TextLink.Icon icon={Mail} />
          <TextLink.Text>wecare-sg@greateasternlife.com</TextLink.Text>
        </TextLink>
      </a>
      <Section margin={[10, 0, 10, 0]}>
        <Button
          data-testid="back-To-Home"
          secondary={true}
          fullWidth
          onClick={backToHome}
          text={POLICY_NUMBER.buttonText}
        />
      </Section>
    </Layout>
  );
};
export default PolicyNumber;
