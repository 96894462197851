export const openExternalURL = url => {
  window.location.href = url;
};

export const openExternalURLNewTab = url => {
  const aTag = document.createElement("a");
  aTag.rel = "noopener";
  aTag.target = "_blank";
  aTag.href = url;
  aTag.click();
};

export const openExternalBrowser = url => {
  let rnWebView = window.ReactNativeWebView;
  rnWebView.postMessage(JSON.stringify({ action: "HANDLE_LINK_NATIVE", url }));
};

// get value from query params
// cannot use URLSearchParams because of IE support
export const getParameterByName = (name, url) => {
  if (typeof window === `undefined`) return;
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
