import { useSelector } from "react-redux";
import { displayDate } from "../../utils/utilsMethods";
import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { paymentSummaryAnalytics } from "../../utils/analyticsUtils";

const useCoveragePeriod = () => {
  const { effectiveDate, expiredDate } = useSelector(
    s => s.getQuote.premiumData
  );

  return {
    title: PAYMENT_SUMMARY.titleCoveragePeriod,
    items: [`${displayDate(effectiveDate)} - ${displayDate(expiredDate)}`],
    shouldShowDivider: false,
    link: "/get-quote/vehicle-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titleCoveragePeriod,
      "Change"
    )
  };
};

export default useCoveragePeriod;
