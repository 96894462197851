import React from "react";
import { Modal, TextLink } from "@dls/web";

import { NO_CLAIM_DISCOUNT } from "../../../../constants/strings/staticString.json";
import { NoClaimDiscountModalContent } from "../../../../components/Modals";

import { LeftAlign } from "../styles";
import Section from "../../../Section";
import { trackEvent } from "../../../../utils/analyticsUtils";

const NCDModal = () => {
  const [visible, setVisible] = React.useState(false);

  const open = () => {
    trackEvent(NO_CLAIM_DISCOUNT.analytics);
    setVisible(true);
  };
  const close = () => setVisible(false);

  return (
    <>
      <Section margin={[2, 0, 0, 0]}>
        <LeftAlign>
          <TextLink onClick={open}>{NO_CLAIM_DISCOUNT.whatIsThis}</TextLink>
        </LeftAlign>
      </Section>
      <Modal title={NO_CLAIM_DISCOUNT.title} visible={visible} onClose={close}>
        <NoClaimDiscountModalContent onClick={close} />
      </Modal>
    </>
  );
};

export default NCDModal;
