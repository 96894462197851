import { SET_TRACKING_ID } from "../../actions/trackingIdActions/actions.json";
import initialState from "./initialState";

export default function(state = initialState, action) {
  if (action.type === SET_TRACKING_ID) {
    return {
      ...state,
      trackingId: action.trackingId
    };
  }
  return state;
}
