import styled from "styled-components";

const Image = styled.div`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: ${props => props.height || "auto"};
  width: ${props => props.width || "auto"};
`;

export default Image;
