import {
  CREATE_PROPOSAL,
  CREATE_PROPOSAL_FAILURE,
  CREATE_PROPOSAL_SUCCESS,
  SET_PROPOSAL_NUMBER
} from "./actions.json";

export const createProposal = () => {
  return { type: CREATE_PROPOSAL };
};

export const createProposalSuccess = (payload) => {
  return { type: CREATE_PROPOSAL_SUCCESS, payload };
};

export const createProposalFailure = (payload) => {
  return { type: CREATE_PROPOSAL_FAILURE, payload };
};

export const setProposalNumber = (proposalNumber) => ({
  type: SET_PROPOSAL_NUMBER,
  proposalNumber
});
