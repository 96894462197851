import { useSelector } from "react-redux";
import { PAYMENT_SUMMARY } from "../../constants/strings/staticString.json";
import { paymentSummaryAnalytics } from "../../utils/analyticsUtils";

const useBillingAddress = () => {
  const { driverPostal, driverStreet, driverFloorAndUnit } = useSelector(
    s => s.driverDetails
  );

  return {
    title: PAYMENT_SUMMARY.titleBillingAddress,
    items: [
      driverStreet,
      `${PAYMENT_SUMMARY.labelCountry} ${driverPostal}`,
      driverFloorAndUnit
    ],
    link: "/driver-details",
    analytics: paymentSummaryAnalytics(
      PAYMENT_SUMMARY.titleBillingAddress,
      "Change"
    )
  };
};

export default useBillingAddress;
