import React from "react";
import { InputText } from "@dls/web";
import { GET_QUOTE } from "../../../../constants/strings/staticString.json";

const DateOfBirth = ({
  name,
  value,
  error,
  hintMessage,
  setFieldTouched,
  setFieldValue
}) => {
  const { label, placeholder } = GET_QUOTE.driverDetails.dateOfBirth;

  return (
    <InputText
      name={name}
      onChange={e => {
        setFieldTouched(name, false);
        setFieldValue(name, e.target.value.replace(/ /g, " "));
      }}
      label={label}
      value={value}
      placeholder={placeholder}
      displayFormat="date"
      type="date"
      onBlur={() => setFieldTouched(name, true)}
      error={error}
      hintMessage={hintMessage}
    />
  );
};

export default DateOfBirth;
