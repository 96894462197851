import { call, put, takeLatest } from "redux-saga/effects";

import { GET_PREVIOUS_INSURER } from "../../actions/vehicleDetailsActions/actions.json";
import { api } from "../apiSaga";
import { insurer } from "../../constants/api.json";
import { setPreviousInsurer } from "../../actions/vehicleDetailsActions";

export function* previousInsurerWatcher() {
  yield takeLatest(GET_PREVIOUS_INSURER, fetchPreviousInsurer);
}

export function* fetchPreviousInsurer() {
  const response = yield call(api, insurer);
  if (response.success) {
    yield put(setPreviousInsurer(response.success));
  }
}
