import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@dls/web";
import { Helmet } from "react-helmet";

import createStore from "./src/store/createStore";
import favicon from './src/assets/favicon.png';
import "./src/index.css";

const store = createStore();

const wrapRootComponent = ({ element }) => {
  return (
    <Provider store={store}>
      <Helmet>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <link rel="icon" href={favicon} />
      </Helmet>
      <ThemeProvider>{element}</ThemeProvider>
    </Provider>
  );
};

export default wrapRootComponent;
