import { Column, Grid } from "@dls/web";
import React from "react";
import { Container } from "./styles";
import { PaddingContainer } from "../";

const Layout = ({
  children,
  xs,
  md,
  mdOffset,
  bgColor,
  paddingMobileBottom
}) => {
  return (
    <Container bgColor={bgColor}>
      <PaddingContainer mobile={{ bottom: paddingMobileBottom }}>
        <Grid>
          <Column noGutter xs={xs || 12} md={md || 6} mdOffset={mdOffset}>
            {children}
          </Column>
        </Grid>
      </PaddingContainer>
    </Container>
  );
};
export default Layout;
