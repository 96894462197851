import { call, put, takeLatest } from "redux-saga/effects";

import { GET_COUNTRIES } from "../../actions/driverDetailsActions/actions.json";
import { country } from "../../constants/api.json";
import { setCountries } from "../../actions/driverDetailsActions";
import { api } from "../apiSaga";

export function* countriesWatcher() {
  yield takeLatest(GET_COUNTRIES, fetchCountries);
}

export function* fetchCountries() {
  const response = yield call(api, country);
  if (response.success) {
    yield put(setCountries(response.success));
  }
}
