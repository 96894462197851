import { Card, Divider, Text } from "@dls/web";

import React from "react";
import Section from "../../Section";
import { SpaceBetween } from "../../commonStyledComponent";

const PolicyCard = ({ wrgt3Value, wrlt3Value, orgt3Value, orlt3Value }) => {
  return (
    <Card Card shadow="sm" radius="sm" padding="lg">
      <Card.Content>
        <Text type="boldBody">Drivers 26 to 69 years old</Text>
        <Section margin={[6, 0, 4, 0]}>
          <SpaceBetween>
            <Text type="body">More than 3 years of driving experience</Text>
            <Text type="boldBody">{wrgt3Value}</Text>
          </SpaceBetween>
        </Section>
        <Divider />
        <Section margin={[4, 0, 10, 0]}>
          <SpaceBetween>
            <Text type="body">Less than 3 years of driving experience</Text>
            <Text type="boldBody">{wrlt3Value}</Text>
          </SpaceBetween>
        </Section>
        <Section margin={[4, 0, 6, 0]}>
          <Text type="boldBody">Drivers below 26 or above 69 years old</Text>
        </Section>
        <SpaceBetween>
          <Text type="body">More than 3 years of driving experience</Text>
          <Text type="boldBody">{orgt3Value}</Text>
        </SpaceBetween>
        <Section margin={[4, 0, 4, 0]}>
          <Divider />
        </Section>
        <SpaceBetween>
          <Text type="body">Less than 3 years of driving experience</Text>
          <Text type="boldBody">{orlt3Value}</Text>
        </SpaceBetween>
      </Card.Content>
    </Card>
  );
};

export default PolicyCard;
