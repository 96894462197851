import React from "react";
import queryString from "query-string";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";

import { setIsMsta } from "../../../actions/isMstaActions";
import { getMyInfoDetails } from "../../../actions/myInfoActions";
import { getParameterByName } from "../../../utils/navigationUtils";

const MyInfoCallback = props => {
  const { search } = props.location;
  const { code, error } = queryString.parse(search);

  const dispatch = useDispatch();

  React.useEffect(
    () => {
      const view = getParameterByName("view");
      const isMsta = view === "webview";
      dispatch(setIsMsta(isMsta));
      if (code) {
        dispatch(getMyInfoDetails(code, isMsta));
      }
    },
    [code, dispatch]
  );

  React.useEffect(
    () => {
      if (error) {
        navigate("/myinfo/permission-required");
      }
    },
    [error]
  );

  return <div />;
};

export default MyInfoCallback;
