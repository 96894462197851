import styled from "styled-components";
import { theme } from "@dls/web";

const PaddingContainer = styled.div`
  padding: ${props =>
    `${(props.mobile && props.mobile.top) || 16}px 0px ${(props.mobile &&
      props.mobile.bottom) ||
      64}px 0px`};
  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${props =>
      `${(props.desktop && props.desktop.top) || 72}px 0px ${(props.desktop &&
        props.desktop.bottom) ||
        51}px 0px`};
  }
`;

export default PaddingContainer;
