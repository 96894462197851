import { SET_PROMOTIONS, SET_SOURCE } from "../../actions/promotionsActions/actions.json";
import { handlePromotionsResponse } from './helpers';

export const initialState = {
  promotions: [],
  freebies: [],
  ribbons: [],
  keyBenefits: [],
  discounts:[],
  source:""
};

export default (promotions = initialState, action = {}) => {
  if (action.type === SET_PROMOTIONS) {
    return {
      ...promotions,
      ...handlePromotionsResponse(action.promotions)
    };
  } else if (action.type === SET_SOURCE) {
    return {...promotions, source:action.source}
  } else {
    return promotions;
  }
};
