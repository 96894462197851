import React from "react";
import { Radio } from "@dls/web";
import { GET_QUOTE } from "../../../../../constants/strings/staticString.json";
import { StyledText } from "./styles";

const LicenseRevoked = ({
  name,
  value,
  error,
  setFieldTouched,
  setFieldValue
}) => {
  const {
    label,
    firstOption,
    secondOption
  } = GET_QUOTE.driverDetails.licenseRevoked;

  return (
    <>
      <StyledText type="smallBody" data-testid="license-revoked-label">
        {label}
      </StyledText>
      <Radio.InputGroup
        name={name}
        value={value}
        options={[firstOption, secondOption]}
        error={error}
        onChange={(_, option) => {
          setFieldTouched(name, true);
          setFieldValue(name, option.value);
        }}
      />
    </>
  );
};

export default LicenseRevoked;
