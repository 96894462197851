import React from "react";

import { ErrorText } from "./styles";
import Section from "../../Section";

const MyInfoDetailIneligible = ({ children, innerRef }) => {
  return (
    <Section padding={[0, 6, 4, 6]} ref={innerRef}>
      <ErrorText type="body">{children}</ErrorText>
    </Section>
  );
};

export default MyInfoDetailIneligible;
