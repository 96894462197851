import React from "react";
import { Checkbox } from "@dls/web";

import { VEHICLE_DETAILS } from "../../../../constants/strings/staticString.json";

const FirstTime = ({ name, value, setFieldValue }) => {
  return (
    <Checkbox.Input
      label={VEHICLE_DETAILS.checkBoxLabel}
      checked={value}
      id="checkbox-id"
      onChange={(e, checked) => {
        setFieldValue(name, checked);
      }}
    />
  );
};
export default FirstTime;
