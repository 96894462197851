export const trackEvent = analytics => {
  if (analytics && analytics.action) {
    const { action, category, label } = analytics;
    var data = {};
    if (category) {
      data.event_category = category;
    }
    if (label) {
      data.event_label = label;
    }
    window && window.gtag && window.gtag("event", action, data);
  }
};

export const paymentSummaryAnalytics = (sectionName, buttonTitle) => {
  return {
    action: "click",
    category: `Motor - Motor Protect - Payment Summary – ${sectionName} – ${buttonTitle}`,
    label: `${sectionName} – ${buttonTitle} – Clicked on "${buttonTitle}" under "${sectionName}"`
  };
};
