import {
  GET_COUNTRIES,
  SET_COUNTRIES,
  SET_DRIVER_DETAILS
} from "../../actions/driverDetailsActions/actions.json";

import { initialState } from "./initialState";

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DRIVER_DETAILS:
      return {
        ...state,
        ...action.payload
      };
    case GET_COUNTRIES:
      return { ...state, loading: true };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
        loading: false
      };
    default:
      return state;
  }
}
