import React from "react";
import { Text } from "@dls/web";
import { DiscountsContainer, DiscountText } from "./styles";

const DiscountsSummary = ({ discountTexts }) => {
  return (
    <>
      {discountTexts && discountTexts.length > 0 && (
        <DiscountsContainer>
          <Text type="boldBody">Price inclusive of:</Text>
          {discountTexts.map((text, index) => (
            <DiscountText key={index}>{text}</DiscountText>
          ))}
        </DiscountsContainer>
      )}
    </>
  );
};

export default DiscountsSummary;
