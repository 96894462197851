import React from "react";
import { InputText, Text } from "@dls/web";
import Section from "../../../Section";

import { VEHICLE_DETAILS } from "../../../../constants/strings/staticString.json";
import { checkValidEnglishChars } from "../../../../utils/validation";

const BankName = ({ name, value, setFieldTouched, setFieldValue }) => {
  const { label, optionalMessage } = VEHICLE_DETAILS.bankName;

  return (
    <>
      <InputText
        name={name}
        label={label}
        value={value}
        onChange={e => {
          setFieldTouched(name, false);
          if (e.target.value === "" || checkValidEnglishChars(e.target.value)) {
            setFieldValue(name, e.target.value);
          }
        }}
        onBlur={() => setFieldTouched(name, true)}
      />
      <Section padding={[2, 0, 0, 0]}>
        <Text type="smallBody">{optionalMessage}</Text>
      </Section>
    </>
  );
};

export default BankName;
