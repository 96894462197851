import React from "react";
import { Text, Row, Column } from "@dls/web";

import { Section } from "../..";

const MyInfoDetailContent = ({ details }) => {
  return (
    <>
      {Object.keys(details).map(detailsKey => (
        <Section key={detailsKey} padding={[0, 6, 6, 6]}>
          <Row>
            <Column noGutter xs={7}>
              <Text type="body">{detailsKey}</Text>
            </Column>
            <Column noGutter>
              <Text type="boldBody">{details[detailsKey]}</Text>
            </Column>
          </Row>
        </Section>
      ))}
    </>
  );
};

export default MyInfoDetailContent;
