import { takeLatest, call, put } from "redux-saga/effects";

import { fetchPremium, refetchPremium } from "../premiumSaga";
import {
  GET_INSURANCE_DATA,
  SUBMIT_GET_QUOTE_VEHICLE_DETAILS,
  REFETCH_PREMIUM
} from "../../actions/getQuoteActions/actions.json";
import { insuranceData } from "../../constants/api.json";
import {
  setNCDItems,
  setClaimAmountItems,
  setMakeModelItems,
  setBodyTypeItems
} from "../../actions/getQuoteActions";
import { api } from "../apiSaga";

export function* getQuoteWatcher() {
  yield takeLatest(GET_INSURANCE_DATA, fetchInsuranceData);
  yield takeLatest(SUBMIT_GET_QUOTE_VEHICLE_DETAILS, fetchPremium);
  yield takeLatest(REFETCH_PREMIUM, refetchPremium);
}

export function* fetchInsuranceData() {
  const response = yield call(api, insuranceData);
  if (response.success) {
    const { ncd, claims, makeModel, bodyType } = response.success;
    yield put(setNCDItems(ncd.reverse()));
    yield put(setClaimAmountItems(claims));
    yield put(setMakeModelItems(makeModel));
    yield put(setBodyTypeItems(bodyType));
  }
}
