import React from "react";
import { theme } from "@dls/web";
import { ArrowUp, ArrowDown } from "@dls/assets/icons";
import styled from "styled-components";

const IconButton = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

const ArrowButton = ({ isOpened, onChange }) => {
  return (
    <IconButton
      data-testid="chevron"
      onClick={() => onChange && onChange(!isOpened)}
    >
      {isOpened ? (
        <ArrowUp size={22} color={theme.main.colours.secondary.d1} />
      ) : (
        <ArrowDown size={22} color={theme.main.colours.secondary.d1} />
      )}
    </IconButton>
  );
};

export default ArrowButton;
