import { call, select } from "redux-saga/effects";
import { navigate } from "gatsby";

import { request } from "../../utils/networkUtils";
import { tokenSelector } from "../../selectors";
import { auth } from "../authSaga";
import errorCodes from "../../constants/errorCodes";

export function* api(endpoint, data = {}, headers = {}) {
  const baseURL = process.env.API_BASE_URL;
  const { url, method } = endpoint;
  let token = yield select(tokenSelector);

  if (!token) {
    token = yield call(auth);
  }
  const response = yield call(request, baseURL, url, method, data, {
    "Content-Type": "application/json",
    Authorization: token,
    ...headers
  });

  if (
    response.error &&
    response.error.response &&
    response.error.response.data &&
    response.error.response.data.result &&
    errorCodes["JWT_EXPIRED"].includes(response.error.response.data.result)
  ) {
    yield call(navigate, "/error/session-time-out");
    return;
  }

  return response;
}

export function* aemAPI(endpoint, data = {}, headers = {}) {
  const baseURL = process.env.GATSBY_SPRINGD_HOST_URL;
  const { url, method } = endpoint;

  const response = yield call(request, baseURL, url, method, data, headers);

  if (
    response.error &&
    response.error.response &&
    response.error.response.data &&
    response.error.response.data.result
  ) {
    yield call(navigate, "/error/session-time-out");
    return;
  }

  return response;
}
