import { FETCH_PROMOTIONS, SET_PROMOTIONS, SET_SOURCE } from "./actions.json";

export const fetchPromotions = () => {
  return {
    type: FETCH_PROMOTIONS
  };
};

export const setPromotions = promotions => {
  return {
    type: SET_PROMOTIONS,
    promotions
  };
};


export const setPromoSource = source => {
  return {
    type: SET_SOURCE,
    source
  };
};
