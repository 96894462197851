import React, { useEffect, useState } from "react";

const useCheckMobileScreen = () => {
  const [windowWidth, setWindowWidth] = useState();
  const handleWindowSizeChange = (width = window.innerWidth) => {
    setWindowWidth(width);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", () =>
      handleWindowSizeChange(window.innerWidth)
    );
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = windowWidth <= 768;
  const isTablet = windowWidth >= 768 && windowWidth <= 992;
  const isDesktop = !isMobile && !isTablet;
  return { isMobile, isTablet, isDesktop, handleWindowSizeChange };
};

export default useCheckMobileScreen;
