import { Column, Divider, Row, Text, theme } from "@dls/web";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  Layout,
  Pdpa,
  Section,
  Summary,
  StepperWrapper,
  PlanSummary,
  DiscountsSummary,
  StickyHeader,
  StickyPlanCard,
  SanitizedHTML
} from "../../components";
import { navigate } from "gatsby";
import { CoverageQuotation } from "../../components/PaymentSummary";
import { PlanCard } from "../../components/Cards";
import { useSummary } from "../../hooks";
import {
  PAYMENT_SUMMARY,
  VEHICLE_DETAILS,
  GENERIC,
  CONFIG
} from "../../constants/strings/staticString.json";
import { premiumTitles } from "../../utils/ui-logic";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { useRefCallback } from "../../hooks";
import { refetchPremium } from "../../actions/getQuoteActions";
import { trackEvent } from "../../utils/analyticsUtils";
import useCheckRedux from "../../hooks/useCheckRedux";

const PaymentSummaryPage = () => {
  const dispatch = useDispatch();
  const { ncd, sdd } = useSelector((s) => s.getQuote.premiumData);

  const premium = useSelector((s) => s.premium);
  const promotions = useSelector((s) => s.promotions);
  const { keyBenefits } = promotions;
  const plan = premium.plans[premium.selectedPlan];
  const loading = premium.isLoading ? GENERIC.IN_PROGRESS : false;
  const planDetails = keyBenefits.filter(
    (item) => item.planCode === plan.planCode
  )[0];

  const items = useSummary();
  const { setRef, elementOffsetTop } = useRefCallback();

  const [visible, setVisible] = useState(false);
  const [stickyHeaderBottom, setStickyHeaderBottom] = useState(2000);

  const validSingtelDiscount =
    plan &&
    plan.totalDiscountPercentage &&
    Math.abs(parseInt(plan.totalDiscountPercentage)) > 0;

  const { isMobile } = useCheckMobileScreen();
  const isReduxRefreshed = useCheckRedux();
  useEffect(() => {
    if (isReduxRefreshed) {
      navigate(CONFIG.redirectURLRedux);
    }
  }, []);

  const discountTexts = () => {
    let texts = [];
    const premiumDiscountTitles = premiumTitles(ncd, sdd);
    if (plan && plan.grossPremiumAmount && premiumDiscountTitles !== "") {
      texts = [...premiumDiscountTitles];
    }
    if (validSingtelDiscount) {
      texts.push(
        `${Math.abs(parseInt(plan.totalDiscountPercentage))}% Premium Discount`
      );
    }
    return texts;
  };

  const SectionText = styled(Text)`
    font-family: AvenirLTStd-Heavy, Arial, Helvetica, sans-serif;
    font-size: 30px;

    @media (max-width: ${theme.breakpoints.sm}) {
      font-size: 24px;
    }
  `;

  const NoteText = styled(Text)`
    color: ${theme.main.colours.errorRed};
  `;

  const SectionTitle = ({ title }) => {
    return (
      <Section margin={[0, 0, 6, 0]}>
        <SectionText>{title}</SectionText>
      </Section>
    );
  };

  useEffect(() => {
    dispatch(refetchPremium());
  }, []);

  useEffect(() => {
    setStickyHeaderBottom(elementOffsetTop || 2000);
  }, [elementOffsetTop]);

  return (
    <>
      {isMobile && (
        <>
          <StickyHeader top={60} bottom={stickyHeaderBottom}>
            <StickyPlanCard
              plan={plan}
              totalLabel={PAYMENT_SUMMARY.total}
              buttonLabel={PAYMENT_SUMMARY.stickyHeaderLabel}
              loading={loading}
              onClick={() => {
                setVisible(true);
              }}
            />
          </StickyHeader>
          <StickyPlanCard
            plan={plan}
            totalLabel={PAYMENT_SUMMARY.total}
            buttonLabel={PAYMENT_SUMMARY.stickyHeaderLabel}
            loading={loading}
            onClick={() => {
              setVisible(true);
            }}
            isHidden
          />
        </>
      )}
      <Layout md paddingMobileBottom={16}>
        <StepperWrapper
          totalSteps={VEHICLE_DETAILS.stepperStepsPurchasePlan.length}
          activeStep={3}
          titles={VEHICLE_DETAILS.stepperStepsPurchasePlan}
        />
        <Section margin={[0, 2, 8, 0]}>
          <Text type="pageTitle">{PAYMENT_SUMMARY.title}</Text>
        </Section>
        <Row>
          <Column noGutter>
            <SectionTitle title={PAYMENT_SUMMARY.yourInsurancePlan} />
            <Section margin={[0, 2, 0, 0]}>
              <PlanSummary
                planName={planDetails && planDetails.tabTitle}
                total={plan && plan.totalPremiumAfterGST}
                onClickChange={() => {
                  navigate("/get-quote/review-policy", {
                    state: { isEdit: true }
                  });
                }}
              />
              <DiscountsSummary discountTexts={discountTexts()} />
              {plan && plan.planCode !== "SV" && (
                <CoverageQuotation
                  coverage_quotation={PAYMENT_SUMMARY.coverage_quotation}
                />
              )}

              {items.map((item, _) => {
                if (item.shouldHide) return;
                if (item.isPromotions) {
                  return (
                    item.promotions.length > 0 && (
                      <>
                        <Section margin={[8, 0, 0, 0]}>
                          <Divider />
                        </Section>
                        <Row>
                          {item.promotions.map((promotion, index) => (
                            <SanitizedHTML
                              html={promotion.promotionText}
                              className="freebie-list-wrapper"
                              key={index}
                            />
                          ))}
                        </Row>
                      </>
                    )
                  );
                }
                if (item.isSection) {
                  return (
                    <>
                      <Section margin={[8, 0, 10, 0]}>
                        <Divider />
                      </Section>
                      <SectionTitle title={item.title} />
                    </>
                  );
                }
                return (
                  <>
                    <Summary
                      testid={item.link}
                      title={item.title}
                      onClickEdit={() => {
                        trackEvent(item.analytics);
                        navigate(item.link, { state: { isEdit: true } });
                      }}
                    >
                      {item.items.map((text, itemIndex) =>
                        text ===
                        PAYMENT_SUMMARY.labelNCDProtectorForValuePlan ? (
                          <NoteText key={itemIndex} type="body">
                            {text}
                          </NoteText>
                        ) : (
                          <Text key={itemIndex} type="body">
                            {text}
                          </Text>
                        )
                      )}
                    </Summary>
                    <Section margin={[0, 0, 6, 0]} />
                  </>
                );
              })}
            </Section>
          </Column>
          <Column noGutter xs={12} md={4} mdOffset={1}>
            {!isMobile && (
              <PlanCard plan={plan} shouldShowOtherInfo={false}>
                <PlanCard.Action
                  testid="pay-now"
                  buttonLabel={PAYMENT_SUMMARY.buttonLabel}
                  loading={loading}
                  onClick={() => {
                    setVisible(true);
                  }}
                />
              </PlanCard>
            )}
          </Column>
        </Row>
        <Pdpa visible={visible} onClose={setVisible} />
      </Layout>
      {isMobile && (
        <PlanCard
          plan={plan}
          shouldShowOtherInfo={false}
          ref={setRef}
          useCardLayout={false}
        >
          <PlanCard.Action
            testid="pay-now-mobile"
            buttonLabel={PAYMENT_SUMMARY.buttonLabel}
            loading={loading}
            onClick={() => {
              setVisible(true);
            }}
          />
        </PlanCard>
      )}
    </>
  );
};

export default PaymentSummaryPage;
