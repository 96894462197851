import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StickyWrapper = styled.div`
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 10;
`;

const StickyHeader = ({
  top = 0,
  bottom = 99999,
  showingIfExceed = -9999,
  positionTop = 0,
  resetPositionTopIfExceed = 99999,
  children
}) => {
  const unStickyStyle = { position: "absolute", top };
  const hiddenStyle = { position: "absolute", top: 0, opacity: 0 };
  const [headerStyle, setHeaderStyle] = useState(
    showingIfExceed === -9999 ? unStickyStyle : hiddenStyle
  );

  const handleScroll = (e) => {
    let calculatedStyle = null;

    if (
      window.screen.height + window.pageYOffset >= bottom ||
      window.pageYOffset < showingIfExceed
    ) {
      calculatedStyle = hiddenStyle;
    } else if (window.pageYOffset >= top) {
      const calculatedTop =
        window.screen.height + window.pageYOffset >= resetPositionTopIfExceed
          ? 0
          : positionTop;
      calculatedStyle = { position: "fixed", top: calculatedTop };
    } else {
      calculatedStyle = unStickyStyle;
    }

    setHeaderStyle(calculatedStyle);
  };

  useEffect(() => {
    window.removeEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScroll);
  }, [top, bottom, showingIfExceed, positionTop, resetPositionTopIfExceed]);

  return (
    <StickyWrapper data-testid="sticky-wrapper" style={headerStyle}>
      {children}
    </StickyWrapper>
  );
};

export default StickyHeader;
