import { all } from "redux-saga/effects";
import { countriesWatcher } from "./countrySaga";
import { createProposalWatcher } from "./createProposalSaga";
import { getExtraCoverage } from "./extraCoverageSaga";
import { getQuoteWatcher } from "./getQuoteSaga";
import { pdpaWatcher } from "./pdpaSaga";
import { policyWatcher } from "./policySaga";
import { previousInsurerWatcher } from "./previousInsurerSaga";
import { paymentWatcher } from "./paymentSaga";
import { captchaWatcher } from "./captchaSaga";
import { myInfoWatcher } from "./myInfoSaga";
import { promotionsWatcher } from "./promotionsSaga";

export default function* root() {
  yield all([
    getQuoteWatcher(),
    countriesWatcher(),
    createProposalWatcher(),
    pdpaWatcher(),
    policyWatcher(),
    getExtraCoverage(),
    previousInsurerWatcher(),
    paymentWatcher(),
    captchaWatcher(),
    myInfoWatcher(),
    promotionsWatcher()
  ]);
}
